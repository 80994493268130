import { IconLibrary } from "./IconLibrary.model";


export abstract class Icon {
	constructor() {}
	equals(icon: Icon) {}
}


/**
 * A sprite icon is a reference to an icon in a svg sprite
 */
export class SpriteIcon extends Icon {

	public url = null;

	constructor() {
		super();
	}

	static mapFromServer(data: any): SpriteIcon {
		return (new SpriteIcon()).setFromServerData(data);
	}

	public setFromServerData(data: any): SpriteIcon {
		this.url = data; // server returns strings, not objects.
  		return this;
	}

	asApiObject() {
		return this.url;
	}

	equals(icon: Icon) {
		if (! (icon instanceof SpriteIcon)) {
			return false;
		}
	  	return this.url === icon.url;
	}
}

/**
 * An icon is a custom uploaded file icon
 */
export class FileIcon extends Icon {

	public id: number|null = null;
	public group: string = '';
	public url: string = '';
	public library: IconLibrary|null = null;

	constructor() {
		super();
	}

	static mapFromServer(data: any): FileIcon {
		return (new FileIcon()).setFromServerData(data);
	}

	setFromServerData(data: any) {
		this.id = data.id;
		this.group = data.group;
		this.url = data.url;

		if (data.library) {
			this.library = IconLibrary.mapFromServer(data.library);
		}

		return this;
	}

	asApiObject() {
		return {
			id: this.id
		};
	}

	equals(icon: Icon) {
		if (! (icon instanceof FileIcon)) {
			return false;
		}
		return this.id === icon.id;
	}

}