export class FilterParamValue {
	id: string | number | boolean;
	label: string | null = null;
	meta: any = null;

	constructor(
		id: string | number | boolean,
		label: string,
		meta: any = {}
	) {
		this.id = id;

		this.label = label;
		this.meta = meta;
	}
}
