import { defineStore } from "pinia";
import { Pupil } from "@/models/Pupil.model";
import { orgApi } from "@/utils/Api.util";
import { DateTime, Duration } from "luxon";
import { useClassroomsStore } from "@/stores/Classrooms.store";
import { PupilActivity } from "@/models/PupilActivity.model";
import { usePupilsStore } from "@/stores/Pupils.store";
import { useActivitiesStore } from "@/stores/Activities.store";
import { Activity } from "@/models/Activity.model";
import { InsightsFilterDef } from "@/stores/filters/InsightsFilterDef";

export class PupilInteraction {
	public percentage: number = 0

	constructor(
		public duration: Duration,
	) {}
}

interface InsightsStoreState {
	activities: any[];
	activitiesDaysActiveCount: number;
	feedback: any[];
	feedbackActivities: Activity[];
	socialMatrixRowPupils: Pupil[];
	socialMatrixColPupils: Pupil[];
	socialMatrix: Map<string, Map<string, PupilInteraction>>;
	socialMinTimeSpent: Duration | undefined;
	socialMaxTimeSpent: Duration | undefined;
}

export const useInsightsStore = defineStore('insights', {

	state: (): InsightsStoreState => ({
		activities: [],
		activitiesDaysActiveCount: 0,
		feedback: [],
		feedbackActivities: [],
		socialMatrixRowPupils: [],
		socialMatrixColPupils: [],
		socialMatrix: new Map<string, Map<string, PupilInteraction>>,
		socialMinTimeSpent: undefined,
		socialMaxTimeSpent: undefined,
	}),

	getters: {

		maxTotalTimeSpentInActivity(state) {
			return state.activities.reduce((max, activity) => {
				return Math.max(max, activity.totalTimeSpent);
			}, 0);
		},

		maxAverageDurationInActivity(state) {
			return state.activities.reduce((max, activity) => {
				return Math.max(max, (activity.totalTimeSpent / Math.max(1, activity.sessions)));
			}, 0);
		},

		totalTimeSpent(state) {
			return state.activities.reduce((total, activity) => {
				return total + activity.totalTimeSpent;
			}, 0);
		},

		totalAverages(state) {
			return state.activities.reduce((total, activity) => {
				return total + (activity.totalTimeSpent / Math.max(1, activity.sessions));
			}, 0);
		},

		totalSessions(state) {
			return state.activities.reduce((total, activity) => {
				return total + activity.sessions;
			}, 0);
		},

		getSocialMatrixValue(state) {
			return (pupilA: Pupil, pupilB: Pupil): PupilInteraction => {

				if (!state.socialMatrix.has(pupilA.id)) {
					return null;
				}

				return state.socialMatrix.get(pupilA.id).get(pupilB.id);

			};
		}

	},

	actions: {

		async loadActivities(filter: InsightsFilterDef) {

			const classroomId = useClassroomsStore().getCurrentClassroom().id.toString();

			this.activities = [];

			const queryParameters = filter.toApiParams();
			if (!queryParameters.start || !queryParameters.end) {
				queryParameters.start = DateTime.now().startOf("week").toJSDate();
				queryParameters.end = DateTime.now().endOf("week").toJSDate();
			}

			queryParameters.mask = [
				'*',
				'activity.*',
				'activity.icon.*',
				'pupilActivities.*',
			].join(',');

			const url = 'insights/classrooms/' + classroomId + '/activities';
			const response = await orgApi.get(url, {
				params: queryParameters
			});

			this.activities = response.data.data.map(r => {
				return {
					activity: Activity.mapFromServer(r.activity),
					daysActive: r.daysActive,
					sessions: r.sessions,
					totalTimeSpent: r.totalTimeSpent,
					pupilActivities: r.pupilActivities
				};
			});

			this.activitiesDaysActiveCount = response.data.meta.daysActive;
			return this.activities;

		},

		sortActivities(sortFunction: (a: PupilActivity, b: PupilActivity) => number) {
			if (!this.activities) {
				return;
			}
			this.activities.sort(sortFunction);
		},

		async loadFeedback(filter: InsightsFilterDef) {

			this.feedback = [];
			this.feedbackActivities = [];

			const {
				feedback,
				feedbackActivities
			} = await this.execLoadFeedback(filter);

			this.feedback = feedback;
			this.feedbackActivities = feedbackActivities;

			return this.feedback;

		},

		async execLoadFeedback(filter: InsightsFilterDef) {

			const classroomId = useClassroomsStore().getCurrentClassroom().id.toString();

			const pupilsStore = usePupilsStore();
			const activityStore = useActivitiesStore();

			await Promise.all([
				pupilsStore.load(),
				activityStore.load()
			]);

			const queryParameters = filter.toApiParams();
			if (!queryParameters.start || !queryParameters.end) {
				queryParameters.start = DateTime.now().startOf("day").toJSDate();
				queryParameters.end = DateTime.now().endOf("day").toJSDate();
			}

			const response = await orgApi.get('insights/classrooms/' + classroomId + '/feedback', {
				params: queryParameters
			});

			const feedback = response.data.data.map(feedback => {

				// First look for the pupil in the pupil store
				if (feedback.pupil) {
					let pupil = pupilsStore.findById(feedback.pupil.id);
					if (!pupil) {
						pupil = Pupil.mapFromServer(feedback.pupil);
					}
					feedback.pupil = pupil;
				}

				feedback.activities = feedback.activities.map(pupilActivity => {
					const model = PupilActivity.mapFromServer(pupilActivity, feedback.pupil);

					// First look for the activity in the activity store
					model.activity = activityStore.findById(pupilActivity.activity.id);
					if (!model.activity) {
						// If not found, map from server data
						model.activity = Activity.mapFromServer(pupilActivity.activity);
					}

					return model;
				});
				return feedback;
			});

			return {
				feedback: feedback,
				feedbackActivities: activityStore.findByIds(response.data.meta.plannedActivities)
			}

		},

		async loadSocialMatrix(filter: InsightsFilterDef) {

			const classroomId = useClassroomsStore().getCurrentClassroom().id.toString();
			const pupilsStore = usePupilsStore();

			await pupilsStore.load();

			const queryParameters = filter.toApiParams();
			if (!queryParameters.start || !queryParameters.end) {
				queryParameters.start = DateTime.now().startOf("day").toJSDate();
				queryParameters.end = DateTime.now().endOf("day").toJSDate();
			}

			const response = await orgApi.get('insights/classrooms/' + classroomId + '/social', {
				params: queryParameters
			});

			const parsedPupils = response.data.data.pupils.map(pupil => {
				return Pupil.mapFromServer(pupil);
			});

			this.socialMatrix = new Map();
			response.data.data.matrix.forEach(socialMatrix => {

				const interactionMap: Map<string, PupilInteraction> = new Map();
				let totalDuration = Duration.fromMillis(0);
				socialMatrix.interactions.forEach(interaction => {

					const duration = Duration.fromMillis(interaction.duration * 1000);
					totalDuration = totalDuration.plus(duration);

					if (typeof(this.socialMaxTimeSpent) === 'undefined' || duration > this.socialMaxTimeSpent) {
						this.socialMaxTimeSpent = duration;
					}

					if (typeof(this.socialMinTimeSpent) === 'undefined' || duration < this.socialMinTimeSpent) {
						this.socialMinTimeSpent = duration;
					}

					interactionMap.set(
						interaction.pupil.id,
						new PupilInteraction(duration)
					);
				})

				// Calculate percentages
				if (totalDuration.as("seconds") > 0) {
					interactionMap.forEach(interaction => {
						interaction.percentage = (interaction.duration.as("seconds") / totalDuration.as("seconds")) * 100;
					});
				}

				this.socialMatrix.set(socialMatrix.pupil.id, interactionMap);

			});

			if (this.socialMinTimeSpent === this.socialMaxTimeSpent) {
				this.socialMinTimeSpent = Duration.fromMillis(0);
			}

			// Split up the list of parsed pupils in two lists:
			// The columns will contain all pupils that we have seen in the matrix columns (including soft deleted once)
			// The rows will contain all pupils that we have seen in the matrix rows (= only the active ones)
			this.socialMatrixColPupils = [].concat(parsedPupils);
			this.socialMatrixRowPupils = parsedPupils.filter((pupil: Pupil) => {
				if (!pupil.id) {
					return false;
				}
				return this.socialMatrix.has(pupil.id);
			});
		}
	}

});
