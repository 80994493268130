import {Interval} from "luxon";

import { FilterDef } from "@/stores/filters/FilterDef";
import { FilterParamValue } from "./FilterParamValue";

export class InsightsFilterDef extends FilterDef {

	static FILTER_DATERANGE = 'dateRange';
	static FILTER_PUPILIDS = 'pupilIds';

	constructor() {
		super([
			InsightsFilterDef.FILTER_DATERANGE,
			InsightsFilterDef.FILTER_PUPILIDS
		]);
	}

	transformApiParamValue(paramName: string, filterParamValue: FilterParamValue): { [key: string] : string | number } {
		const params = {};

		switch (paramName) {
			case InsightsFilterDef.FILTER_DATERANGE:
				const interval = Interval.fromISO(filterParamValue.id as string);
				if (!interval.isValid) {
					throw new Error('Invalid date range');
				}

				// Yea api only accepts ISO strings created by plain javascript, not luxon
				params['start'] = interval.start.toJSDate().toISOString();
				params['end'] = interval.end.toJSDate().toISOString();
				break;

			default:
				params[paramName] = filterParamValue.id;
				break;
		}

		return params;
	}
}
