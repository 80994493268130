import { Icon } from "@/models/Icon.model";
import { HasIcon } from "@/interfaces/HasIcon";

export abstract class AbstractHasIcon implements HasIcon {

	private objectIcon: Icon | null = null;

	get icon(): Icon {
		return this.objectIcon;
	}

	set icon(icon: Icon) {
		this.objectIcon = icon;
	}
}
