import { AgendaFilterDef } from "@/stores/filters/AgendaFilterDef";
import { FilterParamValue } from "@/stores/filters/FilterParamValue";

export class LessonFilterDef extends AgendaFilterDef {

	/**
	 * Constructor
	 */
	constructor() {
		super([
			'type',
			'hasLessonItems'
		]);
	}

}
