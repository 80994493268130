<template>

	<div>
		<div class="flex flex-col sm:flex-row gap-3 mb-6">
			<div v-if="showDateRangeSelector" class="w-full sm:w-1/2 lg:max-w-64">
				<DateRangeSelector
					v-model="dateRangeArray"
					:clearable="false"
					:disabled="dateRangeSelectorReadOnly"
					:readonly="dateRangeSelectorReadOnly"
				/>
			</div>
			<div v-if="showPupilSelector" class="w-full sm:w-1/2 lg:max-w-64">
				<sms-select v-model="selectedPupil" label="" class="select select-sm leading-4 bg-base" :options="pupilsForFilter"></sms-select>
			</div>
		</div>
	</div>

</template>

<script lang="ts">
import { mapStores } from "pinia";
import { usePupilsStore } from '@/stores/Pupils.store';

import DateRangeSelector from "./ui/DateRangeSelector.vue";
import { DateTime, Interval } from "luxon";
import { useDefaultOrderStore } from "@/stores/DefaultOrder.store";
import {InsightsFilterDef} from "@/stores/filters/InsightsFilterDef";

export default {

	props: {
		modelValue: {
			type: InsightsFilterDef,
			required: true,
		},
		showPupilSelector: {
			type: Boolean,
			default: true,
		},
		showDateRangeSelector: {
			type: Boolean,
			default: true,
		},
		dateRangeSelectorReadOnly: {
			type: Boolean,
			default: false,
		},
	},

	emits: [
		'change',
		'update:modelValue',
	],

	components: {
		DateRangeSelector,
	},

	created() {
		this.pupilsStore.orderBy(this.defaultOrderStore.pupilsOrder);
	},

	computed: {
		...mapStores(usePupilsStore, useDefaultOrderStore),

		dateRangeArray: {
			get(): [Date, Date] {
				if(!this.modelValue.hasFilterValue(InsightsFilterDef.FILTER_DATERANGE)) {
					return null;
				} else {
					const dateRanges = this.modelValue.getParamValues(InsightsFilterDef.FILTER_DATERANGE);
					if (dateRanges.length === 0) {
						return null;
					}

					const dateRange = Interval.fromISO(dateRanges[0].id);
					if (!dateRange.isValid) {
						return null;
					}
					
					return [
						dateRange.start.toJSDate(),
						dateRange.end.toJSDate()
					];
				}
			},
			set(value) {
				// Not really setting the new value but rather emitting the updated model value (filter)
				// so the component gets re-rendered with the new value.
				// Also eliminates the need for a mutable local copy of the (unmutable) model value.

				const filter = this.modelValue.clone();
				filter.setParamValue(InsightsFilterDef.FILTER_DATERANGE, Interval.fromDateTimes(
					DateTime.fromJSDate(value[0]).startOf('day'),
					DateTime.fromJSDate(value[1]).endOf('day')
				).toISO());

				this.$emit('update:modelValue', filter);
				this.$emit('change', filter);
			}
		},

		selectedPupil: {
			get(): string {
				if (!this.modelValue.hasFilterValue(InsightsFilterDef.FILTER_PUPILIDS)) {
					return '';
				}

				const values = this.modelValue.getParamValues(InsightsFilterDef.FILTER_PUPILIDS);
				if (values.length > 0) {
					return values[0].label;
				}

				return '';
			},
			set(value: string) {
				let filterCopy = this.modelValue.clone();

				if (value) {
					filterCopy.setParamValue(InsightsFilterDef.FILTER_PUPILIDS, value);
				} else {
					filterCopy.clearParam(InsightsFilterDef.FILTER_PUPILIDS);
				}

				this.$emit('update:modelValue', filterCopy);
				this.$emit('change', filterCopy);
			}
		},

		pupilsForFilter() {
			let list = [];

			list.push({
				value: '',
				label: this.$t('All pupils'),
			});

			for (let pupil of this.pupilsStore.pupils) {
				list.push({
					value: pupil.id,
					label: pupil.firstName + ' ' + pupil.lastName,
				});
			}
			return list;
		},

	},

	mounted() {
		this.loadPupils();
	},

	methods: {
		async loadPupils() {
			return await this.pupilsStore.load();
		},

		filterReset() {
			const cleanFilter = new InsightsFilterDef();
			this.$emit('update:modelValue', this.cleanFilter);
			this.$emit('change', this.cleanFilter);
		},
	},
}

</script>
