import {defineStore} from 'pinia'

import {api, orgApi} from "@/utils/Api.util";
import { FileIcon, SpriteIcon } from "@/models/Icon.model";
import {IconLibrary} from "@/models/IconLibrary.model";
import {ApiErrors} from "@/stores/errors/ApiErrors";
import {ErrorMessage} from "@/stores/errors/ErrorMessage";

interface IconStoreState {
	allCustomFileIcons: FileIcon[],	// custom icons are always FileIcon instances (for now)
	libraryAdded: boolean,
	stdActivityIcons: SpriteIcon[],
	stdPupilIcons: SpriteIcon[],

	errorMessage: ErrorMessage | null
}

export const useIconsStore = defineStore('icons', {

	/**
	 * Default state
	 */
	state: (): IconStoreState => ({
		stdPupilIcons: [],
		stdActivityIcons: [],
		allCustomFileIcons: [],
		libraryAdded: false,
		errorMessage: null
	}),

	/**
	 * Getters
	 */
	// getters: {
	// 	stdPupilIcons: (state: IconStoreState) => {
	// 		return state.allCustomFileIcons.filter(
	// 			(icon: Icon) => {
	// 				return icon.group === 'pupil';
	// 			}
	// 		)
	// 	}
	// },

	/**
	 * Actions
	 */
	actions: {

		async loadStandardPupilIcons() {

			// already loaded? Skip!
			if (this.stdPupilIcons.length > 0) {
				return this.stdPupilIcons;
			}

			const response = await api.get("symbols/pupil");
			this.stdPupilIcons = response.data.map(SpriteIcon.mapFromServer);

			return this.stdPupilIcons;

		},

		async loadStandardActivityIcons() {

			// already loaded? Skip!
			if (this.stdActivityIcons.length > 0) {
				return this.stdActivityIcons;
			}

			const response = await api.get("symbols/activity");
			this.stdActivityIcons = response.data.map(SpriteIcon.mapFromServer);

			return this.stdActivityIcons;

		},

		async load() {

			// already loaded? Skip!
			if (this.allCustomFileIcons.length > 0) {
				return;
			}

			await this.loadAllCustomFileIcons();
		},

		async loadAllCustomFileIcons() {
			const response = await orgApi.get("icons", {params: {mask: "id,group,library.*,library.classroom.id,url"}})
			this.allCustomFileIcons = response.data.data.map(FileIcon.mapFromServer);
		},

		/**
		 * Upload a new icon.
		 * @param library
		 * @param group
		 * @param imageData
		 * @param name
		 * @returns {Promise<void>}
		 */
		async uploadIcon(library: IconLibrary, group: string, imageData: string, name: string) {

			try {
				this.errorMessage = null;

				const response = await orgApi.post('icons', {
					library: library,
					dataUrl: imageData,
					group: group,
					name: name
				});

				const newIcon = FileIcon.mapFromServer(response.data.data);
				newIcon.library = library
				this.allCustomFileIcons.push(newIcon)

			} catch (e: any) {
				this.errorMessage = ApiErrors.fromAxiosException(e);
				throw this.errorMessage;
			}

		},

		async replaceIcon(icon: FileIcon, library: IconLibrary, group: string, imageData: string, name: string) {

			try {
				this.errorMessage = null;

				const response = await orgApi.put('icons/' + icon.id, {
					library: library,
					dataUrl: imageData,
					group: group,
					name: name
				});

				icon.setFromServerData(response.data.data);
			} catch (e: any) {
				this.errorMessage = ApiErrors.fromAxiosException(e);
				throw this.errorMessage;
			}

		},

		/**
		 * Permanently remove a custom icon.
		 * @param icon
		 * @returns {Promise<void>}
		 */
		async removeIcon(icon: FileIcon) {

			try {

				this.errorMessage = null;
				await orgApi.delete('icons/' + icon.id);

				const index = this.allCustomFileIcons.findIndex(v => v.id === icon.id);
				if (index >= 0) {
					this.allCustomFileIcons.splice(index, 1);
				}

			} catch (e: any) {
				this.errorMessage = ApiErrors.fromAxiosException(e);
				throw this.errorMessage;
			}

		},

		clearErrorMessage() {
			this.errorMessage = null;
		}
	}
})
