import { Activity } from "./Activity.model";
import { Pupil } from "./Pupil.model";
import { FileIcon, SpriteIcon } from "./Icon.model";
import { AbstractHasIcon } from "@/models/AbstractHasIcon.model";
import { Lesson } from "@/models/Lesson.model";
import { LessonPlan } from "@/models/LessonPlan.model";

export class LessonItem extends AbstractHasIcon {

	public static create(activity: Activity | null = null): LessonItem {
		const item = new LessonItem();

		if (activity) {
			item.activity = activity;
			item.seats = activity.seats;
			item.icon = activity.icon;
		}

		return item;
	}

	public id?: string;
	public allowedPupilIds?: string[] = [];
	public activity?: Activity;
	public seats ?: number;
	public lesson?: Lesson;
	public lessonPlan?: LessonPlan;
	public guided: boolean = false;

	isPupilSelected(pupil: Pupil): boolean {
		if (!this.allowedPupilIds) {
			return false;
		}

		if (pupil.id === null) {
			return false;
		}

		return this.allowedPupilIds.indexOf(pupil.id) !== -1;
	}

	setPupilSelected(pupil: Pupil, selected: boolean) {

		if (!pupil.id) {
			throw new Error('Pupil ID is required');
		}

		if (!this.allowedPupilIds) {
			this.allowedPupilIds = [];
		}

		if (selected) {
			if (this.isPupilSelected(pupil)) {
				return;
			}
			this.allowedPupilIds.push(pupil.id);
		} else {
			const index = this.allowedPupilIds.indexOf(pupil.id);
			if (index !== -1) {
				this.allowedPupilIds.splice(index, 1);
			}
		}

	}

	getDescriptiveTitle(): string {
		if (this.lessonPlan?.title) {
			return this.activity?.name + ' / ' + this.lessonPlan?.title;
		} else if (this.activity?.name) {
			return this.activity?.name;
		} else {
			return '???';
		}
	}

	static mapFromServer(data: any): LessonItem {
		return (new LessonItem()).setFromServerData(data);
	}

	setFromServerData(data: any) {

		this.id = data.id;
		this.seats = data.seats;
		this.guided = data.guided;

		if (data.activity) {
			this.activity = Activity.mapFromServer(data.activity);
		}

		if (data.icon) {
			this.icon = FileIcon.mapFromServer(data.icon);
		}

		if (data.symbol) {
			this.icon = SpriteIcon.mapFromServer(data.symbol);
		}

		if (data.pupils) {
			this.allowedPupilIds = data.pupils.map((pupil: any) => pupil.id);
		}

		if (data.lesson) {
			this.lesson = Lesson.mapFromServer(data.lesson);
		}

		if (data.lessonPlan) {
			this.lessonPlan = LessonPlan.createFromApiData(data.lessonPlan);
		}

		return this;
	}

	getServerData() {
		const out: any = {
			id: this.id,
			seats: this.seats,
			// title: this.title,
			// notes: this.notes,
			// instructions: this.instructions,
			guided: this.guided,
			icon: (this.icon && this.icon instanceof FileIcon)? this.icon.asApiObject() : null,
			symbol: (this.icon && this.icon instanceof SpriteIcon)? this.icon.asApiObject() : null,
		};

		// allowedPupilIds MAY be NULL, which means that the server will automatically assign all available pupils
		// (or not, but the logic is on the server)
		if (this.allowedPupilIds) {
			out.pupils = this.allowedPupilIds.map((id) => {
				return {
					id
				}
			});
		}

		if (this.activity) {
			out.activity = {
				id: this.activity.id,
			}
		}

		if(this.lessonPlan) {
			if (this.lessonPlan.isSavedInLib) {
				out.lessonPlan = {
					id: this.lessonPlan.id,
					version: this.lessonPlan.version
				};
			} else {
				out.lessonPlan = this.lessonPlan.asApiDataObject();
			}
		}

		return out;
	}

	setFromModel(model: LessonItem): LessonItem {

		if (model === this) {
			return model;
		}

		this.id = model.id;
		this.seats = model.seats;
		this.guided = model.guided;

		if (model.activity) {
			this.activity = model.activity;
		}

		if (model.icon) {
			this.icon = model.icon;
		}

		if (model.allowedPupilIds) {
			this.allowedPupilIds = [ ... model.allowedPupilIds ];
		}

		this.lessonPlan = undefined;
		if (model.lessonPlan) {
			this.lessonPlan = model.lessonPlan.clone();
		}

		return this;
	}

	clone(): LessonItem {
		return (new LessonItem()).setFromModel(this);
	}
}
