import {Pupil} from "./Pupil.model";
import {PupilActivity} from "./PupilActivity.model";
import {File} from "./File.model";
import {Model} from "@/stores/AbstractBase.store";

export class Observation implements Model {

	public id ?: string;
	public note ?: string;
	public files : File[] = [];
	public classroomId ?: string;
	public pupilActivity ?: PupilActivity;
	public pupil: Pupil;
	public date: Date | undefined;

	public fileContent ?: string;
	public fileName ?: string;

	static createFromApiData(data: any, pupil: Pupil | null = null) {
		if (!pupil) {
			pupil = Pupil.mapFromServer(data.pupil);
		}

		return (new Observation(pupil)).hydrateWithApiData(data);
	}

	constructor(pupil: Pupil) {
		this.pupil = pupil;
	}

	removeFile(file: File) {
		this.files = this.files.filter((f: File) => f.id !== file.id);
	}

	hydrateWithApiData(data: any): Observation {
		this.id = data.id;
		this.date = new Date(data.date);
		this.note = data.note;

		if (data.pupilActivity) {
			this.pupilActivity = PupilActivity.mapFromServer(data.pupilActivity);
		}

		if (data.files) {
			this.files = data.files.map((fileData: any) => File.mapFromServer(fileData));
		}

		if (data.classroom) {
			this.classroomId = data.classroom.id;
		}

		return this;
	}

	asApiDataObject() {
		const serverData: any = {
			note: this.note,
			pupil: {
				id: this.pupil.id
			}
		}

		if (this.pupilActivity) {
			serverData.pupilActivity = {
				id: this.pupilActivity.id
			};
		}

		if (this.classroomId) {
			serverData.classroom = {
				id: this.classroomId
			};
		}

		// List of all existing images
		if (this.files) {
			serverData.files = this.files.map((file: File) => {
				return {
					id: file.id
				}
			});
		}

		// Old way to upload images
		if (this.fileContent && this.fileName) {
			serverData.fileContent  = this.fileContent;
			serverData.fileName = this.fileName;
		}

		return serverData;
	}

	clone(): Observation {
		let clonedInstance = Object.assign(
			Object.create(
				Object.getPrototypeOf(this)
			),
			this
		);
		delete clonedInstance.fileContent;
		delete clonedInstance.fileName;
		return clonedInstance;
	}

	setFromModel(observation: Observation) {
		this.id = observation.id;
		this.note = observation.note;
		this.pupilActivity = observation.pupilActivity;
		this.pupil = observation.pupil;
		this.date = observation.date;
		this.files = [ ... observation.files ];
		return this;
	}
}
