<template>
	<div class="print:hidden">
		<div class="flex gap-2 items-start">
			<button type="button" v-if="collapsable && toggleLabel.length" class="btn btn-sm btn-ghost shrink-0" @click="filterPanelOpen = !filterPanelOpen">
				<FunnelIcon class="w-5 h-5"/>
				{{ toggleLabel }}
				<ChevronDownIcon class="w-4 h-4" :class="[filterPanelOpen? 'rotate-180' : '']" />
			</button>
			<div v-else-if="toggleLabel.length" class="flex items-center gap-2 px-4 py-1.5 uppercase text-sm font-semibold">
				<FunnelIcon class="w-5 h-5"/>
				{{ toggleLabel }}
			</div>
			<button v-show="filter.valueCount() > 0" type="button" class="btn btn-sm btn-ghost bg-base-100" @click="resetFilter">
				{{ $t('Reset') }}
			</button>
			<div id="filter-labels" class="flex flex-wrap items-start gap-2 mt-0.5">
				<Tag v-for="filterVal in filter.getParamValues('mainGroups')" :key="filterVal.id" :removeable="true" @remove="removeFilterValue('mainGroups', filterVal)">
					{{ filterVal.label }}
				</Tag>
				<Tag v-for="filterVal in filter.getParamValues('tags')" :key="filterVal.id" class="tag-sky tag-with-icon" :removeable="true" @remove="removeFilterValue('tags', filterVal)">
					{{ filterVal.label }}
				</Tag>
				<Tag v-for="filterVal in filter.getParamValues('keywords')" :key="filterVal.id" class="tag-keyword tag-with-icon" :removeable="true" @remove="removeFilterValue('keywords', filterVal)">
					{{ filterVal.label }}
				</Tag>
			</div>
		</div>

		<div v-show="!collapsable || filterPanelOpen" class="@container bg-base grid grid-cols-12 gap-3 my-3">

			<!-- <div v-if="sourceFilterValues.length" class="col-span-12 @xl:col-span-6 @3xl:col-span-4 @5xl:col-span-2">
				<select class="select select-sm w-full mt-1">
					<option v-for="filterValue in sourceFilterValues" selected v-text="filterValue.label"></option>
				</select>
			</div> -->

			<div class="col-span-12 @xl:col-span-6 @3xl:col-span-4 @5xl:col-span-4">
				<MultiSelect class="w-full"
					:values="mainGroupFilterValues"
					:selectedIds="filter.getParamValues('mainGroups').map(item => item.id)"
					:withFilter="false"
					buttonFieldClasses="input-sm"
					inputFieldClasses="input-sm input-bordered bg-base"
					@itemSelected="addFilterValue('mainGroups', $event)"
					@itemUnselected="removeFilterValue('mainGroups', $event)"
				>
				<span v-if="filter.getParamValues('mainGroups').length" v-text="filter.getParamValues('mainGroups').length" class="badge badge-neutral px-1.5">
				</span>
				{{ $t('Main groups') }}
					<template #item="itemProps">
						<div class="flex items-start py-2 rounded hover:bg-yellow-100 px-4">
							<input type="checkbox" class="checkbox checkbox-xs rounded-full ml-2 mr-3 mt-0.5" :checked="itemProps.selected" />
							<div class="overflow-hidden font-semibold">
								{{ itemProps.value.label }}
							</div>
						</div>
					</template>
				</MultiSelect>
			</div>

			<div class="col-span-12 @xl:col-span-6 @3xl:col-span-4 @5xl:col-span-3">
				<MultiSelect class="w-full"
					:values="tagFilterValues"
					:selectedIds="filter.getParamValues('tags').map(item => item.id)"
					buttonFieldClasses="input-sm"
					inputFieldClasses="input-sm input-bordered bg-base"
					@itemSelected="addFilterValue('tags', $event)"
					@itemUnselected="removeFilterValue('tags', $event)"
				>
				<span v-if="filter.getParamValues('tags').length" v-text="filter.getParamValues('tags').length" class="badge badge-neutral px-1.5"></span>
				{{ $tc('Tag', 2) }}
					<template #item="itemProps">
						<div class="flex items-start py-2 rounded hover:bg-yellow-100 px-4">
							<input type="checkbox" class="checkbox checkbox-xs rounded-full ml-2 mr-3 mt-0.5" :checked="itemProps.selected" />
							<div class="overflow-hidden font-semibold">
								{{ itemProps.value.label }}
							</div>
						</div>
					</template>
				</MultiSelect>
			</div>

			<form class="flex items-center w-full col-span-12 @xl:col-span-6 @3xl:col-span-4 @5xl:col-span-3" @submit="addKeyword">
				<input type="text" class="input input-sm w-full pr-10" :placeholder="$t('Search term')" />
				<button type="submit" class="py-1 -ml-9 pl-2 pr-1 border-l">
					<MagnifyingGlassIcon class="w-4 h-4" />
				</button>
			</form>


		</div>
	</div>
</template>


<style>
.long-tag span {
	@apply max-w-sm md:max-w-sm overflow-hidden overflow-ellipsis whitespace-nowrap;
}
</style>


<script lang="ts">


import {useGoalsStore} from "@/stores/Goals.store";
import { GoalSource } from "@/utils/GoalSource";
import MultiSelect from "@/components/ui/MultiSelect.vue";
import { CheckIcon, FunnelIcon, ChevronDownIcon, XMarkIcon, MagnifyingGlassIcon } from '@heroicons/vue/24/outline';
import Tag from "@/components/ui/Tag.vue";
import {mapStores} from "pinia";
import {FilterDef} from "@/stores/filters/FilterDef";
import {GoalFilterDef} from "@/stores/filters/GoalFilterDef";
import {FilterParamValue} from "@/stores/filters/FilterParamValue";


export default {

	emits: ['change'],

	props: {
		initialFilter: {
			type: GoalFilterDef,
			required: true,
		},
		goalSource: {
			type: GoalSource,
			default: null,
		},
		// whether or not the goal filter is applied to a goal source or just a list of (imported) goals
		appliedToSource: {
			type: Boolean,
			default: false,
		},
		resultCounter: {
			type: Number,
			default: null,
		},
		collapsable: {
			type: Boolean,
			default: true,
		},
		collapsed: {
			type: Boolean,
			default: true,
		},
		toggleButtonLabel: {
			type: String,
			default: null,
		},
	},

	components: {
		MultiSelect,
		Tag,
		CheckIcon, FunnelIcon, ChevronDownIcon, XMarkIcon, MagnifyingGlassIcon,
	},

	data() {
		return {
			filterPanelOpen: !this.collapsed,
			categoriesLoaded: false,
			filter: null,
		}
	},

	computed: {

		...mapStores(useGoalsStore),

		mainGroupFilterValues() {
			return this.goalSource.getEmptyStructure()
				?.items
				?.map(category => new FilterParamValue(category.path, category.path + ' - ' + category.title));
		},
		// sourceFilterValues() {
		// 	return [new FilterParamValue(this.goalSource.getName(), this.goalSource.getName())];
		// },
		tagFilterValues() {
			if(this.appliedToSource) {
				// show all tags found in the source content
				return this.goalSource.getTags().map(tag => new FilterParamValue(tag, tag));
			} else {
				// show all tags found in the imported/used goals
				return this.goalsStore.usedTags.map((tag: string) => new FilterParamValue(tag, tag));
			}
		},
		toggleLabel() {
			return this.toggleButtonLabel ?? this.$t('Filter');
		},
	},

	beforeMount() {
		this.filter = this.initialFilter;
	},

	methods: {

		resetFilter() {
			this.filter.clear();
			this.$emit('change', this.filter);
		},

		addKeyword(event) {
			event.preventDefault();
			const keyword = event.target[0].value;
			if(!keyword || keyword.length < 3) {
				return;
			}
			event.target[0].value = '';
			this.addFilterValue('keywords', new FilterParamValue(keyword, keyword));
		},

		addFilterValue(paramName, paramValue: FilterParamValue) {
			this.filter.addParamValue(paramName, paramValue);
			this.$emit('change', this.filter);
		},

		removeFilterValue(paramName, paramValue: FilterParamValue) {
			this.filter.removeParamValue(paramName, paramValue);
			this.$emit('change', this.filter);
		},

	}

}
</script>
