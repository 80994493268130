<template>
	<TheBoardLayout>

		<LoadingScreen v-if="boardLoading" />

		<div id="reflection-board" class="overflow-x-auto max-h-screen" v-if="!boardLoading">

			<table class="table table-pin-rows table-pin-cols table-fixed">
				<thead>
					<tr class="bg-base z-30 shadow-md">
						<th class="w-24 2xl:w-28 bg-base text-center z-30">
							<button type="button" class="btn btn-circle btn-xl" @click="closeBoard()">
								<XMarkIcon class="w-6 h-6" />
							</button>
						</th>
						<td v-for="activity in insightsStore.feedbackActivities" class="z-20">
							<div class="max-w-24 2xl:max-w-28 mx-auto">
								<ActivitySymbol :activity="activity" />
							</div>
						</td>
					</tr>
				</thead>
				<tbody>

					<tr v-if="insightsStore.feedbackActivities && insightsStore.feedbackActivities?.length === 0">
						<td class="text-center">{{ $t('No registered activities today yet')}}</td>
					</tr>

					<tr v-for="pupil in pupils" class="h-20 2xl:h-24">
						<th class="bg-base z-20">
							<ActivityBoardAvatar :pupil="pupil" />
						</th>
						<td v-for="activity in insightsStore.feedbackActivities" :data-row="pupil.id" :data-col="activity.id" @click="event => highlightCol(event)">
							<div v-if="getPupilActivity(pupil, activity)" class="relative grid justify-center">
								<!-- time gauge -->
								<div style="grid-area: 1 / 1 / 2 / 2;">
									<div
										class="radial-progress text-gray-200 bg-gray-50 border-2 border-gray-50"
										style="grid-area: 1 / 1 / 2 / 2;"
										:style="`--value:${ getPupilActivity(pupil, activity).relativeTime }; --thickness: .5rem;`"
										role="progressbar"
									>
									</div>
								</div>
								<!-- feedback icon(s) -->
								<div style="grid-area: 1 / 1 / 2 / 2;" class="flex items-center justify-center">
									<div class="relative w-3/6 h-3/6">
										<FeedbackIcon :type="getPupilActivity(pupil, activity).getLeftFeedback()" class="absolute w-full h-full" />
										<!-- In case there there was feedback multiple time for this activity,
										AND the feedback was different, we show both icons. -->
										<div class="absolute z-10 w-1/2 h-full overflow-hidden">
											<FeedbackIcon :type="getPupilActivity(pupil, activity).getRightFeedback()" class="min-w-max h-full" />
										</div>
									</div>
								</div>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>

	</TheBoardLayout>
</template>


<style>
#reflection-board table td.highlighted {
	@apply bg-yellow-50;
}
</style>


<script lang="ts">
import { mapStores, storeToRefs } from "pinia";
import { usePupilsStore } from '@/stores/Pupils.store';
import { useActivitiesStore } from '@/stores/Activities.store';

import TheBoardLayout from './layout/TheBoardLayout.vue';
import LoadingScreen from "./ui/LoadingScreen.vue";
import ActivitySymbol from "./ui/symbols/ActivitySymbol.vue";
import ActivityBoardAvatar from "./ActivityBoardAvatar.vue";
import FeedbackIcon from "./ui/symbols/FeedbackIcon.vue";

import { XMarkIcon } from "@heroicons/vue/24/outline";
import { useActivityBoardStore } from "@/stores/ActivityBoard.store";
import { useInsightsStore } from "@/stores/Insights.store";
import { DateTime, Interval } from 'luxon';
import { PupilActivity } from "@/models/PupilActivity.model";
import { Activity } from "@/models/Activity.model";
import { Pupil } from "@/models/Pupil.model";
import {useClassroomsStore} from "@/stores/Classrooms.store";
import {useActivityBoardConnectStore} from "@/stores/ActivityBoardConnect.store";
import {InsightsFilterDef} from "@/stores/filters/InsightsFilterDef";

class PupilActivityInsight {
	public relativeTime: number = 0;
	public feedback: {
		good: number,
		neutral: number,
		bad: number,
	} = {
		good: 0,
		neutral: 0,
		bad: 0,
	};

	getLeftFeedback() {
		if (this.feedback.good > 0) {
			return 'good';
		}

		if (this.feedback.bad > 0) {
			return 'bad';
		}

		if (this.feedback.neutral > 0) {
			return 'neutral';
		}

		return 'unknown';
	}

	getRightFeedback() {
		if (this.feedback.bad > 0) {
			return 'bad';
		}

		if (this.feedback.good > 0) {
			return 'good';
		}

		if (this.feedback.neutral > 0) {
			return 'neutral';
		}

		return 'unknown';
	}
}

export default {

	components: {
		TheBoardLayout,
		LoadingScreen,
		ActivitySymbol,
		ActivityBoardAvatar,
		FeedbackIcon,
		XMarkIcon,
	},

	data() {
		return {
			boardLoading: false,
			filter: null,
			pupils: null,
			activities: null,
			pupilSummary: new Map<string, Map<string, PupilActivityInsight>>(),
		};
	},

	computed: {
		...mapStores(
			usePupilsStore,
			useActivitiesStore,
			useActivityBoardStore,
			useInsightsStore,
			useClassroomsStore,
			useActivityBoardConnectStore
		)
	},

	mounted() {

		if (!this.classroomsStore.currentClassroom) {
			this.$router.push({name: 'activity-board-select'});
			return;
		}

		let dateRange;
		switch (this.$route.meta.dateRange) {
			case 'today':
			default:
				dateRange = Interval.fromDateTimes(DateTime.now().startOf('day'), DateTime.now().endOf('day'))
				break;

			case 'yesterday':
				dateRange = Interval.fromDateTimes(
					DateTime.now().minus({days: 1}).startOf('day'),
					DateTime.now().minus({days: 1}).endOf('day')
				);
				break;
		}

		this.filter = new InsightsFilterDef();
		this.filter.setParamValue(InsightsFilterDef.FILTER_DATERANGE, dateRange.toISO());

		this.reload();

	},

	methods: {

		highlightCol(event) {
			// clear any existing highlighting first
			const cells = document.querySelectorAll('td');
			cells.forEach(cell => {
				cell.classList.remove('highlighted');
			});

			let el = null;
			if(event.target.tagName === 'TD') {
				el = event.target;
			} else {
				el = event.target.closest('td');
			}

			const col = el.dataset.col;
			// const row = el.dataset.row;

			const colCells = document.querySelectorAll(`[data-col="${col}"]`);
			// const rowCells = document.querySelectorAll(`[data-row="${row}"]`);

			colCells.forEach(cell => {
				cell.classList.add('highlighted');
			});
		},

		async reload() {
			this.boardLoading = true;

			await this.insightsStore.loadFeedback(this.filter);
			this.recalculateInsights();

			this.boardLoading = false;
		},

		recalculateInsights() {

			this.pupils = [];
			this.activities = [];
			this.pupilSummary = new Map<Pupil, Map<Activity, PupilActivityInsight>>();

			this.insightsStore.feedback.forEach(
				(feedback) => {

					if (!this.pupils.find(pupil => pupil.id === feedback.pupil.id)) {
						this.pupils.push(feedback.pupil);
					}

					let pupilMap = new Map<string, PupilActivityInsight>();

					// First calculate total duration, will make next loop cheaper.
					let totalTime = 0;
					feedback.activities.forEach(
						(pupilActivity: PupilActivity) => {
							if (pupilActivity.duration) {
								totalTime += pupilActivity.duration.toMillis();
							}
						}
					);

					feedback.activities.forEach(
						(pupilActivity: PupilActivity) => {
							if (!this.activities.find(a => a.id === pupilActivity.activity.id)) {
								this.activities.push(pupilActivity.activity);
							}

							if (!pupilMap.get(pupilActivity.activity.id)) {
								pupilMap.set(pupilActivity.activity.id, new PupilActivityInsight());
							}

							if (totalTime > 0) {
								pupilMap.get(pupilActivity.activity.id).relativeTime = Math.floor(pupilActivity.duration.toMillis() / totalTime * 100);
							}

							if (typeof(pupilActivity) === 'undefined') {

							} else {

								switch (pupilActivity.feedback) {
									case 1:
										pupilMap.get(pupilActivity.activity.id).feedback.good++;
										break;
									case 0:
										pupilMap.get(pupilActivity.activity.id).feedback.neutral++;
										break;
									case -1:
										pupilMap.get(pupilActivity.activity.id).feedback.bad++;
										break;
								}

							}
						}
					);

					this.pupilSummary.set(feedback.pupil.id, pupilMap);
				}
			)

		},

		closeBoard() {
			this.activityBoardStore.closeBoard();
			this.$router.push({ name: 'activity-board-select' });
		},

		getPupilActivity(pupil: Pupil, activity: Activity)
		{
			return this.pupilSummary.get(pupil.id)?.get(activity.id);
		}
	},
}
</script>
