<template>
	<div class="w-48">
	<SearchBox v-model="searchQuery" :searchResults="searchResultsPerYear" :searching="searching" :placeholder="$t('Search week by theme')">
		<template #item="itemProps">
			<div class="w-full flex flex-col gap-2 px-4">
				<div class="py-1 mt-1 text-base-content-light px-4 bg-base-200 rounded">{{ itemProps.year }}</div>
				<div v-for="result in itemProps.results" class="py-2 rounded flex justify-between items-start hover:bg-base-100">


					<div class="px-1 shrink-0 flex flex-col justify-start">
						<div class="font-bold text-lg">
							<span class="lowercase">{{ $t('Wk') }}</span>
							{{ weekNumber(result.firstDayOfWeek) }}
						</div>
						<span class="text-lg sm:text-xs">{{ mondayText(result.interval.start) }} - {{ fridayText(result.interval.end) }}</span>
					</div>
					<div class="flex-grow">
						<span v-for="theme in result.themes" class="badge badge-accent ml-2 inline-block overflow-hidden whitespace-nowrap text-ellipsis" style="max-width: 8rem">
							{{ theme }}
						</span>
					</div>
					<div class="flex gap-2 pr-1">
						<button @click="goToSelectedWeek(result.firstDayOfWeek)" class="btn btn-ghost bg-base-100 btn-sm" :title="$t('Show week')">
							<CalendarDaysIcon class="w-5 h-5"/>
						</button>
						<button @click="copyToClipboard(result)" class="btn btn-ghost bg-base-100 btn-sm" :title="$t('Add week to clipboard')">
							<DocumentDuplicateIcon class="w-5 h-5"/>
						</button>
					</div>
				</div>
			</div>
		</template>
	</SearchBox>
	</div>
</template>

<script lang="ts">
import { DateTime, Interval } from "luxon";
import SearchBox from './ui/SearchBox.vue';

import { CalendarDaysIcon, DocumentDuplicateIcon } from "@heroicons/vue/24/outline";
import {mapStores} from "pinia";
import {useClipboardStore} from "@/stores/Clipboard.store";
import {useLessonsStore} from "@/stores/Lessons.store";
import {useThemesStore} from "@/stores/Themes.store";

export default {
	emits: ['agendaGoTo'],

	components: {
		SearchBox,
		CalendarDaysIcon, DocumentDuplicateIcon,
	},

	data() {
		return {
			searchQuery: '',
			searchResults: [],
			searching: false,
			timeoutId: undefined,
		}
	},

	computed: {

		...mapStores(useClipboardStore),
		...mapStores(useLessonsStore, useThemesStore),

		searchResultsPerYear() {
			// sort (descending)
			this.searchResults.sort(
				(a, b) => b.firstDayOfWeek - a.firstDayOfWeek
			);

			let resultsPerYear = [];
			for (const result of this.searchResults) {
				const year = result.firstDayOfWeek.year;
				let index = resultsPerYear.findIndex((item) => item.year === year);
				if (index < 0) {
					resultsPerYear.push(
						{
							year: year,
							results: []
						});
					index = resultsPerYear.length - 1;
				}
				resultsPerYear[index].results.push(result);
			}
			return resultsPerYear;
		}
	},

	watch: {
		searchQuery() {
			this.handleQueryChange();
		}
	},

	methods: {
		weekNumber(date) {
			return date.toFormat('WW');
		},

		mondayText(date) {
			return date.toFormat('d LLL');
		},

		fridayText(date) {
			return date.plus({ days: 4 }).toFormat('d LLL');
		},

		/**
		 *
		 */
		handleQueryChange() {
			if(!this.searchQuery) {
				this.searchResults = [];
				this.searching = false;
				return;
			}
			this.searching = true;

			// Some logic to only start a search after the user has stopped typing for 1 second
			if (this.timeoutId) {
				clearTimeout(this.timeoutId);
			}
			this.timeoutId = setTimeout(() => { this.search(this.searchQuery) }, 500);
		},

		/**
		 * @param query
		 */
		async search(query: string) {

			if(!query) {
				this.searchResults = [];
				this.searching = false;
				return;
			}

			this.searching = true;

			const themes = await this.themesStore.findThemes(query);
			this.searchResults = [];


			this.searchResults = themes.map(
					theme => {

						const interval = Interval.fromDateTimes(theme.start, theme.end);
						try {
							return {
								firstDayOfWeek: DateTime.fromJSDate(theme.start).startOf('week'),
								themes: [
									theme.name
								],
								interval
							}
						} catch (e) {
							console.warn(e);
							return null;
						}
					}
			).filter(result => result !== null);

			this.searching = false;
		},

		copyToClipboard(result) {
			this.clipboardStore.copy(result.interval, result.themes);
		},

		goToSelectedWeek(date) {
			this.$emit('agendaGoTo', 'date', date);
		},
	}
}
</script>
