<template>

	<ActivitySymbolWithFileIcon v-bind="$attrs"
		v-if="isFileIcon"
		:icon="activity.icon"
		:disabled="disabled"
		style="display: inline-block"
	/>

	<ActivitySymbolWithSpriteIcon v-bind="$attrs"
		v-else-if="isSpriteIcon"
		:icon="activity.icon"
		:disabled="disabled"
	/>

	<ActivitySymbolWithSpriteIcon v-bind="$attrs"
		v-else
		:icon="null"
		:disabled="disabled"
	/>

</template>


<script lang="ts">

import ActivitySymbolWithFileIcon from './ActivitySymbolWithFileIcon.vue';
import ActivitySymbolWithSpriteIcon from './ActivitySymbolWithSpriteIcon.vue';
import { AbstractHasIcon } from "@/models/AbstractHasIcon.model";
import { FileIcon, SpriteIcon } from "@/models/Icon.model";

export default {
	inheritAttrs: false,
	props: {
		activity: {
			required: true,
      		type: AbstractHasIcon
		},
		disabled: {
			type: Boolean,
			default: false
		},
	},

	components: {
		ActivitySymbolWithFileIcon,
		ActivitySymbolWithSpriteIcon
	},

	computed: {
		isFileIcon(): boolean {
			return this.activity.icon instanceof FileIcon;
		},
		isSpriteIcon(): boolean {
			return this.activity.icon instanceof SpriteIcon;
		},
	},
}

</script>
