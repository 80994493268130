import { Organisation } from "@/models/Organisation.model";

export class UserRole {


	constructor(
		public organisation : Organisation,
		public role : string,
		public active : boolean
	) {}

	static mapFromServer(data: any) {
		const orgUser = new UserRole(
			Organisation.mapFromServer(data.organisation),
			data.role,
			data.active
		);
		return orgUser;
	}

	hasOwnerPermissions() {
		return this.role === 'owner';
	}

	hasAdminPermissions() {
		return this.role === 'admin' || this.hasOwnerPermissions();
	}

	hasTeacherPermissions() {
		return true;
	}

	clone() {
		if (!this.organisation) {
			throw new Error('No organisation set');
		}

		const clonedInstance = new UserRole(
			this.organisation.clone(),
			this.role,
			this.active
		);

		return clonedInstance;
	}
}
