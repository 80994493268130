<template>
	<div class="overflow-x-auto h-full relative">

		<table class="h-1 table-fixed border-collapse">
			<thead class="sticky top-0 z-10">
				<tr class="bg-base">
					<th class="w-96 font-normal text-left sticky left-0 right-0 z-10 bg-base/95"><!-- z-value as a consequence of rotate-180 transformation -->

						<!-- FILTER BEGIN -->
						<div class="w-full h-full flex flex-col justify-between items-start border-r-4 relative">

							<div class="absolute w-full h-full z-20 grid place-content-center bg-base/70" :class="[loading? 'visible' : 'invisible']">
								<span class="loading loading-spinner loading-md"></span>
							</div>

							<div class="w-full pl-1 pr-4 pt-1 flex flex-col mb-2">
								<div class="flex flex-col gap-1 p-2 outline-offset-1 outline-accent2 rounded" :class="[goalScope === 'planned'? 'outline' : '']">
									<div class="flex items-center gap-3">
										<input type="radio" id="opt-planned" v-model="goalScope" value="planned" class="radio radio-sm" />
										<label for="opt-planned" class="text-sm">Ingeplande doelen</label>
									</div>
									<div v-show="goalScope === 'planned'" class="text-xs pl-8 text-base-content-light relative group">
										<ul class="goal-scope-options">
											<li :class="[isGoalScopeOptionSelected('planned','theme-goals')? '' : 'line-through']">themadoelen</li>
											<li :class="[isGoalScopeOptionSelected('planned','class-goals')? '' : 'line-through']">doelen bij klassikale lessen</li>
											<li :class="[isGoalScopeOptionSelected('planned','freeplay-goals')? '' : 'line-through']">doelen bij keuze-activiteiten</li>
										</ul>
										<button type="button" class="absolute top-0 left-0 w-full h-full hidden group-hover:block" @click="showGoalScopeOptions = true">
											<div class="absolute top-0 right-0 btn btn-xs btn-circle">
												<PencilIcon class="w-3 h-3" />
											</div>
										</button>
									</div>
								</div>
								<div class="flex flex-col gap-1 p-2 outline-offset-1 outline-accent2 rounded" :class="[goalScope === 'touched'? 'outline' : '']">
									<div class="flex items-center gap-3">
										<input type="radio" id="opt-touched" v-model="goalScope" value="touched" class="radio radio-sm" />
										<label for="opt-touched" class="text-sm">Doelen waarrond gewerkt werd</label>
										<QuestionMarkCircleIcon class="w-5 h-5 text-base-content-light" />
									</div>
									<div v-show="goalScope === 'touched'" class="text-xs pl-8 text-base-content-light relative group">
										<ul class="goal-scope-options">
											<li :class="[isGoalScopeOptionSelected('touched','class-goals')? '' : 'line-through']">tijdens klassikale lessen</li>
											<li :class="[isGoalScopeOptionSelected('touched','freeplay-goals')? '' : 'line-through']">tijdens keuze-activiteiten</li>
										</ul>
										<button type="button" class="absolute top-0 left-0 w-full h-full hidden group-hover:block" @click="showGoalScopeOptions = true">
											<div class="absolute top-0 right-0 btn btn-xs btn-circle">
												<PencilIcon class="w-3 h-3" />
											</div>
										</button>
									</div>
								</div>
								<div class="flex flex-col gap-1 p-2 outline-offset-1 outline-accent2 rounded" :class="[goalScope === 'unplanned'? 'outline' : '']">
									<div class="flex items-center gap-3">
										<input type="radio" id="opt-unplanned" v-model="goalScope" value="unplanned" class="radio radio-sm" />
										<label for="opt-unplanned" class="text-sm">Niet-ingeplande doelen</label>
									</div>
								</div>
							</div>
							<select class="select select-ghost min-h-0">
								<option value="">Alle domeinen</option>
								<option v-for="group in groupedDummyGoals" :key="group.groupName">{{ group.groupName }}</option>
							</select>
						</div>
						<!-- FILTER END -->
					</th>
					<th v-for="pupil in dummyPupils" class="text-left border-r">
						<!-- wrap in div for writing mode to work in Safari -->
						<div class="h-52 min-w-10 rotate-180 truncate py-4 px-2.5 font-normal text-sm " :title="pupil.name" style="writing-mode: vertical-lr;">{{ pupil.name }}</div>
					</th>
				</tr>
			</thead>
			<tbody>
				<template v-for="group in groupedDummyGoals" :key="group.groupName">
					<tr class="sticky top-52 z-20 bg-base border-b-2 border-accent2">
						<th class="bg-accent2/20 px-4 py-2 rounded-l-md text-left sticky left-0 right-0">{{ group.groupName }}</th>
						<td class="bg-accent2/20 rounded-r-md" colspan="100"></td>
					</tr>
					<tr v-for="goal in group.goals" :key="goal.id" class="hover:outline -outline-offset-1 outline-accent2">
						<th class="max-w-96 text-sm border-b text-left sticky left-0 bg-base/90 font-normal">
							<div class="h-full border-r-4 px-4 py-3 truncate" :title="goal.title">
								<span class="text-xs inline-block min-w-10 text-base-content-light">{{ goal.fullPath }}</span>
								<GoalTitle :goal="goal" />
							</div>
						</th>
						<td v-for="pupil in dummyPupils" class=" border-r border-b">
							<button type="button" class="flex justify-center items-center h-full w-full" @click="evaluatedGoal = goal.id">
								<div class="rounded-full w-6 h-6 self-center" :class="colorForMockup(goal.id, pupil.id)"></div>
							</button>
						</td>
					</tr>
				</template>
			</tbody>
		</table>

	</div>

	<Modal v-if="showGoalScopeOptions" :modalTitle="$t('Which goals to include')" width="md" @requestUnmount="showGoalScopeOptions = null">
		<template  #default="{ hide }">
			<form @submit.prevent="saveGoalScopeOptions">
				<input type="hidden" name="goalScope" :value="goalScope" />
				<template v-if="goalScope === 'planned'">
					<div class="my-4">{{ $t('Goals that were planned') }}</div>
					<ul class="mb-8 flex flex-col gap-2">
						<li class="flex gap-3 items-center">
							<input type="checkbox" id="cb-tg-opt1" class="checkbox checkbox-sm" name="goalScopeOptionsPlanned" value="theme-goals" :checked="isGoalScopeOptionSelected('planned', 'theme-goals')" />
							<label for="cb-tg-opt1" class="lowercase">{{ $tc('Theme goal', 2) }}</label>
						</li>
						<li class="flex gap-3 items-center">
							<input type="checkbox" id="cb-tg-opt2" class="checkbox checkbox-sm" name="goalScopeOptionsPlanned" value="class-goals" :checked="isGoalScopeOptionSelected('planned', 'class-goals')" />
							<label for="cb-tg-opt2">doelen bij klassikale lessen</label>
						</li>
						<li class="flex gap-3 items-center">
							<input type="checkbox" id="cb-tg-opt3" class="checkbox checkbox-sm" name="goalScopeOptionsPlanned" value="freeplay-goals" :checked="isGoalScopeOptionSelected('planned', 'freeplay-goals')" />
							<label for="cb-tg-opt3">doelen bij keuze-activiteiten</label>
						</li>
					</ul>
				</template>
				<template v-else-if="goalScope === 'touched'">
					<div class="my-4">Doelen waarrond gewerkt werd</div>
					<ul class="mb-8 pl-2 flex flex-col gap-2">
						<li class="flex gap-3 items-center">
							<input type="checkbox" id="cb-tg-opt1" class="checkbox checkbox-sm" name="goalScopeOptionsTouched" value="class-goals" :checked="isGoalScopeOptionSelected('touched', 'class-goals')" />
							<label for="cb-tg-opt1">tijdens klassikale lessen</label>
						</li>
						<li class="flex gap-3 items-center">
							<input type="checkbox" id="cb-tg-opt2" class="checkbox checkbox-sm" name="goalScopeOptionsTouched" value="freeplay-goals" :checked="isGoalScopeOptionSelected('touched', 'freeplay-goals')" />
							<label for="cb-tg-opt2">tijdens keuze-activiteiten</label>
						</li>
					</ul>
				</template>
				<div class="flex justify-end gap-2 mt-4">
					<button type="button" class="btn btn-ghost" @click="hide">{{ $t('Cancel') }}</button>
					<button type="submit" class="btn btn-primary">{{ $t('Apply') }}</button>
				</div>
			</form>
		</template>
	</Modal>

	<EvaluationEditor v-if="evaluatedGoal" @requestClose="closeEvaluationEditor" />

</template>


<style>
.goal-scope-options li:not(:last-child)::after {
	content: ' +';
}
.goal-scope-options li {
	float: left;
	margin-right: 0.25rem;
}
</style>



<script lang="ts">
import { InsightsFilterDef } from "@/stores/filters/InsightsFilterDef";
import { QuestionMarkCircleIcon, PencilIcon } from "@heroicons/vue/24/solid";
import GoalTitle from "@/components/GoalTitle.vue";
import Modal from "@/components/ui/Modal.vue";
import EvaluationEditor from "@/components/EvaluationEditor.vue";

export default {

	props: {
		filter: {
			type: InsightsFilterDef,
			required: true
		},
	},

	components: {
		QuestionMarkCircleIcon, PencilIcon,
		GoalTitle,
		Modal,
		EvaluationEditor,
	},

	data() {
		return {
			loading: false,
			goalScope: 'planned',
			showGoalScopeOptions: false,
			evaluatedGoal: null,
			filterWorkingCopy: {	// TODO: should be initialized with the filter prop I guess
				'goalScope': ['planned'],
				'plannedGoalScopeOptions': [
					'theme-goals', 'class-goals', 'freeplay-goals',
				],
				'touchedGoalScopeOptions': [
					'class-goals', 'freeplay-goals'
				],
			},
			// TODO: remove dummy data, mockup purposes only
			dummyPupils: [
				{ id: 1, name: 'Barry Callebaut' }, { id: 2, name: 'Simon Avermans' }, { id: 3, name: 'Valerie Van der Rijksaarde' }, { id: 4, name: 'Jamie Jamoboree' }, { id: 5, name: 'Lore Vereenmaakschaevegem' },
				{ id: 11, name: 'Barry Callebaut' }, { id: 12, name: 'Simon Avermans' }, { id: 13, name: 'Valerie Van der Rijksaarde' }, { id: 14, name: 'Jamie Jamoboree' }, { id: 15, name: 'Lore Vereenmaakschaevegem' },
				{ id: 21, name: 'Barry Callebaut' }, { id: 22, name: 'Simon Avermans' }, { id: 23, name: 'Valerie Van der Rijksaarde' }, { id: 24, name: 'Jamie Jamoboree' }, { id: 25, name: 'Lore Vereenmaakschaevegem' },
				{ id: 31, name: 'Barry Callebaut' }, { id: 32, name: 'Simon Avermans' }, { id: 33, name: 'Valerie Van der Rijksaarde' }, { id: 34, name: 'Jamie Jamoboree' }, { id: 35, name: 'Lore Vereenmaakschaevegem' },
				{ id: 41, name: 'Barry Callebaut' }, { id: 42, name: 'Simon Avermans' }, { id: 43, name: 'Valerie Van der Rijksaarde' }, { id: 44, name: 'Jamie Jamoboree' }, { id: 45, name: 'Lore Vereenmaakschaevegem' },
			],
			groupedDummyGoals: [
				{ groupName: 'Ik en de wereld', goals: [
					{ id: 1, fullPath: '4.1.7', title: 'Ik kan de verschillende delen van een boom aanduiden', isSubgoal: () => false },
					{ id: 2, fullPath: '4.2.3', title: 'Ik kan een vogel van een schildpad onderscheiden', isSubgoal: () => false },
				] },
				{ groupName: 'Ysbergrekenen', goals: [
					{ id: 33, fullPath: '8.4.3', title: 'Ik herken een cirkel, driehoek, vierkant en ovaal', isSubgoal: () => false },
					{ id: 34, fullPath: '8.4.6', title: 'Ik ken de cijfers tot 10', isSubgoal: () => false },
					{ id: 35, fullPath: '8.4.7', title: 'Ik ken de cijfers tot 20', isSubgoal: () => false },
					{ id: 36, fullPath: '8.4.8', title: 'Ik ken de cijfers tot 30', isSubgoal: () => false },
					{ id: 37, fullPath: '8.4.9', title: 'Ik ken de cijfers tot 40', isSubgoal: () => false },
					{ id: 38, fullPath: '8.4.10', title: 'Ik ken de cijfers tot 50', isSubgoal: () => false },
				] },
				{ groupName: 'Socio-emotionele vaardigheden', goals: [
					{ id: 43, fullPath: '1.4.3', title: 'Ik sta mijn mannetje in de groep', isSubgoal: () => false },
					{ id: 44, fullPath: '1.4.6', title: 'Ik kan mezelf vlot uitdrukken', isSubgoal: () => false },
					{ id: 45, fullPath: '1.4.7', title: 'Ik kan ...', isSubgoal: () => false },
					{ id: 46, fullPath: '1.4.8', title: 'Ik ken ...', isSubgoal: () => false },
					{ id: 47, fullPath: '1.4.9', title: 'Ik kan ...', isSubgoal: () => false },
					{ id: 48, fullPath: '1.4.10', title: 'Ik kan ...', isSubgoal: () => false },
				] },
			],
		}
	},

	methods: {

		// TODO: remove this method, mockup purposes only
		colorForMockup(goalId, pupilId) {
			const colors = [ 'bg-gray-200', 'bg-green-500', 'bg-amber-400', 'bg-red-400', 'bg-blue-500' ];
			return colors[(goalId + 2*pupilId) % colors.length];
		},

		isGoalScopeOptionSelected(scope: string, option: string): boolean {
			if(scope === 'planned') {
				return this.filterWorkingCopy.plannedGoalScopeOptions.includes(option);
			} else if (scope === 'touched') {
				return this.filterWorkingCopy.touchedGoalScopeOptions.includes(option);
			}
			return false;
		},

		saveGoalScopeOptions(formData) {
			const formElements = formData.target.elements;
			const goalScope = formElements.goalScope.value;
			const optionInputs = formElements.goalScopeOptionsPlanned || formElements.goalScopeOptionsTouched;

			// count the number of checked options in the NodeList
			let count = 0;
			optionInputs.forEach((option: HTMLInputElement) => {
				if (option.checked) {
					count++;
				}
			});

			if(count === 0) {
				this.$notify({
					title: "Error",
					text: "Gelieve minstens één optie te selecteren.",
					type: "error",
				});
				return;
			}

			if(goalScope === 'planned') {
				this.filterWorkingCopy.plannedGoalScopeOptions = [];
				// extract the selected options from the form data and update the filter
				optionInputs.forEach((option: HTMLInputElement) => {
					if (option.checked) {
						this.filterWorkingCopy.plannedGoalScopeOptions.push(option.value);
					}
				});
			} else if(goalScope === 'touched') {
				this.filterWorkingCopy.touchedGoalScopeOptions = [];
				optionInputs.forEach((option: HTMLInputElement) => {
					if (option.checked) {
						this.filterWorkingCopy.touchedGoalScopeOptions.push(option.value);
					}
				});
			}

			// TODO: APPLY FILTER
			// ...

			this.showGoalScopeOptions = false;
		},

		closeEvaluationEditor() {
			this.evaluatedGoal = null;
		},
	}

}
</script>