import { FilterDef } from "@/stores/filters/FilterDef";

export class GoalFilterDef extends FilterDef {
	constructor() {
		super([
			'mainGroups',
			'keywords',
			'tags'
		]);
	}
}
