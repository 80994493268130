import { Tag } from "@/models/Tag.model";
import { File } from "@/models/File.model";
import { User } from "@/models/User.model";
import { Goal } from "@/models/Goal.model";
import { Comparable } from "@/interfaces/Comparable.interface";
import { ApiErrors } from "@/stores/errors/ApiErrors";
import { FieldValidationError } from "@/stores/errors/FieldValidationError";
import { Model } from "@/stores/AbstractBase.store";

export class LessonPlan implements Model, Comparable {

	static createFromApiData(data: any) {
		const newObject = new LessonPlan();
		newObject.hydrateWithApiData(data);
		return newObject;
	}

	public id?: string = undefined;
	public originalId: string | undefined = undefined;
	public version: number = 0;
	public latestVersion: number | undefined = undefined;
	public title: string = '';
	public instructions: string = '';
	public supplies: string[] = [];
	public isGuided: boolean = false;
	public tags: Tag[] = [];
	public isSavedInLib: boolean = false;
	public attachments ?: File[] = [];
	public owner ?: User;
	public goals: Goal[] = [];
	public createdAt: Date | null = null;

	get themeTags(): Tag[] {
		return this.tags.filter(tag => tag.type === 'theme');
	}

	get activityTags(): Tag[] {
		return this.tags.filter(tag => tag.type === 'activity');
	}

	set themeTags(tags: Tag[]) {
		tags.forEach(tag => tag.type = 'theme')

		this.tags = this.tags.filter(tag => tag.type !== 'theme');
		this.tags.push(...tags);
	}

	set activityTags(tags: Tag[]) {
		tags.forEach(tag => tag.type = 'activity')

		this.tags = this.tags.filter(tag => tag.type !== 'activity');
		this.tags.push(...tags);
	}

	setFromServerData(data: any) {
		console.warn('LessonPlan.setFromServerData is deprecated. Use hydrateWithApiData instead.');
		return this.hydrateWithApiData(data);
	}

	hydrateWithApiData(data: any) {
		this.id = data.id;
		this.version = data.version;
		this.latestVersion = data.latestVersion;
		this.title = data.title;
		this.instructions = data.instructions;

		this.isGuided = data.isGuided;
		this.isSavedInLib = data.inLibrary;
		this.createdAt = new Date(data.createdAt);

		this.tags = [];
		if (typeof (data.tags) !== 'undefined') {
			this.tags = Tag.mapManyFromServer(data.tags);
		}

		this.supplies = [];
		if (typeof (data.supplies) !== 'undefined') {
			this.supplies = data.supplies.map((supply: any) => supply.name);
		}

		this.attachments = [];
		if (data.attachments) {
			this.attachments = data.attachments.map((fileData: any) => File.mapFromServer(fileData));
		}

		if (data.owner) {
			this.owner = User.mapFromServer(data.owner);
		}

		if (data.goals) {
			this.goals = data.goals.map((goalData: any) => Goal.mapFromServer(goalData));
		}

		return this;
	}

	clone(): LessonPlan {
		let clonedInstance: LessonPlan = Object.assign(
			Object.create(
				Object.getPrototypeOf(this)
			),
			this
		);

		clonedInstance.tags = [ ...this.tags ];
		if (this.attachments) {
			clonedInstance.attachments = [ ...this.attachments ]
		}
		clonedInstance.goals = [ ...this.goals ];

		return clonedInstance;
	}

	isValid(): boolean {
		return this.title !== '' && this.title.length > 0;
	}

	validate(): void {
		if (!this.isValid()) {

			const error = new ApiErrors();
			error.fields['title'] = new FieldValidationError('title', [ 'The title field is required' ]);

			throw error;

		}
	}

	isLatestVersion(): boolean {
		return this.version === this.latestVersion;
	}

	setFromModel(model: LessonPlan) {
		this.id = model.id;
		this.version = model.version;
		this.latestVersion = model.latestVersion;
		this.title = model.title;
		this.instructions = model.instructions;
		this.isGuided = model.isGuided;
		this.tags = model.tags;
		this.isSavedInLib = model.isSavedInLib;
		this.supplies = model.supplies;

		if (model.attachments) {
			this.attachments = [ ... model.attachments ];
		}

		if (model.goals) {
			this.goals = [ ... model.goals ];
		}
	}

	getServerData(): any {
		console.warn('LessonPlan.getServerData is deprecated. Use asApiDataObject instead.');
		return this.asApiDataObject();
	}

	asApiDataObject(): any {
		const out: any = {
			id: this.id,
			originalId: this.originalId,
			version: this.version,
			title: this.title,
			instructions: this.instructions,
			supplies: this.supplies,
			isGuided: this.isGuided,
			tags: this.tags,
			inLibrary: this.isSavedInLib,
		};

		if (this.tags) {
			out.tags = this.tags.map(tag => tag.getServerData());
		}

		if (this.supplies) {
			out.supplies = [];
			this.supplies.forEach(supply => {
				out.supplies.push({
					name: supply
				});
			});
		}

		if (this.attachments) {
			out.attachments = this.attachments.map((file: File) => {
				return {
					id: file.id
				}
			});
		}

		if (this.goals) {
			out.goals = this.goals.map(goal => goal.getLinkData());
		}

		return out;
	}

	public equals(other: Comparable) {
		if (!(other instanceof LessonPlan)) {
			return false;
		}

		return JSON.stringify(this.getServerData()) === JSON.stringify(other.getServerData());
	}
}
