<template>

	<AgendaLessonPlanEditor
		v-if="selectedLessonItemForEditing !== null && drawerForm == 'lesson-plan'"
		:lessonItem="selectedLessonItemForEditing"
		:lesson="lesson"
		@requestClose="hideForm"
	/>

	<LessonItemSettings
		v-if="selectedLessonItemForEditing !== null && drawerForm == 'settings'"
		:lessonItem="selectedLessonItemForEditing"
		@requestClose="hideForm"
		@update:model-value="afterLessonItemChanged"
	/>

	<div class="flex justify-center print:hidden">
		<div v-show="isBoardTypeActivities" class="mb-4 border-4 border-primary rounded-lg inline-block w-full sm:max-w-xl">

			<MultiSelect
				:values="activityFilterValues"
				:selectedIds="selectedActivityIds"
				class="w-full"
				buttonFieldClasses="input-bordered"
				inputFieldClasses="input-bordered"
				@itemSelected="addLessonItemWithActivity"
				@itemUnselected="removeLessonItemWithActivity"
			>
				{{ $t('Select the activities to offer') }}

				<template #item="itemProps">
					<div class="w-full flex py-2 pl-3 hover:text-accent-content hover:bg-accent">
						<div class="flex-shrink-0 flex items-center justify-center w-16">
							<div class="avatar">
								<div class="w-10 mask mask-squircle">
									<ActivitySymbol :activity="itemProps.value.meta.activity" class="w-10" />
								</div>
							</div>
						</div>
						<div class="flex flex-1 items-center justify-between">
							<div class="flex-1 overflow-hidden pl-4 pr-12 py-2">
								{{ itemProps.value.label }}
								<!-- <p class="opacity-40">{{ itemProps.value.info }}</p> -->
							</div>
							<div class="flex-shrink-0">
								<span v-show="itemProps.selected" class="text-accent-content absolute inset-y-0 right-0 flex items-center pr-4">
									<CheckIcon class="h-7 w-7"></CheckIcon>
								</span>
							</div>
						</div>
					</div>
				</template>
			</MultiSelect>

		</div>
	</div>

	<div v-if="!isBoardTypeActivities" class="flex flex-col items-center gap-4">
		<div>{{ $t('Add a lesson plan') }}...</div>
		<div class="flex gap-4">
			<button type="button" class="btn btn-primary rounded-full" @click="handleIntentToAddLessonPlanToGeneralLesson(true)">
				<PlusIcon class="w-5 h-5 mr-1" />
				{{ $t('New') }}
			</button>
			<button type="button" class="btn btn-primary rounded-full" @click="handleIntentToAddLessonPlanToGeneralLesson(false)">
				<MagnifyingGlassIcon class="w-5 h-5 mr-1" />
				{{ $t('From library') }}
			</button>
		</div>
	</div>

	<div v-show="duplicateIconsInUse" class="alert alert-warning">
		<div class="flex gap-2">
			<svg xmlns="http://www.w3.org/2000/svg" class="stroke-current flex-shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" /></svg>
			<span>{{ $t('Warning: one or more icons will be displayed multiple times' )}}</span>
		</div>
	</div>

	<table v-if="isBoardTypeActivities || lesson.lessonItems.length" id="activities-list" class="w-full divide-y divide-base-200 mt-12">
		<thead>
			<tr class="">
				<th class="uppercase text-sm align-bottom text-left max-w-2xl p-2">
					<span v-if="isBoardTypeActivities">{{ $tc('Activity', 2) }}</span>
				</th>
				<th class="uppercase text-sm text-center">
					<span class="print:hidden">{{ $t('Lesson plan') }}</span>
				</th>
				<th class="align-bottom p-2 uppercase text-sm text-left">
					{{ $t('for') }}...
				</th>
				<th></th>
				<th></th>
			</tr>
			<tr v-if="isBoardTypeActivities && lesson.lessonItems.length">
				<th colspan="3"></th>
				<th class="relative">
					<div class="absolute bottom-full left-0 text-center bg-accent p-2 w-full rounded-t-lg">
						<span class="flex items-center gap-1 justify-center mb-1 text-accent-content pb-2 border-b border-accent-focus">
							<span class="uppercase text-sm font-bold">{{ $t('Activity board') }}</span>
						</span>
						<span v-show="isBoardTypeActivities && requiredSeats > 0" class="inline-flex gap-2 items-center tip-up px-2 py-1 rounded shadow-md" :class="[ sumActivitySeats >= requiredSeats? 'seats-ok' : 'seats-warning'  ]">
							<UsersIcon class="w-4 h-4 shrink-0" />
							<span class="text-sm">{{ sumActivitySeats }} <span class="opacity-50">/ {{ requiredSeats }}</span></span>
						</span>
					</div>
				</th>
				<th></th>
			</tr>
		</thead>

		<!--
			Empty state
		-->
		<tbody v-if="isBoardTypeActivities && !lesson.lessonItems.length">
			<tr>
				<td colspan="5">
					<div class="flex flex-col text-center gap-6 items-center text-base-content-light italic w-full sm:max-w-xs mx-auto rounded-lg mt-20">
						<svg class="w-20 h-20 opacity-30 block" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
							<path class="opacity-50" d="M496 384H176a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h320a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zm0-320H176a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h320a16 16 0 0 0 16-16V80a16 16 0 0 0-16-16zm0 160H176a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h320a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16z"/>
							<path d="M80 368H16a16 16 0 0 0-16 16v64a16 16 0 0 0 16 16h64a16 16 0 0 0 16-16v-64a16 16 0 0 0-16-16zm0-320H16A16 16 0 0 0 0 64v64a16 16 0 0 0 16 16h64a16 16 0 0 0 16-16V64a16 16 0 0 0-16-16zm0 160H16a16 16 0 0 0-16 16v64a16 16 0 0 0 16 16h64a16 16 0 0 0 16-16v-64a16 16 0 0 0-16-16z" />
						</svg>
						{{ $t('Go ahead and add the first activity') }}
					</div>
				</td>
			</tr>
		</tbody>
		<!--
			Non-empty state - Show list of activities
		-->
		<!-- <tbody v-else class="divide-y divide-base-200" id="activity-list"> -->
		<TransitionGroup name="animatedlist" tag="tbody" class="divide-y divide-base-200" id="activity-list">
			<tr v-for="(lessonItem, index) in lesson.lessonItems" :key="lessonItem.activity ? lessonItem.activity.id : index">
				<!-- <td class="px-2 py-2 max-w-lg group relative hover:bg-base-100"> -->
				<td class="px-2 py-3 max-w-lg align-top">
					<template v-if="lessonItem.activity">
						<h1 class="flex items-center font-bold">
							{{ lessonItem.activity.name }}
							<LifebuoyIcon v-if="lessonItem.lessonPlan?.isGuided" class="ml-2 w-6 h-6 text-success" />
						</h1>
						<h2 class="mb-2">
							<span v-if="lessonItem.lessonPlan?.title" class="text-base-content-light" v-text="lessonItem.lessonPlan.title"></span>
						</h2>
					</template>
					<template v-else>
						<h1 class="font-bold">
							<span v-if="lessonItem.lessonPlan?.title" v-text="lessonItem.lessonPlan.title"></span>
						</h1>
						<TextBlock :text="lessonItem.lessonPlan?.instructions" :renderUrls="true" class="text-sm" linkClass="hover:underline" />
					</template>
					<!-- <div @click="showForm('lesson-plan', lessonItem)" class="absolute top-2 right-2 hidden group-hover:inline-flex btn btn-circle btn-sm">
						<PencilIcon class="h-4 w-4"></PencilIcon>
					</div> -->
				</td>
				<td class="text-center align-top relative py-3">
					<button v-if="lessonItem.lessonPlan" type="button" class="btn btn-circle btn-sm relative print:hidden" @click="showForm('lesson-plan', lessonItem)" :class="[lessonItem.lessonPlan.isSavedInLib? 'btn-primary' : 'btn-ghost ring-2 ring-primary ring-inset']">
						<DocumentTextIcon class="w-4 h-4"></DocumentTextIcon>
					</button>
					<div v-else class="print:hidden">
						<div class="tooltip" :data-tip="$t('New')">
							<button type="button" class="join-item btn btn-circle btn-ghost bg-base-100 btn-sm" @click="handleIntentToAddLessonPlanToActivity(lessonItem, true)">
								<PlusIcon class="w-4 h-4"></PlusIcon>
							</button>
						</div>
						<div class="tooltip" :data-tip="$t('From library')">
							<button type="button" class="join-item btn btn-circle btn-ghost bg-base-100 btn-sm" @click="handleIntentToAddLessonPlanToActivity(lessonItem)">
								<MagnifyingGlassIcon class="w-4 h-4"></MagnifyingGlassIcon>
							</button>
						</div>
					</div>
				</td>
				<td class="px-2 py-4 align-top text-sm max-w-lg group relative" :colspan="isBoardTypeActivities? 1 : 2">
					<LessonItemSelectedPupilsSummary :lessonItem="lessonItem"></LessonItemSelectedPupilsSummary>
					<div @click="showForm('settings', lessonItem)" class="absolute top-2 right-2 hidden group-hover:inline-flex btn btn-circle btn-sm">
						<PencilIcon class="h-4 w-4"></PencilIcon>
					</div>
				</td>
				<td v-if="isBoardTypeActivities" class="px-2 py-2 align-top bg-accent group relative hover:bg-accent-focus seats-icon-cell w-36">
					<span class="whitespace-nowrap flex gap-2 items-center flex-shrink-0 justify-center mb-2">
						<ActivitySymbol :activity="lessonItem" class="w-12" v-if="lessonItem" />
						<span class="flex items-center justify-center gap-1 bg-accent-focus bg-opacity-30 rounded h-12 w-12 p-2 text-lg text-center">
							<UsersIcon class="w-4 h-4 shrink-0" />
							{{ lessonItem.seats }}
						</span>
					</span>
					<div @click="showForm('settings', lessonItem)" class="absolute top-2 right-2 hidden group-hover:inline-flex btn btn-circle btn-sm">
						<PencilIcon class="h-4 w-4"></PencilIcon>
					</div>
				</td>
				<td class="align-top text-center pl-2 py-3">
					<button @click="removeLessonItem(lessonItem)" class="btn btn-ghost btn-circle btn-sm bg-base-100 hover:bg-danger text-base-content-light hover:text-base print:hidden">
						<TrashIcon class="w-4 h-4"></TrashIcon>
					</button>
				</td>
			</tr>
		<!-- </tbody> -->
		</TransitionGroup>
	</table>


</template>


<style scoped>
.tip-up {
	position: relative;
}
.tip-up::after {
	position: absolute;
	top: -6px;
	left: 50%;
	content: "";
	width: 0;
	height: 0;
	border-left: 4px solid transparent;
	border-right: 4px solid transparent;
	border-bottom-width: 6px;
}
.seats-warning.tip-up::after {
	@apply border-b-danger;
}
.seats-warning {
	@apply bg-danger text-danger-content;
}
.seats-ok.tip-up::after {
	@apply border-b-teal-600;
}
.seats-ok {
	@apply bg-teal-600 text-base;
}

#activity-list tr:last-child td.seats-icon-cell {
    @apply rounded-b-lg;
}
</style>


<script lang="ts">
import { LessonItem } from "@/models/LessonItem.model";
import { Lesson } from "@/models/Lesson.model";

import {
	TrashIcon,
	PlusIcon,
	ArrowTopRightOnSquareIcon,
	RectangleGroupIcon,
	UserGroupIcon,
	Squares2X2Icon,
	PencilIcon,
	CheckIcon,
	CheckCircleIcon,
	LifebuoyIcon, ComputerDesktopIcon, UsersIcon,
	DocumentTextIcon,
	MagnifyingGlassIcon,
} from '@heroicons/vue/24/outline';
import Skeleton from '@/components/ui/Skeleton.vue';
import MultiSelect from '@/components/ui/MultiSelect.vue';
import {mapState, mapStores} from "pinia";
import {useActivitiesStore} from "@/stores/Activities.store";
import PupilSymbol from "@/components/ui/symbols/PupilSymbol.vue";
import ActivitySymbol from "@/components/ui/symbols/ActivitySymbol.vue";
import {usePupilsStore} from "@/stores/Pupils.store";
import {useClassroomsStore} from "@/stores/Classrooms.store";
import LessonItemSelectedPupilsSummary from "@/components/LessonItemSelectedPupilsSummary.vue";
import { useLessonPlansStore } from "@/stores/LessonPlans.store";
import TextBlock from "@/components/ui/TextBlock.vue";
import AgendaLessonPlanEditor from "@/components/AgendaLessonPlanEditor.vue";
import LessonItemSettings from "@/components/LessonItemSettings.vue";
import {Tag} from "@/models/Tag.model";
import {useThemesStore} from "@/stores/Themes.store";
import {Theme} from "@/models/Theme.model";
import {FilterParamValue} from "@/stores/filters/FilterParamValue";

export default {

	props: {
		lesson: {
			type: Lesson,
			required: true,
		}
	},

	components: {
		LessonItemSelectedPupilsSummary,
		PupilSymbol,
		Skeleton,
		MultiSelect,
		ArrowTopRightOnSquareIcon, PencilIcon, PlusIcon, RectangleGroupIcon, Squares2X2Icon,
		TrashIcon, UserGroupIcon, CheckIcon, CheckCircleIcon, LifebuoyIcon, UsersIcon, ComputerDesktopIcon,
		DocumentTextIcon, MagnifyingGlassIcon,
		ActivitySymbol,
		TextBlock,
		AgendaLessonPlanEditor,
		LessonItemSettings,
	},

	data() {
		return {
			lessonItems: [ ],
			drawerForm: null,
			// selectableActivities: [],
			// askToggleConfirmation: false,	// lesson type toggle (multi vs group activity)
			selectedLessonItemForEditing: null,
			duplicateIconsInUse: false,
			newType: null,
			isMounted: false,
		};
	},

	computed: {

		...mapStores(
			useActivitiesStore,
			usePupilsStore,
			useClassroomsStore,
			useLessonPlansStore,
			useThemesStore
		),

		...mapState(useActivitiesStore, ['activities']),
		...mapState(usePupilsStore, ['pupils']),

		requiredSeats() {
			return this.pupils.length;
		},

		sumActivitySeats() {
			if (!this.lesson) {
				return 0;
			}

			if(this.lesson.lessonItems.length) {
				return this.lesson.lessonItems.map(x => x.seats).reduce(function(prev, curr) { return prev + curr; });
			}
			return 0;
		},

		activityFilterValues() {
			return this.activities.map(activity => new FilterParamValue(activity.id, activity.name, { activity: activity }));
		},

		selectedActivityIds() {
			return this.lesson.lessonItems
					.filter(el => el.activity)
					.map(el => el.activity.id);
		},

		isBoardTypeActivities() {
			return this.lesson.boardType === Lesson.BOARD_TYPE_ACTIVITIES;
		},
	},

	mounted() {
		this.isMounted = true;
		this.initialize();
	},

	methods: {

		initialize() {
			this.activitiesStore.load();
			this.pupilsStore.load();

			this.checkForDuplicateIcons();
		},

		// 'Proxy' method (for readability)
		addLessonItemWithoutActivity() {
			return this.addLessonItem();
		},

		// 'Proxy' method (for readability)
		addLessonItemWithActivity(filterValue: FilterParamValue) {
			const activity = this.activitiesStore.activities.find(a => a.id == filterValue.id);
			this.addLessonItem(activity);
		},

		async handleIntentToAddLessonPlanToGeneralLesson(skipLibrary: boolean = false) {
			const li = this.addLessonItemWithoutActivity();
			this.selectedLessonItemForEditing = li;
			if(skipLibrary) {
				li.lessonPlan = await this.createLessonPlan(li);
			}
			this.showForm('lesson-plan', li);
		},

		async handleIntentToAddLessonPlanToActivity(lessonItem: LessonItem, skipLibrary: boolean = false) {
			if(skipLibrary) {
				lessonItem.lessonPlan = await this.createLessonPlan(lessonItem);
			}
			this.showForm('lesson-plan', lessonItem);
		},

		async createLessonPlan(lessonItem: LessonItem) {
			const plan = await this.lessonPlansStore.new(lessonItem, this.lesson);

			// Is there are active themes, add labels to the lesson plan
			const themes = this.themesStore.getActiveAt(this.lesson.start);

			const themeTags = [];
			themes.forEach((theme: Theme) => {
				const tag = new Tag();
				tag.name = theme.name;
				themeTags.push(tag);
			});

			plan.themeTags = themeTags;
			return plan;
		},

		/**
		 * Select an activity
		 * @param activity
		 */
		addLessonItem(activity = null) {
			let newLessonItem = LessonItem.create(activity);
			newLessonItem.allowedPupilIds = this.pupilsStore.pupils.map(p => p.id);

			this.lesson.addLessonItem(newLessonItem);

			if(this.isBoardTypeActivities) {
				this.checkForDuplicateIcons();
			}
			return newLessonItem;
		},

		// Remove the lesson item that has the given activity
		removeLessonItemWithActivity(filterValue: FilterParamValue) {
			let lessonItem = this.lesson.lessonItems.find(l => l.activity && l.activity.id == filterValue.id);
			if (lessonItem) {
				this.removeLessonItem(lessonItem);
			}
		},
		// Remove the lesson item with the given index
		removeLessonItem(lessonItem) {

			this.lesson.removeLessonItem(lessonItem);
			this.checkForDuplicateIcons();
		},

		hideForm() {

			// For general lessons, a lesson item without a lesson plan is pretty useless, so let's remove it again
			if(this.lesson.boardType !== Lesson.BOARD_TYPE_ACTIVITIES) {
				if(!this.selectedLessonItemForEditing.lessonPlan || !this.selectedLessonItemForEditing.lessonPlan.isValid()) {
					// No need to keep a lesson item without a valid lesson plan
					this.selectedLessonItemForEditing.lessonPlan = null;
					this.lesson.removeLessonItem(this.selectedLessonItemForEditing);
				// } else {
				// 	if(!this.lesson.title) {
				// 		// If the lesson has no title yet, we might as well populate it with the title of the first lesson plan
				// 		this.lesson.title = this.selectedLessonItemForEditing.lessonPlan.title;
				// 	}
				}
			} else if(this.selectedLessonItemForEditing.lessonPlan && !this.selectedLessonItemForEditing.lessonPlan.isValid()) {
				// For a choice activity, no need to keep an invalid lesson plan
				this.selectedLessonItemForEditing.lessonPlan = null;
			}

			this.selectedLessonItemForEditing = null;
			this.drawerForm = null;

			this.checkForDuplicateIcons();
		},

		showForm(form, lessonItem: LessonItem) {
			this.selectedLessonItemForEditing = lessonItem;
			this.drawerForm = form;
		},

		afterLessonItemChanged(updatedItem: LessonItem) {
			this.selectedLessonItemForEditing.setFromModel(updatedItem);

			this.checkForDuplicateIcons();
		},

		checkForDuplicateIcons() {
			let iconList = [];
			for(const lessonItem of this.lesson.lessonItems) {
				let icon = lessonItem.icon;
				if (icon) {
					for (let i = 0; i < iconList.length; i++) {
						if (iconList[i].equals(icon)) {
							this.duplicateIconsInUse = true;
							return;
						}
					}
					iconList.push(icon);
				}
			}
			this.duplicateIconsInUse = false;
		},

	}
}
</script>
