import { defineStore } from 'pinia';

import { Observation } from "@/models/Observation.model";
import { Pupil } from "@/models/Pupil.model";
import { useClassroomsStore } from "@/stores/Classrooms.store";
import SmaSyLocalStorage from "@/utils/SmaSyLocalStorage.util";
import { useOrganisationsStore } from "@/stores/Organisations.store";
import { ObservationFilterDef } from "@/stores/filters/ObservationFilterDef";
import { AbstractBaseStore, AbstractStoreState } from "@/stores/AbstractBase.store";
import { useDefaultOrderStore } from './DefaultOrder.store';

interface ObservationStoreState extends AbstractStoreState<Observation> {

}

const BaseStore = AbstractBaseStore<Observation, ObservationStoreState>();

/**
 * Observation store.
 */
export const useObservationsStore = defineStore('observations', {

	/**
	 * Return the initial state of the store.
	 * @returns ObservationStoreState
	 */
	state: (): ObservationStoreState => {

		// Set the default filter
		const defaultFilter = new ObservationFilterDef();
		let interval = useOrganisationsStore().currentOrganisation?.getCurrentSchoolYearInterval();
		if (interval) {
			defaultFilter.setInterval(interval);
		}

		// Load the last known filter from local storage
		const storedFilterData = SmaSyLocalStorage.getCurrentUserItem('observationFilters');
		let initialFilter: ObservationFilterDef | null = null;
		if (storedFilterData) {
			initialFilter = (new ObservationFilterDef).fromJson(storedFilterData);
		}

		// Base state from the AbstractBaseStore
		return BaseStore.state({

			defaultFilter,
			initialFilter,
			order: useDefaultOrderStore().observationsOrder,

		});

	},

	getters: {

		...BaseStore.getters,

		apiPath(): string {
			return 'observations';
		},

		globalQueryParameters() {

			return {
				classroomIds: useClassroomsStore().currentClassroom?.id
			}

		},

		defaultMask() {
			return [
				'*',
				'files.*',
				'files.variations.*',
				'classroom.id',
				'pupil.*',
				'pupil.icon.*',
				'pupil.classrooms.id',
				'pupilActivity.*',
				'pupilActivity.lessonItem.*',
				'pupilActivity.lessonItem.icon.*',
				'pupilActivity.lessonItem.lesson.*',
				'pupilActivity.lessonItem.activity.*',
				'pupilActivity.lessonItem.lessonPlan.*',
			];
		},

	},

	actions: {

		...BaseStore.actions,

		mapToItem(data: any): Observation {
			return Observation.createFromApiData(data);
		},

		new(pupil: Pupil) {
			let newObservation = new Observation(pupil);

			const currentClassroom = useClassroomsStore().currentClassroom;
			if (!currentClassroom) {
				throw new Error('No current classroom set');
			}
			
			newObservation.classroomId = currentClassroom.id;
			newObservation.note = "";

			return newObservation;
		},

		applyFilter(filter: ObservationFilterDef) {
			SmaSyLocalStorage.setCurrentUserItem('observationFilters', filter, 60 * 60 * 8);
			return this.superApplyFilter(filter);
		},

		resetFilter() {
			SmaSyLocalStorage.removeCurrentUserItem('observationFilters');
			return this.superResetFilter();
		}
	}

});
