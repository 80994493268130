import { FilterDef } from "@/stores/filters/FilterDef";
import { Interval } from "luxon";

import {FilterParamValue} from "@/stores/filters/FilterParamValue";

export class ObservationFilterDef extends FilterDef {

	constructor() {
		super([
			'pupilIds',
			'activityIds',
			'start',
			'end',
			'query'
		]);
	}

	setInterval(interval: Interval | null) {

		if (interval && interval.start && interval.end) {
			this.setParamValues('start', [new FilterParamValue(interval.start.toUTC().toISO(), interval.start.toUTC().toISO())]);
			this.setParamValues('end', [new FilterParamValue(interval.end.toUTC().toISO(), interval.end.toUTC().toISO())]);
		} else {
			this.clearParam('start');
			this.clearParam('end');
		}
	}

	get pupilId() {
		return this.getParamValues('pupilIds')[0]?.id || null;
	}

	set pupilId(value: string) {
		this.setParamValues('pupilIds', [new FilterParamValue(value, value)]);
	}

	get activityId() {
		return this.getParamValues('activityIds')[0]?.id || null;
	}

	set activityId(value: string) {
		this.setParamValues('activityIds', [new FilterParamValue(value, value)]);
	}

	get keywords() {
		return this.getParamValues('query')[0]?.id || null;
	}

	set keywords(value: string) {
		this.setParamValues('query', [new FilterParamValue(value, value)]);
	}

}
