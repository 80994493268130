<template>

<Drawer initialWidth="3xl" @requestUnmount="hide" :title="$t('Import')">

	<template #default="{ hide }">

		<div class="alert alert-info text-sm">
			<img src="/images/instructions/copy-students-table.gif" class="w-52 shadow-md" />
			{{ $t('#instruction: import pupil data through copy/paste from a spreadsheet') }}
		</div>

		<div>
			<div class="alert alert-error text-sm" v-if="pupilsStore.errorMessage">
				{{ pupilsStore.errorMessage }}
			</div>

			<EditableTable :columns="columns" v-model="spreadsheetData" ref="importTable" />

			<div class="pl-12 pr-4 py-2 mt-10 flex gap-2 items-center" v-if="!classroom">
				<span>{{ $t('Add these pupils to the classroom') }}</span>
				<select class="select select-bordered max-w-64" v-model="selectedClassroom">
					<option :value="null">{{ $t('None') }}</option>
					<option v-for="classroom in organisationsStore.currentOrganisation?.classrooms" :value="classroom">{{ classroom.name }}</option>
				</select>
			</div>
		</div>

		<div class="drawer-action-bar justify-end gap-1">

			<button type="button" class="btn btn-ghost" @click="hide">
				{{ $t('Cancel') }}
			</button>
			<button type="button" class="btn btn-primary" @click="importPupils()">
				<!-- <span v-show="saving" class="loading loading-spinner"></span> -->
				{{ $t('Import') }}
			</button>

		</div>

	</template>
</Drawer>

</template>

<script lang="ts">
import {TrashIcon} from "@heroicons/vue/24/outline";
import EditableTable from "@/components/EditableTable.vue";
import Drawer from "@/components/ui/Drawer.v2.vue";
import {DateTime} from "luxon";
import {mapStores} from "pinia";
import {usePupilsStore} from "@/stores/Pupils.store";
import {Pupil} from "@/models/Pupil.model";
import {Classroom} from "@/models/Classroom.model";
import {PupilClassroom} from "@/models/PupilClassroom.model";
import {useClassroomsStore} from "@/stores/Classrooms.store";
import {useOrganisationsStore} from "@/stores/Organisations.store";

export default {
	components: {
		Drawer,
		EditableTable,
		TrashIcon
	},

	props: {
		classroom: {
			type: Classroom,
			required: false,
		},
	},

	data() {
		return {
			spreadsheetData: [],
			lastChangeWasPastedTableData: false,
			selectedClassroom: null,
		};
	},

	mounted() {
		this.pupilsStore.clearErrorMessage();
	},

	computed: {
		...mapStores(
			usePupilsStore,
			useOrganisationsStore
		),

		columns() {
			return [
				{
					title: this.$t('First name'),
					key: 'firstName',
					required: true,
					validator: (value) => {
						return value.length > 0;
					}
				},
				{
					title: this.$t('Name'),
					key: 'name',
					required: true,
					validator: (value) => {
						return value.length > 0;
					}
				},
				{
					title: this.$t('Date of birth'),
					key: 'birthdate',
					required: true,
					type: 'date',
					pasteTransformer: (value: string) => {

						// Try to convert common mistakes

						// Convert dashes or dots to slashes
						value = value.replace(/[-.]/g, '/');

						// Convert 2-digit years to 4-digit years, and leading zeros to non-leading zeros
						const dateParts = value.split('/');
						if (dateParts.length === 3) {
							if (dateParts[2].length === 2) {
								// Todo: fix this before 2100 ;-)
								dateParts[2] = '20' + dateParts[2];
							}
							dateParts[1] = parseInt(dateParts[1]).toString();
							dateParts[0] = parseInt(dateParts[0]).toString();
							value = dateParts.join('/');
						}

						const date = DateTime.fromFormat(value, 'd/M/yy');
						return date.isValid ? date.toISODate() : '';
					},
					validator: (value: string) => {

						const date = DateTime.fromISO(value);
						return date.isValid;

					}
				}
			]
		},

	},

	methods: {

		hide () {
			this.pupilsStore.clearErrorMessage();
			this.$emit('requestClose');
		},

		async importPupils() {

			// Classroom
			let assignToClassroom = null;
			if (this.selectedClassroom) {
				assignToClassroom = this.selectedClassroom;
			} else if (this.classroom) {
				assignToClassroom = this.classroom;
			}

			// Check validation
			const table = this.$refs.importTable;
			if (!table.validate()) {
				return;
			}

			// Convert input to Pupil objects
			const pupils = this.spreadsheetData.map((row) => {

				const pupil = new Pupil();
				pupil.firstName = row[0].value;
				pupil.lastName = row[1].value;
				pupil.birthdate = row[2].value ? DateTime.fromISO(row[2].value) : null;

				// Do we have a classroom to import into?
				if (assignToClassroom) {
					pupil.classroomRelations = [
						new PupilClassroom(assignToClassroom, undefined, 'gray')
					];
				}

				return pupil;
			});

			// Send pupils to server
			try {
				await this.pupilsStore.bulkSave(pupils);
				this.$emit('requestClose');
			} finally {

			}

		},
	}

}
</script>
