<template>
<TheBoardLayout>

	<LoadingScreen v-if="boardLoading" />

	<Drawer :open="showBoardSettings" :panel-title="$t('Settings')" :show-default-close-button="false"
		@requestClose="showBoardSettings = false"
		class="max-w-xl"
	>
		<ActivityBoardControlPanel
			@requestReloadBoard="refreshPage"
			@requestCloseBoard="handleRequestCloseBoard"
		/>
	</Drawer>

	<div class="relative bg-white min-h-screen w-full overflow-y-scroll">

		<!-- Don't show pause screen while another dialog is open. Only show once dialog screen is closed. -->
		<ActivityBoardPauseScreen v-show="activityBoardStore.activitiesPaused && !showCheckInDialog && !showCheckOutDialog" @resume="activityBoardStore.setActivitiesPause(false)" />

		<template v-if="activityBoardStore.outOfExtraTime && !showCheckInDialog && !showCheckOutDialog">
			<ActivityBoardEndedScreen @close="handleRequestCloseBoard(null)" />
		</template>
		<template v-else-if="!activityBoardStore.relatedLesson?.isToday() && !showCheckInDialog && !showCheckOutDialog">
			<ActivityBoardNotTodayScreen @close="handleRequestCloseBoard(null)" />
		</template>

		<ActivityBoardOfflineScreen v-show="!applicationOnline" />

		<ActivityBoardCheckInDialog v-if="showCheckInDialog"
			:pupil="interactingPupil"
			@close="wrapUpCheckIn"

		/>

		<ActivityBoardCheckOutDialog v-if="interactingCheckin"
			:pupilActivity="interactingCheckin"
			@requestCheckOut="handleCheckOut"
			@requestClose="showCheckOutDialog = false; interactingCheckin = null"
		/>

		<!-- <div class="absolute top-4 right-10 z-10 bg-yellow-200 py-2 px-4 bg-opacity-50 rounded-lg" v-if="activityBoardStore.showActivityEndedWarning">
            {{ this.eventCountdown }}
		</div> -->

		<div class="flex flex-row items-start w-full overflow-y-scroll mb-2">

			<!-- PUPIL LIST -->
			<div id="pupilgrid" class="p-4 bg-white flex-shrink-0 2xl:px-10">

				<ActivityBoardAvatarColorFilter v-if="activityBoardStore.avatarColorFilterEnabled" class="w-full mb-4" />

                <div class="flex flex-col gap-2 sm:grid xl:gap-3 2xl:gap-5 sm:grid-flow-row" :class="'grid-cols-' + pupilGridCols">
					<div v-for="(pupil, index) in pupilsToShow" :key="pupil.id">
						<button :id="'inactive-av-' + pupil.id" @click="handleInactivePupilSelection(pupil)" class="w-full" :class="[activityBoardStore.isCheckedIn(pupil)? 'opacity-20 cursor-not-allowed':'touch-btn']">
							<ActivityBoardAvatar
								:pupil="pupil"
								:disabled="activityBoardStore.isCheckedIn(pupil)"
								:withSymbol="activityBoardStore.avatarWithSymbol"
								:withName="activityBoardStore.avatarWithName"
								:class="avatarSizeClasses"
							/>
						</button>
					</div>
                </div>
            </div>

            <!-- ACTIVITY GRID -->
            <div id="activity-grid" class="w-full gap-y-3 flex items-start flex-wrap p-2 bg-white mt-2">

				<template v-for="boardItem in activityBoardStore.boardItems" :key="boardItem.lessonItem.id">
                    <div class="w-full" :id="'bi-' + boardItem.lessonItem.id" :class="boardActivityPanelSize(boardItem)">
						<div class="h-full mx-1 flex flex-col gap-2 p-2 items-center bg-gray-200 bg-opacity-60 border-2 border-gray-400 border-opacity-60 rounded-lg">

                        <ActivitySymbol :activity="boardItem.lessonItem" class="-mt-4 border-4 border-gray-200 rounded-lg bg-gray-50 cursor-not-allowed w-10 sm:w-12 lg:w-14 2xl:w-24 min-[1921px]:w-32" />

                        <template v-if="activityBoardStore.avatarWithName">
                            <div class="grid grid-flow-row gap-1.5 justify-items-center" :class="[showCheckInDialog? 'opacity-50':'', 'grid-cols-' + Math.ceil(boardItem.lessonItem.seats/3)]">
                                <!-- occupied activity seats: {{ boardItem.checkins.length }} -->
                                <template v-for="checkin in boardItem.checkins" :key="checkin.id">
																	<!-- checkin -->
									<button type="button" :id="'active-av-' + checkin.pupil.id" @click="handleActivePupilSelection(checkin)" class="touch-btn w-full">
										<ActivityBoardAvatar
											:pupil="checkin.pupil"
											:withSymbol="activityBoardStore.avatarWithSymbol"
											:withName="true"
											:class="avatarSizeClasses"
										/>
									</button>
                                </template>
                                <!-- placeholders empty seats -->
                                <template v-for="i in availableSeats(boardItem)" :key="i">
                                    <div
                                        class="rounded-full border-dotted border-2 bg-gray-600 bg-opacity-10 disabled:cursor-not-allowed outline-none"
										:class="avatarSizeClasses"
                                        :id="'bi-' + boardItem.lessonItem.id + '-' + i"
                                        :disabled="!showCheckInDialog"
                                    >
                                </div>
                                </template>
                            </div>
                        </template>
                        <template v-if="activityBoardStore.avatarWithSymbol && !activityBoardStore.avatarWithName">
                            <div class="grid grid-flow-row gap-1.5 2xl:gap-4 items-center justify-items-center" :class="[showCheckInDialog? 'opacity-50':'', 'grid-cols-' + Math.ceil(boardItem.lessonItem.seats/2)]">
                                <!-- occupied activity seats: {{boardItem.checkins.length}} -->
                                <template v-for="checkin in boardItem.checkins" :key="checkin.id">
									<button :id="'active-av-' + checkin.pupil.id" @click="handleActivePupilSelection(checkin)" class="touch-btn">
										<ActivityBoardAvatar
											:pupil="checkin.pupil"
											:withSymbol="true"
											:withName="false"
											:class="avatarSizeClasses"
										/>
									</button>
                                </template>
                                <!-- placeholders empty seats -->
                                <template v-for="i in availableSeats(boardItem)" :key="i">
                                    <div
                                        class="rounded-full border-dotted border-2 bg-gray-600 bg-opacity-10 aspect-square outline-none"
										:class="avatarSizeClasses"
                                        :id="'bi-' + boardItem.lessonItem.id + '-' + i"
                                        :disabled="!showCheckInDialog"
                                    >
                                    </div>
                                </template>
                            </div>
                        </template>

						</div>

                    </div>
                </template>

			</div>

		</div>

        <!-- Close button specific for the activity board, replacing default drawer close button -->
		<div
			class="absolute top-4 right-0 z-40 w-8 transition-transform ease-in-out duration-300 hover:-translate-x-6"
			:class="[showBoardSettings? '-translate-x-6': '']"
		>
			<button
				class="transition-transform duration-500 p-3 border text-gray-600 active:bg-neutral active:text-base"
				:class="[showBoardSettings? 'w-auto rounded-full shadow-none bg-base-100 hover:bg-base-200 border-base-100 scale-125': 'w-16 rounded-l-full rounded-r-none shadow-md bg-white hover:bg-base-100 border-base-300']"
                @click="showBoardSettings = !showBoardSettings"
			>
				<ChevronLeftIcon class="w-4 h-4 transition-transform duration-500" :class="[showBoardSettings? 'rotate-180' : 'rotate-0']"  />
			</button>
		</div>

	</div>

</TheBoardLayout>
</template>


<style>
.anim-roll {
	animation: anim-roll 800ms ease-out 400ms none;
}
@keyframes anim-roll {
	0% {
		transform: rotate(0deg) scale(1);
	}
	50% {
		transform: rotate(180deg) scale(2);
	}
	100% {
		transform: rotate(360deg) scale(1);
	}
}

.anim-roll-out {
	animation: anim-roll-out 1000ms ease-out 0ms none;
}
@keyframes anim-roll-out {
	0% {
		transform: rotate(0deg) scale(4);
		opacity: .4;
	}
	100% {
		transform: rotate(360deg) scale(1);
		opacity: 1;
	}
}

.touch-btn:active:hover,
.touch-btn:active:focus {
    transform: scale(var(--btn-focus-scale, 0.95));
}
.back-btn {
	@apply aspect-square m-2 lg:m-4 h-14 lg:h-20 xl:h-24 flex items-center justify-center bg-red-500 rounded-full text-white;
}
</style>


<script lang="ts">
import { mapStores, storeToRefs } from "pinia";
import { usePupilsStore } from '@/stores/Pupils.store';
import { useActivityBoardStore } from '@/stores/ActivityBoard.store';
import { Pupil } from "@/models/Pupil.model";

import TheBoardLayout from './layout/TheBoardLayout.vue';
import LoadingScreen from "./ui/LoadingScreen.vue";
import ActivitySymbol from "./ui/symbols/ActivitySymbol.vue";
import ActivityBoardAvatar from "./ActivityBoardAvatar.vue";
import ActivityBoardCheckOutDialog from "./ActivityBoardCheckOutDialog.vue";
import ActivityBoardCheckInDialog from "./ActivityBoardCheckInDialog.vue";
import ActivityBoardPauseScreen from "./ActivityBoardPauseScreen.vue";
import ActivityBoardOfflineScreen from "./ActivityBoardOfflineScreen.vue";
import Drawer from "./ui/Drawer.vue";
import ActivityBoardControlPanel from "./ActivityBoardControlPanel.vue";
import { nextTick, watch } from "vue";
import { ChevronLeftIcon } from "@heroicons/vue/24/outline";
import ActivityBoardAvatarColorFilter from "./ActivityBoardAvatarColorFilter.vue";
import { PupilActivity } from "@/models/PupilActivity.model";
import ActivityBoardEndedScreen from "@/components/ActivityBoardEndedScreen.vue";
import ActivityBoardNotTodayScreen from "@/components/ActivityBoardNotTodayScreen.vue";

const CHECKOUT_NO_FEEDBACK_TIMEOUT = 30 * 1000;

export default {

	components: {
		ActivityBoardNotTodayScreen,
		TheBoardLayout,
		LoadingScreen,
		ActivitySymbol,
		ActivityBoardAvatar,
		ActivityBoardCheckOutDialog,
		ActivityBoardCheckInDialog,
		ActivityBoardPauseScreen,
		ActivityBoardOfflineScreen,
		Drawer,
		ActivityBoardControlPanel,
		ChevronLeftIcon,
		ActivityBoardAvatarColorFilter,
		ActivityBoardEndedScreen
	},

	data() {
		return {
			boardLoading: false,
			applicationOnline: true,
			interactingPupil: null,
			interactingCheckin: null,
			showCheckInDialog: false,
			showCheckOutDialog: false,
			showBoardSettings: false,

			colorSorting: (a: Pupil, b: Pupil) => {
					if(!a['color'] && !b['color']) {
						return 0;
					}
					if(!a['color']) {
						return 1;
					}
					if(!b['color']) {
						return -1;
					}
					return a['color'].localeCompare(b['color']);
			},
		}
	},

	computed: {
		...mapStores(usePupilsStore, useActivityBoardStore),

		pupilGridCols() {
			return this.activityBoardStore.avatarWithName ?
				Math.ceil(this.pupilsStore.pupils.length/(18)) :
				Math.ceil(this.pupilsStore.pupils.length/(10));
		},

		activityGridCols() {
			let cols = this.activityBoardStore.avatarWithName ? 6 : 5;
			if(this.activityBoardStore.boardItems.length < cols) {
				cols = this.activityBoardStore.boardItems.length;
			}
			return cols;
		},

		pupilsToShow() {
			let pupils = this.pupilsStore.pupils.sort(this.colorSorting);
			pupils = pupils.filter((pupil) => {
				if(!pupil.color && this.activityBoardStore.avatarSelectedColors.includes('gray')) {
					return true;
				} else if(this.activityBoardStore.avatarSelectedColors.includes(pupil.color)) {
					return true;
				}
				return false;
			});
			return pupils;
		},

		// the lesson item the interacting pupil is checked in to
		interactingPupilLessonItem() {
			return this.activityBoardStore.getLessonItemForCheckedInPupil(this.interactingPupil);
		},

		availableSeats() {
			return (boardItem) => {
				return Math.max(0, boardItem.lessonItem.seats - boardItem.checkins.length);
			}
		},

		// showCountdown() {
		// 	return this.activityBoardStore.eventTimeLeft < 300;
		// },

		// eventCountdown() {
		// 	return Duration.fromMillis(this.activityBoardStore.eventTimeLeft * 1000).toFormat('mm:ss');
		// }

		avatarSizeClasses() {
			if (this.activityBoardStore.avatarWithName) {
				return 'h-10 xl:h-12 w-32 xl:w-40';
			} else {
				return 'h-10 lg:h-12 xl:h-14 2xl:h-20 min-[1921px]:h-28';
			}
		},
	},

	async mounted() {

		if(
			!this.activityBoardStore.relatedLesson ||
			this.activityBoardStore.relatedLesson.boardType !== 'activities'
		) {
			this.$router.push({ name: 'activity-board-select' });
			return;
		}

		// disable right click
		// if(process.env.NODE_ENV === 'production') {
			document.addEventListener('contextmenu', event => event.preventDefault());
		// }

		this.boardLoading = true;

		this.applicationOnline = navigator.onLine;
		window.addEventListener("offline", (e) => {
			this.applicationOnline = false;
		});
		window.addEventListener("online", (e) => {
			// (unreliable method though, more logic required to make sure we're really online)
			if(!this.applicationOnline) {
				this.loadBoard();
			}
			this.applicationOnline = true;
		});

		// removed as it is already loaded in ActivityBoardStartScreen
		//await this.loadBoard();

		this.boardLoading = false;

		// Redirect back to board select when the lesson is deleted
		const { relatedLesson } = storeToRefs(this.activityBoardStore);
		watch(relatedLesson, (newValue) => {
			if(!newValue) {
				this.$router.push({name: 'activity-board-select'});
			}
		});
	},

	methods: {
		async loadBoard() {
			await this.activityBoardStore.load();
			return;
		},

		refreshPage() {
			window.location.reload();
		},

		boardActivityPanelSize(boardItem) {
			if(this.activityBoardStore.avatarWithName) {
				if(boardItem.lessonItem.seats > 9) {
					return 'sm:w-full min-[1921px]:w-1/2';
				} else if(boardItem.lessonItem.seats > 6) {
					return 'sm:w-full lg:w-2/3 xl:w-1/2 2xl:w-1/2 min-[1921px]:w-1/3';
				} else if(boardItem.lessonItem.seats > 3) {
					return 'sm:w-1/2 md:w-2/3 lg:w-1/2 xl:w-1/3 min-[1921px]:w-1/4';
				} else {
					return 'sm:w-1/2 lg:w-1/3 xl:w-1/6 min-[1921px]:w-[calc(100%/8)]';
				}
			}
			else {
				if(boardItem.lessonItem.seats > 12) {
					// return 'sm:w-1/2 xl:w-1/3 2xl:w-full min-[1921px]:w-full';
					return '';	// w-full is default
				} else if(boardItem.lessonItem.seats > 8) {
					return 'sm:w-1/2 lg;w-1/4 xl:w-1/3 2xl:w-1/2 min-[1921px]:w-1/2';
				} else if(boardItem.lessonItem.seats > 6) {
					return 'sm:w-1/2 lg:w-1/3 xl:w-1/3 2xl:w-1/3 min-[1921px]:w-1/3';
				} else if(boardItem.lessonItem.seats > 4) {
					return 'sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/4 min-[1921px]:w-1/4';
				} else if(boardItem.lessonItem.seats > 2) {
					return 'sm:w-1/4 lg:w-1/6 xl:w-1/6 min-[1921px]:w-1/6';
				} else {
					return 'sm:w-1/4 md:w-1/6 xl:w-[calc(100%/8)]';
				}
			}
		},

		handleInactivePupilSelection(pupil: Pupil) {
			if(this.activityBoardStore.isCheckedIn(pupil)) {
				// make the inactive button shak
				const btnEl = document.getElementById(`inactive-av-${pupil.id}`);
				btnEl.classList.toggle('shake');
				setTimeout(() => {
					btnEl.classList.toggle('shake');
				}, 1500);
				// highlight the pupil's avatar
				const btnEl2 = document.getElementById(`active-av-${pupil.id}`);

				btnEl2.classList.toggle('anim-roll');
				setTimeout(() => {
					btnEl2.classList.toggle('anim-roll');
				}, 1500);

				return;
			}

			this.interactingPupil = pupil;
			this.showCheckInDialog = true;
		},

		async handleActivePupilSelection(pupilActivity: PupilActivity) {

			// If this checkout is less than 30 seconds from the checkin, do not request feedback
			const duration = pupilActivity.getDuration();
			if (duration && duration.toMillis() < CHECKOUT_NO_FEEDBACK_TIMEOUT) {
				await this.activityBoardStore.checkOut(pupilActivity, null);
				this.animateCheckOutAction(pupilActivity.pupil);
				return;
			}

			this.interactingCheckin = pupilActivity;
			this.showCheckOutDialog = true;

		},

		animateCheckInAction(checkin : PupilActivity | null) {
			if (!checkin) {
				return;
			}

			const symbolEl = document.getElementById('active-av-' + checkin.pupil.id);
			symbolEl.classList.add('anim-roll-out');
		},

		wrapUpCheckIn(checkin: PupilActivity) {
			this.showCheckInDialog = false;
			this.interactingPupil = null;
			this.animateCheckInAction(checkin);
		},

		async handleCheckOut(checkin: PupilActivity, feedbackScore: number) {
			this.interactingCheckin = null;

			try {
				await this.activityBoardStore.checkOut(checkin, feedbackScore)
			} catch (e) {
				this.$notify({
					title: "Error!",
					text: e.toString(),
					type: "error",
				});

				throw e;
			}

			nextTick(() => {
				this.animateCheckOutAction(checkin.pupil);
			});
		},

		animateCheckOutAction(pupil : Pupil) {
			const symbolEl = document.getElementById('inactive-av-' + pupil.id);
			symbolEl.classList.add('anim-roll');
		},

		handleRequestCloseBoard(time : Date = null) {
			if(time) {
				this.activityBoardStore.clear(time);
			} else {
				this.activityBoardStore.closeBoard();
			}
		},
	}
}
</script>
