import { ErrorMessage } from "@/stores/errors/ErrorMessage";
import { orgApi } from "@/utils/Api.util";
import { PaginationState } from "@/models/PaginationState.model";
import { FilterDef } from "./filters/FilterDef";
import { OrderParameter } from "@/models/OrderParameter.model";
import { _ActionsTree, _GettersTree, DefineStoreOptions } from "pinia";
import { ApiErrors } from "./errors/ApiErrors";

export interface AbstractStoreState<T> {

	page: PaginationState | null,
	order: OrderParameter,
	items: T[],
	errorMessage: ErrorMessage | null,

	loadingMore?: boolean,

	_defaultFilter: FilterDef | null,
	_filter: FilterDef | null,
}

export interface Model {
	id?: string;
	hydrateWithApiData(data: any): void;
	asApiDataObject(): any;
	clone(): Model;
}

export const Method = {
	INDEX: 	'index',
	VIEW: 	'view',
	CREATE:	'create',
	UPDATE:	'update',
	DELETE:	'delete',
}

/**
 * Interface that overrides the default store options to be able to define the type of the state (including the genric type T).
 */
export interface AbstractBaseStoreOptionsInterface<T extends Model, S extends AbstractStoreState<T>>
	extends Omit<DefineStoreOptions<string, AbstractStoreState<T>, _GettersTree<S>, any>, 'id'> {
		// the 'any' in the action tree is not correct and should instead be some kind of ActionTree type that describes
		// the content of the action property, but I haven't managed to get that working in the generic typings yet.
	
		// The Omit<{},'id'> is to tell typescript that the id property is not required in this particular store.

	state: <U extends AbstractStoreState<T>>(baseState?: any) => U;

}

/** 
 *
 */
export function AbstractBaseStore<T extends Model, S extends AbstractStoreState<T>>(): AbstractBaseStoreOptionsInterface<T, S> {
	return {

		/**
		 * Define a state.
		 * @param baseState 
		 * @returns 
		 */
		state: <U extends AbstractStoreState<T>>(baseState: any = {}): U => {

			const defaultFilter = baseState.defaultFilter ? baseState.defaultFilter.clone() : new FilterDef([]);
			const filter = baseState.initialFilter ? baseState.initialFilter.clone() : defaultFilter.clone();

			return {
				page: 			null,
				items: 			[],
				order: 			new OrderParameter('id'),	// Generic placeholder order
				errorMessage: 	null,

				_defaultFilter:	defaultFilter,
				_filter: 		filter,
			} as unknown as U;

		},

		getters: {

			/**
			 * Get the API path for the store.
			 * @param state 
			 */
			apiPath(state: AbstractStoreState<T>): string {
				throw new Error('apiPath not implemented');
			},

			/**
			 * Generate the API URL for the given method and id.
			 * @param state 
			 * @returns 
			 */
			apiUrl(state: AbstractStoreState<T>) {
				return (method: string, id: string | undefined) => {
					switch (method) {
						case Method.INDEX:
						case Method.CREATE:
							return this.apiPath;

						case Method.UPDATE:
						case Method.VIEW:
							if (!id) {
								throw new Error('Method requires an id');
							}

							return this.apiPath + '/' + id;

						default:
							throw new Error('Method not implemented');
					}
				};
			},

			/**
			 * Get global query parameters that must be appended to every request.
			 * @param state 
			 * @returns 
			 */
			globalQueryParameters(state: AbstractStoreState<T>): { [key: string]: String | number } {
				return {};
			},

			/**
			 * Get the default mask that should be included in every request.
			 * @param state 
			 * @returns 
			 */
			defaultMask(state: AbstractStoreState<T>): string[] {
				return [ '*' ];
			},

			/**
			 * @returns number
			 */
			defaultRecordsPerPage(): number {
				return 10;
			},

		} as _GettersTree<AbstractStoreState<T>>,

		actions: {

			/**
			 * @param awaitNewDataBeforeResettingList If set to TRUE, the list will not first reset before the new data is loaded.
			 */
			async load(
				awaitNewDataBeforeResettingList: boolean = false
			) {

				if (!awaitNewDataBeforeResettingList) {
					this.items = [];
				}

				this.items = await this._executeIndexRequest();

			},

			/**
			 * On a given page, load additional items.
			 * @returns 
			 */
			async loadMore() {

				if (this.loadingMore) {
					return;
				}

				if (!this.page) {
					return;
				}

				if (!this.page.hasNext()) {
					return;
				}

				this.loadingMore = true;
				
				let page: PaginationState = this.page;
				this.page = page.getNext();

				// Actually load the additional items
				this.items.push(... await this._executeIndexRequest());

				// Adapt page to show 'from' as 1 (as the other items are also still loaded)
				// (we are still on the first page, but the page got longer)
				this.page.from = page.from;

				this.loadingMore = false;

			},

			/**
			 * 
			 * @returns 
			 */
			async _executeIndexRequest() {

				// Must assign to a new object to avoid modifying the global query parameters
				// (which is a getter, but for some reason is cached, causing it to be modified)
				const params = Object.assign({}, this.globalQueryParameters);

				params.mask = this.defaultMask.join(',');
				params.page = this.page ? this.page.currentPage : null;

				if (typeof(params.records) === 'undefined') {
					params.recordsPerPage = this.defaultRecordsPerPage;
				}

				if (this._filter) {
					Object.assign(params, this._filter.toApiParams());
				}

				if (this.order) {
					params.order = this.order.getApiProperty();
				}

				let response;
				try {
					response = await orgApi.get(this.apiUrl(Method.INDEX), {
						params: params
					});
				} catch (e: any) {
					this.errorMessage = ApiErrors.fromAxiosException(e);
					throw this.errorMessage;
				}

				this.page = PaginationState.mapFromServer(response.data.meta);

				return response.data.data.map((itemData: any) => {
					return this.mapToItem(itemData);
				});

			},

			/**
			 * Map item data from the API to a model.
			 * @param data 
			 */
			mapToItem(data: any): T {
				throw new Error('mapToItem not implemented');
			},

			/**
			 * Load the first page of paginated data.
			 */
			loadFirstPage() {
				this.page = null;
				this.load();
			},

			/**
			 * Load a specific page of paginated data.
			 */
			goToPage(page: PaginationState) {
				this.page = page;
				this.load();
			},

			/**
			 * Find a loaded item in the store by its id.
			 * WARNING: Does not load the item from the backend.
			 * @param id 
			 * @returns 
			 */
			find(id: string): T | null {
				return this.items.find((item: T) => item.id === id) || null;
			},

			/**
			 * Save a model to the store and the backend.
			 * @param model 
			 */
			async save(model: T) {

				return this.superSave(model);
				
			},

			/**
			 * Actually save a model to the store and the backend.
			 * @param model
			 */
			async superSave(model: T) {

				this.errorMessage = null;

				if(model.id) {
					// update existing item
					try {
						const response = await orgApi.put(this.apiUrl(Method.UPDATE, model.id), model.asApiDataObject(), {
							params: {
								mask : this.defaultMask.join(','),
							}
						});
						model.hydrateWithApiData(response.data.data);
						this.updateStoreItem(model);

					} catch (e: any) {
						this.errorMessage = ApiErrors.fromAxiosException(e);
						throw this.errorMessage;
					}

				} else {
					// create new item
					try {
						const response = await orgApi.post(this.apiUrl(Method.INDEX), model.asApiDataObject(), {
							params: {
								mask : this.defaultMask.join(',')
							}
						});
						model.hydrateWithApiData(response.data.data);
						this.addStoreItem(model);

					} catch (e: any) {
						this.errorMessage = ApiErrors.fromAxiosException(e);
						throw this.errorMessage;
					}
				}

			},

			/**
			 * Delete an item from the store and the backend.
			 * @param model 
			 */
			async delete(model: T) {

				try {
					const response = await orgApi.delete(this.apiUrl(Method.DELETE, model.id));
					this.deleteStoreItem(model);

				} catch (e: any) {
					this.errorMessage = ApiErrors.fromAxiosException(e);
					throw this.errorMessage;
				}

			},

			/**
			 * Load data from the API and refresh the model.
			 * @param model 
			 */
			async refresh(model: T) {
				const response = await orgApi.get(this.apiUrl(Method.VIEW, model.id), {
					params: {
						mask: this.defaultMask.join(',')
					}
				});
	
				model.hydrateWithApiData(response.data.data);
			},

			/**
			 * Update the value of a loaded item in the store.
			 * @param model 
			 */
			updateStoreItem(model: T) {
				var storeIndex = this.items.findIndex(
					(item: T) => item.id == model.id
				);
				this.items[storeIndex] = model.clone();
			},

			/**
			 * Add a loaded item into the store
			 * @param model 
			 */
			addStoreItem(model: T) {
				this.items.unshift(model);
			},

			/**
			 * Remove a loaded item from the store
			 * (does not affect entries in the backend)
			 * @param model 
			 */
			deleteStoreItem(model: T) {
				const index = this.items.findIndex((item: T) => item.id === model.id);
				if (index >= 0) {
					this.items.splice(index, 1);
				}
			},

			/**
			 * Warning: do not make this a getter, as getter values are cached.
			 * @returns 
			 */
			getCurrentFilter() {
				return this._filter.clone();
			},

			/**
			 * Warning: do not make this a getter, as getter values are cached.
			 * @param filter 
			 * @returns 
			 */
			isDefaultFilter(filter: FilterDef | null) {
				if (!filter) {
					return true;
				}
				return filter.equals(this._defaultFilter);
			},

			/**
			 * Apply filter and reload data.
			 * @param filter FilterDef
			 * @returns 
			 */
			applyFilter(filter: FilterDef) {
				return this.superApplyFilter(filter);
			},

			/**
			 * Helper method to apply filter and reload data.
			 * Do not call directly. Use resetFilter instead.
			 * This is a workaround in order to be able to 'override' applyFilters in specific stores.
			 * @param filter 
			 */
			superApplyFilter(filter: FilterDef) {
				this._filter = filter;
				this.load();

				// Return the cloned filter for convenience
				return this.getCurrentFilter();
			},

			/**
			 * Reset the filters to their default values and reload data.
			 * @returns void
			 */
			resetFilter() {
				return this.superResetFilter();
			},

			/**
			 * Reset filter to default and reload data.
			 * Do not call directly. Use resetFilter instead.
			 * This is a workaround in order to be able to 'override' resetFilter in specific stores.
			 */
			superResetFilter() {
				const filter = this._defaultFilter.clone();
				return this.applyFilter(filter);
			},

			/**
			 * Apply order and reload data.
			 * @param order 
			 */
			applyOrder(order: OrderParameter) {
				this.superApplyOrder(order);
			},

			/**
			 * Actually apply order and reload data.
			 * Do not call directly. Use applyOrder instead.
			 * This is a workaround in order to be able to 'override' applyOrder in specific stores.
			 * @param order 
			 */
			superApplyOrder(order: OrderParameter) {
				this.order = order;
				this.load();
			},

			/**
			 * Clear the error message.
			 * (call before a save / update / delete action)
			 */
			clearErrorMessage() {
				this.errorMessage = null;
			},

		}

	}

}
