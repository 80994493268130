<template>

	<transition appear name="fade" @after-enter="showContent = true" @after-leave="afterDialogCloseAnimation">
	<div v-show="showBackdrop" id="backdrop" class="fixed inset-0 bg-black z-50 bg-opacity-80">

		<transition appear name="zoom" @after-leave="showBackdrop = false">
		<div v-show="showContent" class="fixed bottom-0 left-0 right-0 inset-0 grid place-items-center z-40">


			<div class="absolute top-0 left-0 w-full h-full">
				<div class="pt-4 flex h-full">

					<!-- "back" button -->
					<div class="pl-4 absolute">
						<button type="button" @click="handleGoBack" class="touch-btn back-btn">
							<ChevronLeftIcon class="w-4/5 h-4/5" />
						</button>
					</div>

					<div class="h-full flex-grow flex flex-col items-center justify-center text-gray-100 pt-8">

						<div class="relative flex flex-col items-center">
							<ActivityBoardAvatar
								:pupil="pupil"
								:withSymbol="activityBoardStore.avatarWithSymbol"
								:withName="activityBoardStore.avatarWithName"
								class="h-12 md:h-16 lg:h-20 xl:h-24 mb-4 shadow-lg"
							/>
							<ActivitySymbol
								v-if="checkedInLessonItem"
								:activity="checkedInLessonItem"
								class="mb-4 lg:mb-8 bg-gray-800 border-8 border-gray-900 rounded-lg h-24 md:h-28 lg:h-36 xl:h-44"
							/>
						</div>

						<div class="flex justify-center gap-6">
							<!-- feedback - GOOD -->
							<button type="button" @click="registerFeedback(1)" id="btn-pop-in-1" class="touch-btn fb-btn bg-yellow-600" :class="[showContent? 'btn-pop-in':'']">
								<svg class="w-4/5 h-4/5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
									<path d="M22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12ZM7.41 14.66C7.08 14.92 7.03 15.39 7.28 15.71C8.41 17.15 10.14 18 12 18C13.86 18 15.58 17.15 16.71 15.72C16.97 15.39 16.91 14.92 16.59 14.66C16.26 14.41 15.79 14.46 15.53 14.79C14.69 15.86 13.4 16.5 12 16.5C10.6 16.5 9.31 15.86 8.46 14.78C8.21 14.46 7.73 14.4 7.41 14.66ZM7.75 10C7.75 10.69 8.31 11.25 9 11.25C9.69 11.25 10.25 10.69 10.25 10C10.25 9.31 9.69 8.75 9 8.75C8.31 8.75 7.75 9.31 7.75 10ZM13.75 10C13.75 10.69 14.31 11.25 15 11.25C15.69 11.25 16.25 10.69 16.25 10C16.25 9.31 15.69 8.75 15 8.75C14.31 8.75 13.75 9.31 13.75 10Z" />
								</svg>
							</button>

							<!-- feedback - NEUTRAL -->
							<button type="button" @click="registerFeedback(0)" id="btn-pop-in-2" class="touch-btn fb-btn bg-yellow-700" :class="showContent? 'btn-pop-in':''">
								<svg class="w-4/5 h-4/5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
									<path d="M2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2C6.48 2 2 6.48 2 12ZM9 11.25C8.31 11.25 7.75 10.69 7.75 10C7.75 9.31 8.31 8.75 9 8.75C9.69 8.75 10.25 9.31 10.25 10C10.25 10.69 9.69 11.25 9 11.25ZM15 11.25C14.31 11.25 13.75 10.69 13.75 10C13.75 9.31 14.31 8.75 15 8.75C15.69 8.75 16.25 9.31 16.25 10C16.25 10.69 15.69 11.25 15 11.25ZM8.25 15C9 15 15 15 15.75 15C16.16 15 16.5 15.34 16.5 15.75C16.5 16.16 16.16 16.5 15.75 16.5C15 16.5 9 16.5 8.25 16.5C7.84 16.5 7.5 16.16 7.5 15.75C7.5 15.34 7.84 15 8.25 15Z" />
								</svg>
							</button>

							<!-- feedback - BAD -->
							<button type="button" @click="registerFeedback(-1)" id="btn-pop-in-3" class="touch-btn fb-btn bg-yellow-800" :class="showContent? 'btn-pop-in':''">
								<svg class="w-4/5 h-4/5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
									<path d="M22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12ZM7.71 15.3C7.42 15.6 7.43 16.07 7.72 16.36C8.02 16.65 8.49 16.65 8.78 16.35C9.63 15.49 10.77 15 12 15C13.22 15 14.37 15.49 15.21 16.34C15.5 16.64 15.98 16.64 16.27 16.35C16.57 16.06 16.57 15.59 16.28 15.29C15.16 14.15 13.63 13.5 12 13.5C10.37 13.5 8.83 14.16 7.71 15.3ZM7.75 10C7.75 10.69 8.31 11.25 9 11.25C9.69 11.25 10.25 10.69 10.25 10C10.25 9.31 9.69 8.75 9 8.75C8.31 8.75 7.75 9.31 7.75 10ZM13.75 10C13.75 10.69 14.31 11.25 15 11.25C15.69 11.25 16.25 10.69 16.25 10C16.25 9.31 15.69 8.75 15 8.75C14.31 8.75 13.75 9.31 13.75 10Z" id="e2TMzgzsCi" />
								</svg>
							</button>
						</div>
					</div>
				</div>
			</div>


		</div>
		</transition>

	</div>
	</transition>

</template>


<style scoped>
.btn-pop-in {
    animation: 250ms ease-out pop-in;
}
#btn-pop-in-1 {
    animation-delay: 100ms;
}
#btn-pop-in-2 {
    animation-delay: 150ms;
}
#btn-pop-in-3 {
    animation-delay: 200ms;
}
@keyframes pop-in {
    0% { transform: scale(1); }
    50% { transform: scale(1.2) }
    100% { transform: scale(1); }
}
.fb-btn {
	@apply w-24 h-24 md:w-32 md:h-32 lg:w-40 lg:h-40 xl:w-48 xl:h-48 flex justify-center items-center text-white border-white border-4 rounded-full
}
</style>


<script lang="ts">
import { useActivityBoardStore } from "@/stores/ActivityBoard.store";
import { mapStores } from "pinia";

import { Pupil } from '@/models/Pupil.model';
import ActivityBoardAvatar from './ActivityBoardAvatar.vue';
import ActivitySymbol from './ui/symbols/ActivitySymbol.vue';
import { ChevronLeftIcon } from '@heroicons/vue/24/outline';
import { PupilActivity } from "@/models/PupilActivity.model";

export default {
	emits: ['requestCheckOut', 'requestClose', 'conflict'],

	props: {
		pupilActivity: {
			type: PupilActivity,
			required: true
		},
	},

	components: {
		ActivityBoardAvatar,
		ActivitySymbol,
		ChevronLeftIcon,
	},

	data() {
		return {
			showContent: false,
			showBackdrop: true,
			score: null,
		}
	},

	computed: {
		...mapStores(useActivityBoardStore),

		checkedInLessonItem() {
			return this.activityBoardStore.getLessonItemForCheckedInPupil(this.pupil);
		},

		pupil(): Pupil {
			return this.pupilActivity.pupil;
		},
	},

	methods: {
		registerFeedback(value) {
			this.score = value;

			setTimeout(() => {	// short delay before closing the dialog
				this.showContent = false;
            }, 200);
		},

		afterDialogCloseAnimation() {
			// wait to emit 'requestCheckOut' event as the event will remove the pupil from the parent checkedInPupils list, which will trigger a
			// re-render of this dialog (which will fail because the lessonItem object used in this dialog is no longer available
			// in the checkedInPupils list)
			if(this.score !== null) {
				this.$emit('requestCheckOut', this.pupilActivity, this.score);
			}
			this.$emit('requestClose');
			this.score = null;
		},

		handleGoBack() {
			this.showContent = false;
		}
	}
}
</script>
