import { FileIcon, SpriteIcon } from "./Icon.model";
import { AbstractHasIcon } from "@/models/AbstractHasIcon.model";

export class Activity extends AbstractHasIcon
{
	public id ?: string;
	public name ?: string;
	// public color ?: string;

	public seats ?: number;
	public classroomId: string = '';

	static mapFromServer(data: any): Activity {
		return (new Activity()).setFromServerData(data);
	}

	setFromServerData(data: any) {

		this.id = data.id;
		this.name = data.name;

		if (data.icon) {
			this.icon = FileIcon.mapFromServer(data.icon);
		}

		if (data.symbol) {
			this.icon = SpriteIcon.mapFromServer(data.symbol);
		}

		this.seats = data.seats;

		return this;
	}

	getServerData(): any {
		return {
			id: this.id,
			name: this.name,
			seats: this.seats,
			icon: (this.icon && this.icon instanceof FileIcon)? this.icon.asApiObject() : null,
			symbol: (this.icon && this.icon instanceof SpriteIcon)? this.icon.asApiObject() : null,
			classroom: {
				id: this.classroomId
			}
		}
	}

	clone(): Activity {
		let clonedInstance = Object.assign(
			Object.create(
				Object.getPrototypeOf(this)
			),
			this
		);
		return clonedInstance;
	}
}
