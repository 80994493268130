<template>
	<TheStdLayout :title="$t('Insights')">

		<div class="px-4 pb-6 mx-auto sm:px-6 md:px-8 relative flex flex-col h-full overflow-auto">

			<template v-if="loaded">

				<div class="pt-6 bg-base h-48 sm:h-36">
					<InsightsFilterComponent
						v-model="filter"
						:showPupilSelector="true"
						:showDateRangeSelector="true"
						:dateRangeSelectorReadOnly="currentReport === 'social'"
						@change="applyFilter"
					/>

					<div class="border-b mb-8">
						<Tabs
							:value="currentReport"
							:options="[
								{ value: 'activity', label: this.$tc('Activity',2) },
								{ value: 'feedback', label: this.$t('Feedback') },
								{ value: 'social', label: this.$t('Social interaction') },
								{ value: 'goals', label: this.$tc('Goal', 2) },
							]"
							@change="changeReport"
							class="text-md">
						</Tabs>
					</div>
				</div>

				<div class="overflow-auto">
					<template v-if="loaded">

						<template v-if="currentReport === 'activity'">
							<InsightsActivities :filter="filter"></InsightsActivities>
						</template>

						<template v-if="currentReport === 'feedback'">
							<InsightsFeedback :filter="filter" :withNameColumn="true"></InsightsFeedback>
						</template>

						<template v-if="currentReport === 'social'">
							<InsightsSocial :filter="filter"></InsightsSocial>
						</template>

						<template v-if="currentReport === 'goals'">
							<InsightsGoals :filter="filter"></InsightsGoals>
						</template>

					</template>
				</div>

			</template>

		</div>

	</TheStdLayout>
</template>

<script lang="ts">
import {mapState, mapStores} from "pinia";
import {useInsightsStore} from "@/stores/Insights.store";
import { DateTime, Interval } from "luxon";
import InsightsActivities from "@/components/InsightsActivities.vue";
import InsightsFilterComponent from "@/components/InsightsFilter.vue";
import InsightsFeedback from "@/components/InsightsFeedback.vue";
import InsightsSocial from "@/components/InsightsSocial.vue";
import InsightsGoals from "@/components/InsightsGoals.vue";
import Tabs from "@/components/ui/Tabs.vue";
import {InsightsFilterDef} from "@/stores/filters/InsightsFilterDef";


export default {
	components: {
		InsightsFeedback,
		InsightsFilterComponent,
		InsightsActivities,
		InsightsSocial,
		InsightsGoals,
		Tabs,
	},

	data() {
		return {
			filter: null,
			loaded: false,
			currentReport: 'activity',
		}
	},

	computed: {
		...mapState(useInsightsStore, [ 'activities', 'maxTotalTimeSpentInActivity' ]),
	},

	mounted() {
		this.initializeFilter();

		this.currentReport = this.$route.meta.report || sessionStorage.getItem("insights.report") || 'feedback';
		if(this.currentReport === 'social') {
			this.adjustDateRangeForSocialReport();
		}
		this.loaded = true;
	},

	methods: {
		initializeFilter() {
			this.filter = new InsightsFilterDef();
			this.filter.setParamValue(InsightsFilterDef.FILTER_DATERANGE, this.retrieveDateRange().toISO());
		},

		applyFilter(filter: InsightsFilterDef) {
			if(this.currentReport !== 'social') {
				this.storeDateRange();
			}
		},

		changeReport(report: string) {

			// Has report changed?
			if (this.currentReport !== report) {

				if (report === 'social') {
					this.adjustDateRangeForSocialReport();
				} else if (this.currentReport === 'social') {
					this.filter.setParamValue(InsightsFilterDef.FILTER_DATERANGE, this.retrieveDateRange().toISO());
				}

				// Change route
				this.$router.push({ name: 'classroom.insights.' + report });
			}

			this.currentReport = report;
			sessionStorage.setItem("insights.report", report);
		},

		storeDateRange() {
			const values = this.filter.getParamValues(InsightsFilterDef.FILTER_DATERANGE);
			if (values.length > 0) {
				sessionStorage.setItem("insights.dateRange", JSON.stringify(values[0].label));
			}
		},

		retrieveDateRange() {
			const intervalStr = sessionStorage.getItem("insights.dateRange");
			if (intervalStr) {
				return Interval.fromISO(JSON.parse(intervalStr));
			} else {
				return Interval.fromDateTimes(DateTime.now().startOf('week'), DateTime.now().endOf('week'));
			}
		},

		adjustDateRangeForSocialReport() {
			// Social report always covers last 31 days for now
			this.filter.setParamValue(
				InsightsFilterDef.FILTER_DATERANGE,
				Interval.fromDateTimes(
					DateTime.now().endOf('day').minus({ days: 31 }),
					DateTime.now().endOf('day')
				).toISO()
			);
		},
	}

}
</script>
