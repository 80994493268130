<template>
<TheStdLayout :title="$tc('User', 2)">

	<div class="px-4 py-6 mx-auto sm:px-6 md:px-8">

		<FloatingPlusButton @push="handleCreateRequest" />

		<div class="float-right sticky z-30 mt-2 pr-4 -mb-6 print:hidden" v-if="usersStore.me?.impersonating">
			<a :href="emailEveryoneString" class="btn btn-default btn-circle" title="Email all users">
				<EnvelopeIcon class="w-6 h-6"></EnvelopeIcon>
			</a>
		</div>

		<table class="divide-y divide-base-200 w-full">

			<thead>
				<tr>
					<th scope="col" class="table-col-title">
						<SortableHeader property="name" :order="usersStore.order" @change="usersStore.changeOrder">{{ $t('Name') }}</SortableHeader>
					</th>
					<th scope="col" class="table-col-title">
						<SortableHeader property="role" :order="usersStore.order" @change="usersStore.changeOrder">{{ $t('Role') }}</SortableHeader>
					</th>
					<th scope="col" class="table-col-title">
						{{ $t('Status') }}
					</th>
					<th scope="col" class="px-6 py-3">
						<span class="sr-only">{{$t('Actions')}}</span>
					</th>
				</tr>
			</thead>

			<tbody class="bg-base divide-y divide-base-200">

				<tr v-show="!loaded && usersStore.users.length === 0">
					<td colspan="4">
						<Skeleton content-type="userlist" :items="3" class="m-4" thumbnail-classes="mask mask-squircle" />
					</td>
				</tr>

				<tr v-for="user in usersStore.users" :key="user.id" class="text-sm">
    				<td class="px-4 py-3 whitespace-nowrap print:py-1">
						<div class="flex items-center">
							<div class="hidden sm:block flex-shrink-0">
								<img class="w-10 h-10 bg-base-200 rounded-full flex-shrink-0" src="https://www.gravatar.com/avatar/{{ md5(strtolower($listedUser->email)) }}?s=40&d=mp" />
							</div>
							<div class="sm:ml-4">
								<div class="font-medium">
									<div class="ml-3">
										<p class="font-medium">
											<span v-text="user.name?? ''"></span>&nbsp;<span v-if="user.isMe()" class="text-gray-400">({{ $t('You') }})</span>
										</p>
										<p class="text-base-content-light">{{ user.email }}</p>
									</div>
								</div>
							</div>
						</div>
					</td>

					<td class="px-4 py-3 whitespace-nowrap">
						<template v-if="user.role">
							{{ $t(user.role.charAt(0).toUpperCase() + user.role.slice(1)) }}
						</template>
					</td>
					<td class="px-4 py-3 whitespace-nowrap">
						<span class="badge lowercase" :class="[user.active ? 'badge-success' : 'badge-error']">
							{{ user.active? $t('Active') : $t('Inactive') }}
						</span>
					</td>

    				<td class="px-4 py-3 whitespace-nowrap text-right font-medium flex justify-end gap-2 sm:gap-4 print:py-1">
      					<div class="flex-row print:hidden">
							<button class="btn btn-ghost btn-circle bg-base-100" @click="handleEditRequest(user)">
								<PencilIcon class="w-5 h-5"></PencilIcon>
							</button>
						</div>
					</td>

				</tr>

			</tbody>
		</table>
  	</div>

	<UserEditModal v-if="showFormModal" :user="user" @requestClose="closeEditPopup" />

</TheStdLayout>
</template>

<script lang="ts">
import {mapStores} from "pinia";
import {useUsersStore} from "@/stores/Users.store";
import {User} from "@/models/User.model";

import Skeleton from '@/components/ui/Skeleton.vue';
import UserEditModal from "@/components/UserEditModal.vue";
import { PencilIcon, EnvelopeIcon } from '@heroicons/vue/24/outline';
import { ApiErrors } from "@/stores/errors/ApiErrors";
import SortableHeader from "@/components/ui/SortableHeader.vue";

export default {

	data() {
		return {
			loaded: false,
			user: null,
			showFormModal: false,
		};
	},

	components: {
		SortableHeader,
		Skeleton,
		UserEditModal,
		PencilIcon,
		EnvelopeIcon
	},

	computed: {
		...mapStores(useUsersStore),

		emailEveryoneString() {

			const emails: string[] = [];
			let ownerEmail : string = '';

			this.usersStore.users.forEach((user: User) => {
				if (!user.active) {
					return;
				}

				if (user.role === 'owner' && !ownerEmail) {
					ownerEmail = user.email;
				} else if (user.email) {
					emails.push(user.email.trim());
				}
			});

			let url = 'mailto:';
			if (ownerEmail) {
				url += ownerEmail;
			}

			url += '?bcc=' + emails.join(';');
			return url;

		},
	},

	methods: {

		handleCreateRequest() {
			this.showFormModal = true;
		},

		handleEditRequest(user: User) {
			this.user = user;
			this.showFormModal = true;
		},

		async loadUsers() {
			try {
				await this.usersStore.load();
				this.loaded = true;
			} catch (e) {
				if (e instanceof ApiErrors) {
					this.$notify({
						text: e.toString(),
						type: "error"
					});
				} else throw e;
			}
		},

		closeEditPopup() {
			this.user = null;
			this.showFormModal = false;
		},
	},

	mounted() {
		this.loadUsers();
  	},
};

</script>
