<template>
	<div class="avatar aspect-square">
		<div class="avatar-selected mask mask-square items-center justify-center"
			:class="[disabled ? 'opacity-25' : '']"
			style="display: flex;"
		>
			<p class="mask mask-square aspect-square w-11/12 flex items-center justify-center">
				<svg v-if="icon"
					fill="currentColor"
					viewBox="0 0 24 24"
					xmlns="http://www.w3.org/2000/svg"
					class="w-3/5 aspect-square"
					>
					<use :href="icon.url"></use>
				</svg>
				<svg v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" fill="currentColor" class="w-3/5 aspect-square">
					<!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
					<path d="M144 416c-17.67 0-32 14.33-32 32s14.33 32.01 32 32.01s32-14.34 32-32.01S161.7 416 144 416zM211.2 32H104C46.66 32 0 78.66 0 136v16C0 165.3 10.75 176 24 176S48 165.3 48 152v-16c0-30.88 25.12-56 56-56h107.2C244.7 80 272 107.3 272 140.8c0 22.66-12.44 43.27-32.5 53.81L167 232.8C137.1 248 120 277.9 120 310.6V328c0 13.25 10.75 24.01 24 24.01S168 341.3 168 328V310.6c0-14.89 8.188-28.47 21.38-35.41l72.47-38.14C297.7 218.2 320 181.3 320 140.8C320 80.81 271.2 32 211.2 32z"/>
				</svg>
			</p>
		</div>
	</div>
</template>

<script lang="ts">

import { SpriteIcon } from '@/models/Icon.model';

export default {
	props: {
		icon: {
			type: SpriteIcon,
		},
		disabled: {
			type: Boolean,
			default: false
		},
	}
}
</script>