import { AbstractHasIcon } from "@/models/AbstractHasIcon.model";
import { Classroom } from "@/models/Classroom.model";
import { Icon } from "@/models/Icon.model";

export class PupilClassroom extends AbstractHasIcon {

	public classroom: Classroom;
	public color: string | null;
	public active: boolean = true; // frontend attribute: allows to remove and add again an existing relation without losing data

	constructor(classroom: Classroom, icon: Icon | null, color: string | null) {
		super();

		this.classroom = classroom;
		this.icon = icon;
		this.color = color;
		this.active = true;
	}

	clone(): PupilClassroom {
		let clonedInstance = Object.assign(
			Object.create(
				Object.getPrototypeOf(this)
			),
			this
		);
		return clonedInstance;
	}
}
