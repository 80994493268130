import { useUsersStore } from "@/stores/Users.store";
import { useOrganisationsStore } from "@/stores/Organisations.store";
import { UserRole } from "@/models/UserRole";

export class User {

    public id ?: string;
    public name ?: string;
	public email ?: string;
    public roles: UserRole[] = [];
    public impersonating: boolean = false;
	public locale ?: string;
	public capabilities: string[] = [];

	public google_id ?: string;

	public superAdmin: boolean = false;

    static mapFromServer(data: any) {
        const user = new User();
        user.setFromServerData(data);

        return user;
    }

	getNameIdentifier(): string {
		if (this.name) {
			return this.name;
		}
 
		if (this.email) {
			return this.email;
		}

		return 'User ' + this.id;
	}

	/**
	 * Return the id of the user; throw exception if not defined.
	 * @returns string
	 */
	getId(): string {
		if (!this.id) {
			throw new Error('User has no id');
		}
		return this.id;
	}

    isMe(): boolean {
		const me = useUsersStore().me;
		if (!me) {
			return false;
		}

        return me.id === this.id;
    }

	isSuperAdmin(): boolean {
		return this.superAdmin && useOrganisationsStore().currentOrganisation?.name === 'SUPER';
	}

    setFromServerData(data: any)
    {
        this.id = data.id;
        this.name = data.name;

        this.impersonating = data.impersonating;
		this.locale = data.locale;
		this.email = data.email;

        if (data.roles) {
            this.roles = data.roles.map((orgData: any) => {
				return UserRole.mapFromServer(orgData);
			});
        }

		if (typeof(data.super_admin) !== 'undefined') {
			this.superAdmin = data.super_admin;
		}

		if (typeof(data.capabilities) !== 'undefined') {
			this.capabilities = data.capabilities;
		}

		this.google_id = data.google_id;
    }

    clone(): User {
		let clonedInstance = Object.assign(
			Object.create(
				Object.getPrototypeOf(this)
			),
			this
		);

		clonedInstance.roles = this.roles.map(org => org.clone());

		return clonedInstance;
	}

	getServerData(): any {
		return {
			id: this.id,
			name: this.name,
			email: this.email,
			roles: this.roles.map(
				(org) => {
					return {
						organisation: {
							id: org.organisation.id
						},
						role: org.role,
						active: org.active
					};
				}
			)
		};
	}

	/**
	 * Return the role associated to the current organisation set in the organisation store.
	 */
	public getCurrentOrganisationRole(): UserRole | null {
		return this.roles
			.find(org => org.organisation.id === useOrganisationsStore().currentOrganisation?.id) || null;
	}

	/**
	 * Magic currentOrganisationRole getter and setter for backwards compatability
	 * and easier access to the current organisation role
	 */
	public get role(): string {
		return this.getCurrentOrganisationRole()?.role || '';
	}

	public set role(value: string) {
		const organisationRole = this.getCurrentOrganisationRole();
		if (!organisationRole) {
			throw new Error('No current organisation set');
		}
		organisationRole.role = value;
	}

	public get active(): boolean {
		return this.getCurrentOrganisationRole()?.active || false;
	}

	public set active(value: boolean) {
		const organisationRole = this.getCurrentOrganisationRole();
		if (!organisationRole) {
			throw new Error('No current organisation set');
		}
		organisationRole.active = value;
	}

	hasOwnerPermissions() {
		return this.getCurrentOrganisationRole()?.hasOwnerPermissions() || false;
	}

	hasAdminPermissions() {
		return this.getCurrentOrganisationRole()?.hasAdminPermissions() || false;

	}

	hasTeacherPermissions() {
		return this.getCurrentOrganisationRole()?.hasTeacherPermissions() || false;
	}

	hasCapability(ability: string) {
		return this.capabilities.includes(ability);
	}
}
