<template>
	<div class="w-full">
		<div class="relative flex justify-center">
			<button
				v-if="collapsable"
				type="button"
				@click="requestOpenPopover"
				class="relative w-full input pr-10 text-left"
				:class="buttonFieldClasses"
			>
				<!-- <span v-if="(count = selectedItems.length) >= 1" class="flex gap-2">
					<span class="w-5 h-5 flex justify-center rounded-full bg-primary">{{ count }}</span>
					{{ $t('selected') }}
				</span>
				<span v-else class="opacity-50"><slot /></span> -->
				<span class="text-base-content-light"><slot /></span>
				<span class="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
					<ChevronUpDownIcon class="w-5 h-5"></ChevronUpDownIcon>
				</span>
			</button>

			<Transition
				leave-from-class="opacity-100"
				leave-active-class="transition ease-in duration-100"
				leave-to-class="opacity-0"
			>
				<ul v-show="!collapsable || openPopover"
					class="w-full overflow-auto rounded-md bg-base pb-1 focus:outline-none sm:text-sm"
					:class="[collapsable? 'absolute z-10' : '', popoverStyleClasses]"
					tabindex="-1"
					:style="{ maxHeight: dropdownMaxHeight }"
				>

					<li v-if="withFilter" class="sticky top-0 z-10 bg-base pt-1">
						<div class="relative mx-4" :class="[collapsable? 'py-2' : '']">
							<input v-model="filterQuery" type="text" ref="itemSearchField" :placeholder="$t('Search')" class="input w-full pr-16" :class="inputFieldClasses">
							<button v-show="filterQuery" @click="clearFilter" class="text-accent hover:text-accent-focus absolute inset-y-0 right-9">
								<XMarkIcon class="h-1/3 aspect-square"></XMarkIcon>
							</button>
							<div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
								<MagnifyingGlassIcon class="h-1/3 aspect-square"></MagnifyingGlassIcon>
							</div>
						</div>
					</li>

					<li v-for="item in filteredItems" :key="item.id"
						@click="toggleItemSelectState(item)"
						class="relative cursor-default select-none">

						<slot name="item" v-bind="item"></slot>
					</li>

				</ul>
			</Transition>
		</div>
	</div>
</template>

<script lang="ts">
import { CheckIcon, ChevronUpDownIcon, MagnifyingGlassIcon, XMarkIcon } from '@heroicons/vue/24/outline'

export default {
	name: "MultiSelect",
	emits: ['update:selectedIds', 'itemSelected', 'itemUnselected'],
	props: {
		values: {
			type: Array,    // Array of objects of which the properties get exposed to the parent component in a #item slot
		},
		selectedIds: {
			type: Array
		},
		singleModus: {	// If true, only one item can be selected at a time
			type: Boolean,
			default: false
		},
		collapsable: {
			type: Boolean,
			default: true
		},
		dropdownMaxHeight: {
			type: String,
			default: '24rem'
		},
		buttonFieldClasses: {
			type: String,
			default: ''
		},
		inputFieldClasses: {
			type: String,
			default: ''
		},
		withFilter: {
			type: Boolean,
			default: true
		}
	},

	watch: {
		values() {
			this.updateListItems();
		},

		selectedIds() {
			this.updateListItems();
		}
	},

	components: {
		CheckIcon, ChevronUpDownIcon, MagnifyingGlassIcon, XMarkIcon,
	},
	data() {
		return {
			openPopover: false,
			filterQuery: null,
			listItems: [],
		};
	},
	computed: {

		popoverStyleClasses() {
			return this.collapsable ? 'shadow-lg ring-1 ring-neutral ring-opacity-10 ' : '';
		},

		selectedItems() {
			return this.listItems.filter(
				a => {
					return this.selectedIds.includes(a.id);
				}
			);
		},

		filteredItems() {
			if(this.filterQuery) {
				const searchString = this.filterQuery.toLowerCase();
				return this.listItems.filter(
					function (a) {
						if(!a.value.label) {
							return false;
						}
						const itemName = a.value.label.toLowerCase();
						if ( itemName.startsWith(searchString) || itemName.indexOf(searchString) !== -1 ) {
							return true;
						}
						return false;
					}.bind(this)
				);
			}
			return this.listItems;
		},
	},

	mounted() {
		//this.listItems = [ ...this.modelValue ];
		this.updateListItems();
	},

	methods: {

		updateListItems() {
			if(this.singleModus && this.selectedIds.length > 1) {
				throw new Error("MultiSelect: singleModus, but multiple items are selected");
			}

			this.listItems = this.values.map(el => {
				return {
					selected: this.selectedIds.includes(el.id),
					value: el
				};
			});
		},

		showPopover() {
			this.openPopover = true;
			if(this.withFilter) {
				setTimeout(() => this.$refs["itemSearchField"].focus(), 200);
			}
		},

		hidePopover() {
			this.openPopover = false;
		},

		requestOpenPopover(event) {
			// event.stopPropagation();
			window.addEventListener('click', this.closeIfPopoverClickOutside);
			this.showPopover();
		},

		closeIfPopoverClickOutside(event) {
			if(!this.$el.contains(event.target)) {
				window.removeEventListener('click', this.closeIfPopoverClickOutside);
				this.hidePopover();
			}
		},

		highlightListItem(el, toggleState) {
			if(toggleState == true) {
				el.classList.add("text-accent-content", "bg-accent");
				this.highlightedListItem = el;
			}else{
				el.classList.remove("text-accent-content", "bg-accent");
			}
		},

		onListItemHoverStart(event) {
			this.highlightListItem(event.target, true);
		},

		onListItemHoverStop(event) {
			this.highlightListItem(event.target, false);
		},

		/**
		 * @param item
		 */
		toggleItemSelectState(item) {
			item.selected = !item.selected;

			if(this.singleModus) {
				this.hidePopover();
			}

			this.$emit('update:selectedIds',
				this.listItems.filter( v => v.selected ).map( v => v.value.id )
			);

			if( item.selected ) {
				this.$emit('itemSelected', item.value);
			} else {
				this.$emit('itemUnselected', item.value);
			}
		},

		clearFilter() {
			this.filterQuery = null;
			this.$refs["itemSearchField"].focus();
		},
	}
}
</script>
