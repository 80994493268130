<template>
	<Drawer :title="$t('Select goals')" initialWidth="2xl" :closeOnClickOutside="true" @requestUnmount="$emit('requestUnmount')">

		<template #default="{ hide }">

			<div class="pt-4">

				<div v-if="suggestedGoals.length" role="tablist" class="px-6 tabs mb-2">
					<a role="tab" class="tab tab-bordered uppercase" :class="[activeTab === 'search'? 'tab-active' : '']" @click.prevent="activeTab = 'search'">
						<FunnelIcon class="w-5 h-5 mr-1"/>
						{{ $t('Search') }}
					</a>
					<a role="tab" class="tab tab-bordered uppercase" :class="[activeTab === 'suggestions'? 'tab-active' : '']" @click.prevent="activeTab = 'suggestions'">
						{{ $t('Suggestions') }} <div class="badge badge-primary ml-1">{{ suggestedGoals.length }}</div>
					</a>
				</div>
				<div v-else class="text-sm uppercase px-6 flex gap-2 font-semibold">
					<FunnelIcon class="w-5 h-5"/>
					{{ $t('Search') }}
				</div>

				<div v-if="suggestedGoals.length && activeTab === 'suggestions'" class="px-6 pb-6">
					<div>
						<ul>
							<li v-for="goal in suggestedGoalsSorted" :key="goal.path">
								<GoalListItem
									:listItem="goal"
									action="select"
									:checked="selectedGoals.find(g => g.id == goal.id) ? true : false"
									:withTags="false"
									@checked="addGoal"
									@unchecked="removeGoal"
								/>
							</li>
						</ul>
					</div>
				</div>

				<div v-else-if="activeTab === 'search'" class="pt-2">
					<div v-if="!goalLibraryLoaded" class="text-center py-4">
						<span class="loading loading-spinner loading-sm"></span>
					</div>

					<div v-else class="drawer-content pb-4">
						<GoalFilter
							:initialFilter="goalsStore.filter"
							:goalSource="goalSource"
							:collapsable="false"
							:collapsed="false"
							:toggleButtonLabel="''"
							class="mb-8 relative z-10"
							@change="storeFilterSettings"
						/>

						<GoalList
							v-if="!goalsStore.filter.isEmpty()"
							:goalSource="goalSource"
							action="select"
							:checkedFunction="goal => selectedGoals.find(g => g.id == goal.id) ? true : false"
							@itemChecked="addGoal"
							@itemUnchecked="removeGoal"
							class="relative z-0"
						/>
					</div>
				</div>
			</div>

			<div class="drawer-action-bar justify-end gap-2">
				<button type="submit" class="btn btn-primary" @click="hide">
					{{ $t('Done') }}
				</button>
			</div>
		</template>

	</Drawer>
</template>


<style scoped>
.bounceInOut-enter-active {
	animation: bounce-in 0.3s;
}
.bounceInOut-leave-active {
	animation: bounce-in 0.3s reverse;
}
@keyframes bounce-in {
	0% {
		transform: scaleY(0);
		opacity: 0;
	}
	50% {
		transform: scaleY(1.05);
	}
	100% {
		transform: scaleY(1);
		opacity: 1;
	}
}
</style>


<script lang="ts">
import { mapStores, mapState } from "pinia";
import { useUsersStore } from "@/stores/Users.store";
import { useOrganisationsStore } from "@/stores/Organisations.store";
import { useGoalsStore } from "@/stores/Goals.store";
import { GoalSource } from "@/utils/GoalSource";
import SmaSyLocalStorage from '@/utils/SmaSyLocalStorage.util';

import Drawer from '@/components/ui/Drawer.v2.vue';
import GoalFilter from "@/components/GoalFilter.vue";
import GoalList from "@/components/GoalList.vue";
import GoalListItem from "@/components/GoalListItem.vue";
import { ChevronDownIcon, FunnelIcon } from '@heroicons/vue/24/outline';
import {GoalFilterDef} from "@/stores/filters/GoalFilterDef";

export default {

	emits: ['requestUnmount', 'goalSelected', 'goalUnselected'],

	props: {
		selectedGoals: {
			type: Array,
			default: () => [],
		},

		suggestedGoals: {
			type: Array,
			default: () => [],
		},
	},

	components: {
		Drawer,
		GoalFilter,
		GoalList,
		GoalListItem,
		ChevronDownIcon, FunnelIcon,
	},

	data() {
		return {
			goalLibraryLoaded: false,
			goalSource: null,
			activeTab: 'search',
		}
	},

	computed: {
		...mapStores(useOrganisationsStore, useGoalsStore),
		...mapState(useUsersStore, ['me']),

		suggestedGoalsSorted() {
			return this.suggestedGoals.sort((a, b) => a.fullPath.localeCompare(b.fullPath));
		},
	},

	async mounted() {
		this.goalSource = new GoalSource();

		if (this.organisationsStore.currentOrganisation.curriculum) {
			await this.goalSource.load(this.organisationsStore.currentOrganisation.curriculum);

			// initialize the filter with settings stored in local storage if any
			const locallyStoredFilter = SmaSyLocalStorage.getUserItem(this.me.id, 'lessonplan-goal-filter');
			if (locallyStoredFilter) {
				this.goalsStore.filter.fromJson(JSON.parse(locallyStoredFilter));
			} else {
				this.goalsStore.filter.clear();
			}

			await this.goalsStore.load();
		}
		this.goalLibraryLoaded = true;
	},

	methods: {
		addGoal(goal) {
			this.$emit('goalSelected', goal);
		},

		removeGoal(goal) {
			this.$emit('goalUnselected', goal);
		},

		storeFilterSettings(filterDef: GoalFilterDef) {
			if(filterDef.isEmpty()) {
				SmaSyLocalStorage.removeUserItem(this.me.id, 'lessonplan-goal-filter');
			} else {
				SmaSyLocalStorage.setUserItem(this.me.id, 'lessonplan-goal-filter', filterDef.toJson());
			}
		},
	}

}
</script>
