import {defineStore} from "pinia";
import {ErrorMessage} from "@/stores/errors/ErrorMessage";
import {User} from "@/models/User.model";
import {orgApi, api, setOrganisationId} from "@/utils/Api.util";
import {useClassroomsStore} from "@/stores/Classrooms.store";
import {ApiErrors} from "@/stores/errors/ApiErrors";
import config from "@/config/app.config";
import { OrderDirection, OrderParameter } from "@/models/OrderParameter.model";
import { useDefaultOrderStore } from "@/stores/DefaultOrder.store";
import { useOrganisationsStore } from "@/stores/Organisations.store";
import { UserRole } from "@/models/UserRole";
import { Organisation } from "@/models/Organisation.model";

interface UserStoreState {
	errorMessage: ErrorMessage | null,
	me: User | null,
	users: User[],
	roles: string[],
	order: OrderParameter,
}

export const useUsersStore = defineStore('users', {

	state: (): UserStoreState => ({
		errorMessage: null,
		me: null,
		users: [],
		roles: [ 'owner', 'admin', 'teacher' ],
		order: useDefaultOrderStore().usersOrder
	}),

	getters: {
		admins: (state) => {
			return state.users.filter(u => u.role === 'admin' || u.role === 'owner');
		},

		owners: (state) => {
			return state.users.filter(u => u.role === 'owner');
		},

		teachers: (state) => {
			return state.users.filter(u => u.role === 'teacher');
		},

		fromIds: (state) => (ids: string[]) => {
			return state.users.filter(u => u.id && ids.indexOf(u.id) >= 0);
		}
	},

	actions: {

		new() {
			let newUser = new User();

			const organisation = useOrganisationsStore().currentOrganisation;
			if (!organisation) {
				throw new Error('No organisation set');
			}

			const userRole = new UserRole(useOrganisationsStore().getCurrentOrganisation(), 'teacher', true);
			newUser.roles.push(userRole);

			return newUser;
		},

		clearErrorMessage() {
			this.errorMessage = null;
		},

		async load() {

			let response;
			try {
				response = await orgApi.get("users", {
					params: {
						mask: [
							'*',
							'classrooms.*',
							'roles.*',
						].join(',')
					}
				});
			} catch (e) {
				throw ApiErrors.fromAxiosException(e);
			}

			this.users = response.data.data.map(User.mapFromServer);

			this.orderBy(this.order);
		},

		changeOrder(order: OrderParameter) {
			this.order = order;
			this.orderBy(this.order);
			useDefaultOrderStore().setDefaultUsersOrder(order);
		},

		async loadFromEmail(email: string) {

			if (!email) {
				return null;
			}

			const response = await api.get("users", {
				params: {
					email
				}
			});

			const users = response.data.data.map(User.mapFromServer);
			return users.length > 0 ? users[0] : null;

		},

		/**
		 * Warning: should only be called once during an app loading phase.
		 * Do not call this method within component initialisation, as the access tokens might not be set yet.
		 */
		async loadMe() {

			// already loaded?
			if (this.me) {
				return this.me;
			}

			let response;
			try {
				response = await api.get("users/me", {
					params: {
						mask: [
							'*',
							'roles.*',
							'roles.organisation.*',
							'roles.organisation.features.*',
							'roles.organisation.classrooms.*',
							'roles.organisation.agenda_settings.*',
						].join(',')
					}
				});
			} catch (e) {
				this.errorMessage = ApiErrors.fromAxiosException(e);
				throw this.errorMessage;
			}

			this.me = User.mapFromServer(response.data.data);
			if (this.me.roles.length === 0) {
				return this.me;
			}

			// Check if config organisation is set correctly
			let currentOrganisationIndex = this.me.roles
				.map(o => o.organisation)
				.findIndex(o => o.id == config.ORG_ID);

			let currentOrganisation: Organisation;

			// Organisation not found?
			if (currentOrganisationIndex < 0) {
				if (this.me.roles.length === 1 && this.me.roles[0].organisation.active) {
					currentOrganisation = this.me.roles[0].organisation;
					if (currentOrganisation.id) {
						config.ORG_ID = currentOrganisation.id;
					}
				} else {
					// No organisation selected yet, redirect to organisation selection
					return this.me;
				}
			} else {
				currentOrganisation = this.me.roles[currentOrganisationIndex].organisation;
			}

			if (!currentOrganisation.id) {
				throw new Error('No organisation id set');
			}

			setOrganisationId(currentOrganisation.id);

			// Automatically select first classroom
			useClassroomsStore().setDefaultCurrentClassroom(this.me, currentOrganisation);

			return this.me;

		},

		/**
		 * Get the currently logged in user.
		 * @returns User
		 */
		getMe(): User {	
			if (!this.me) {
				throw new Error('Current user not loaded');
			}
			return this.me;
		},

		clearMe() {
			this.me = null;
		},

		orderBy(orderBy: OrderParameter) {
			this.sortItemsBy(orderBy.property, orderBy.direction);
		},

		sortItemsBy(attr: any, direction = OrderDirection.ASC) {
			this.users.sort(
				(a: any, b: any) => {
					let result;

					if (!a[attr] && !b[attr]) {
						// Sort by id
						result = parseInt(a.id) - parseInt(b.id);
					} else if (!a[attr]) {
						result = -1;
					} else if (!b[attr]) {
						result = 1;
					} else {
						result = a[attr].localeCompare(b[attr]);
					}

					// Same? Sort by id.
					if (result === 0) {
						result = parseInt(a.id) - parseInt(b.id);
					}

					if (direction === OrderDirection.DESC) {
						result = -result;
					}

					return result;
				}
			);
		},

		async save(user: User, sendInvitationEmail: boolean = false) {

			this.errorMessage = null;

			if(user.id) {
				try {
					const response = await orgApi.put("users/" + user.id, user.getServerData());
					this.updateStoreItem(user);
				} catch (e: any) {
					this.errorMessage = ApiErrors.fromAxiosException(e);
					throw this.errorMessage;
				}

			} else {
				try {
					const data = user.getServerData();
					data.send_invitation = sendInvitationEmail;

					const response = await orgApi.post("users", data);
					user.id = response.data.data.id;
					this.addStoreItem(user);
				} catch (e: any) {
					this.errorMessage = ApiErrors.fromAxiosException(e);
					throw this.errorMessage;
				}
			}

			this.orderBy(this.order);
		},

		async savePreferences(user: User) {
			if(!user.id) {
				throw new Error('User ID not set');
			}

			const response = await api.put("users/" + user.id + '/preferences', {
				locale: user.locale
			});

			if (this.me && this.me.id === user.id) {
				// Also store the app locale
				if (user.locale) {
					localStorage.setItem('locale', user.locale);
				}
			}

			window.location.reload(); // hacky hacky joy joy
		},

		async delete(user: User) {

			this.errorMessage = null;
			try {
				const response = await orgApi.delete("users/" + user.id);
				this.deleteStoreItem(user);
			} catch (e: any) {
				this.errorMessage = ApiErrors.fromAxiosException(e);
				throw this.errorMessage;
			}

		},

		updateStoreItem(user: User) {
			const index = this.users.findIndex((u: User) => u.id === user.id);
			if (index !== -1) {
				this.users[index] = user.clone();
			}
		},

		addStoreItem(user: User) {
			this.users.push(user.clone());
			this.orderBy(this.order);
		},

		deleteStoreItem(user: User) {
			const index = this.users.findIndex(v => v.id === user.id);
			if (index >= 0) {
				this.users.splice(index, 1);
			}
		},

		async impersonate(userId: string, organisationId: string | null = null) {
			const response = await api.post("users/" + userId + "/impersonate");

			localStorage.setItem('impersonating_access_token', localStorage.getItem('access_token') || '');
			localStorage.setItem('access_token', response.data.accessToken);

			if (organisationId) {
				localStorage.setItem('organisation_id', organisationId);
			} else {
				localStorage.removeItem('organisation_id');
			}

			window.location.reload();
		},

		async stopImpersonating() {
			if (!this.me) {
				return;
			}

			const response = await api.delete("users/" + this.me.id + "/impersonate");

			localStorage.setItem('access_token', localStorage.getItem('impersonating_access_token') || '');
			localStorage.removeItem('impersonating_access_token');

			window.location.reload();
		}
	}

});
