import { FilterDef } from "@/stores/filters/FilterDef";

export class LessonPlanFilterDef extends FilterDef {

	/**
	 * Constructor
	 */
	constructor() {

		super([
			'activityTags',
			'themeTags',
			'goals',
			'keywords'
		]);

	}

}
