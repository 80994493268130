<template>

<Drawer :title="$t('Change icon')" initialWidth="2xl" @requestUnmount="$emit('requestClose')" :closeOnClickOutside="true">
	<template #default="{ hide }">

		<div class="flex justify-center mt-4">
			<select v-model="selectedIconLibraryId" class="select bg-base-100">
				<option v-for="(lib, index) in iconLibraries" :key="lib.value" :value="lib.value">{{ lib.label }}</option>
			</select>
		</div>

		<div class="p-5">

			<!-- Custom icons -->
			<div v-if="selectedIconLibraryId !== 'default'">
				<p v-if="iconsInSelectedLibrary.length === 0" class="text-sm p-4 italic">{{ $t('This collection is empty') }}</p>
				<div v-else class="flex flex-wrap gap-3">
					<button type="button" v-for="icon in iconsInSelectedLibrary" :key="icon.id" :disabled="!isSelectable(icon)"
						class="relative w-16 h-16 rounded-lg flex justify-center items-center shadow disabled:bg-base disabled:cursor-not-allowed disabled:shadow-none disabled:opacity-20 enabled:hover:scale-110"
						:class="[ isSelected(icon)? 'icon-selected' : '' ]"
						@click="selectIcon(icon, hide)"
					>
						<img :src="icon.url" class="rounded-lg" />
					</button>
				</div>
			</div>

			<!-- Default icons -->
			<div v-else class="flex flex-wrap gap-3">
				<button type="button" v-for="icon in iconsInSelectedLibrary" :key="icon.url" :disabled="!isSelectable(icon)"
					class="relative w-16 h-16 rounded-lg flex justify-center items-center bg-base-100 disabled:text-base-200 disabled:bg-base disabled:cursor-not-allowed disabled:shadow-none enabled:hover:scale-110"
					:class="[ isSelected(icon)? 'icon-selected' : '' ]"
					@click="selectIcon(icon, hide)"
				>
					<svg fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" :class="'w-3/5 h-3/5'">
						<use :href="icon.url"></use>
					</svg>
				</button>
			</div>

		</div>

	</template>
</Drawer>

</template>

<script lang="ts">

import {mapStores} from "pinia";
import {useIconLibrariesStore} from "@/stores/IconLibraries.store";
import {FileIcon, SpriteIcon} from "@/models/Icon.model";
import {useIconsStore} from "@/stores/Icons.store";
import {usePreferencesStore} from "@/stores/Preferences.store";
import Drawer from '@/components/ui/Drawer.v2.vue';
import {Classroom} from "@/models/Classroom.model";
import {HasIcon} from "@/interfaces/HasIcon";

export default {

	emits: [
		'icon:selected',
		'requestClose',
	],

	props: {
		classroom: {
			type: Classroom,
			required: true
		},
		group: {
			type: String,
			required: true,
		},
		isSelectable: {
			type: Function,
			required: true,
		},
		model: {
			type: Object() as HasIcon,
			required: true
		}
	},

	components: {
		Drawer,
	},

	data() {
		return {
			selectedIconLibraryId: 'default',
			selectedIcon: null,
		}
	},

	async mounted() {

		// @todo loaded icons must be of the provided classroom

		// Load everything we need
		let promises = [];
		if (this.group === 'pupil') {
			promises.push(this.iconsStore.loadStandardPupilIcons());
		} else if (this.group === 'activity') {
			promises.push(this.iconsStore.loadStandardActivityIcons());
		}

		promises.push(this.iconsStore.load());
		promises.push(this.iconLibrariesStore.load(this.classroom.id));

		await Promise.all(promises);

		// Find the library where the currently selected icon is in and open that one by default
		let iconLibrary = null;

		if (this.model.icon instanceof FileIcon) {
			iconLibrary = this.iconLibrariesStore.iconLibraries.find(
					(library) => {
						return library.id === this.model.icon.library.id;
					}
			);
		} else if (this.model.icon instanceof SpriteIcon) {
			this.selectedIconLibraryId = 'default';
			return;
		} else if (this.preferredLibrary) {
			iconLibrary = this.iconLibrariesStore.iconLibraries.find(
					(library) => {
						return library.id === this.preferredLibrary;
					}
			);
		}

		if (iconLibrary) {
			this.selectedIconLibraryId = iconLibrary.id;
		} else {
			this.selectedIconLibraryId = 'default';
		}

	},

	computed: {

		...mapStores(useIconLibrariesStore, useIconsStore, usePreferencesStore),

		/**
		 * @returns {[{label: *, value: string}]}
		 */
		iconLibraries() {
			var libraries = [{
				value: 'default',
				label: this.$t('Standard collection')
			}];

			this.iconLibrariesStore.iconLibraries.forEach(
					iconLibrary => {
						if (iconLibrary.group !== this.group) {
							return;
						}

						libraries.push({
							value: iconLibrary.id,
							label: iconLibrary.name,
						});
					}
			);

			return libraries;
		},

		/**
		 * @returns {string[]}
		 */
		iconsInSelectedLibrary() {
			let iconSet = [];
			if(this.selectedIconLibraryId === 'default') {
				iconSet = this.standardIcons;
			} else {
				iconSet = this.iconsStore.allCustomFileIcons.filter((item) => {
					return item.library.id == this.selectedIconLibraryId;
				});
			}
			return iconSet;
		},

		standardIcons() {
			switch (this.group) {
				case 'pupil':
					return this.iconsStore.stdPupilIcons;

				case 'activity':
					return this.iconsStore.stdActivityIcons;
			}
			return [];
		},

		preferredLibrary() {
			switch (this.group) {
				case 'pupil':
					return this.preferencesStore.lastUsedPupilIconLibrary;

				case 'activity':
					return this.preferencesStore.lastUsedActivityIconLibrary;
			}
		}
	},

	methods: {

		isSelected(icon) {
			if (this.selectedIcon) {
				return this.selectedIcon.equals(icon);
			}
			else if (this.model && this.model.icon) {
				return this.model.icon.equals(icon);
			}
			return false;
		},

		selectIcon(icon: Icon, hideDrawerCallback: Function) {
			this.selectedIcon = icon;
			this.$emit('icon:selected', icon);
			this.setPreferredLibrary();
			hideDrawerCallback();
		},

		setPreferredLibrary() {
			switch (this.group) {
				case 'pupil':
					this.preferencesStore.setLastUsedPupilIconLibraryId(this.selectedIconLibraryId);
					break;

				case 'activity':
					this.preferencesStore.setLastUsedActivityIconLibraryId(this.selectedIconLibraryId);
					break;
			}
		}

	}
}

</script>
