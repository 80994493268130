<template>
	<div>
		<label v-if="label" class="block input-label" v-text="label" :class="[validationError? 'text-error-content' : '']"></label>
		<VueDatePicker
			v-bind="$attrs"
			:locale="locale"
			:format="datePickerDateFormat"
			:action-row="{showSelect: false, showCancel: false}"
			auto-apply
			:enable-time-picker="false"
			position="left"
		>
			<template v-if="asInput" #dp-input="{ value }">
				<label class="input flex items-center gap-2" :class="inputCssClassesIncludingErrorState">
					<CalendarDaysIcon class="w-5 h-5 opacity-60" />
					<input type="text" class="grow bg-transparent" :value="value" :placeholder="placeholder" />
				</label>
			</template>
			<template v-else #trigger>
				<button type="button" class="btn btn-ghost bg-base-100 btn-sm">
					<CalendarDaysIcon class="w-5 h-5" />
				</button>
			</template>
		</VueDatePicker>
	</div>
</template>

<style>
.dp__outer_menu_wrap {
	@apply shadow-md;
}
</style>

<script lang="ts">
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import { CalendarDaysIcon } from '@heroicons/vue/24/outline';
import { FieldValidationError } from '@/stores/errors/FieldValidationError';

export default {
	inheritAttrs: false,

  	components: {
		VueDatePicker,
		CalendarDaysIcon,
  	},

	props: {
		label: {
			type: String,
		},
		asInput: {
			type: Boolean,
			default: true,
		},
		inputCssClasses: {
			type: String,
			default: '',
		},
		placeholder: {
			type: String,
			default: '',
		},
		validationError: {
			type: FieldValidationError
		},
	},

	computed: {
		locale() {
			return this.$i18n.locale;
		},

		datePickerDateFormat() {
			return (date) => {
				return date.toLocaleDateString(this.locale);
			}
		},

		inputCssClassesIncludingErrorState() {
			let classes = this.inputCssClasses;
			if(this.validationError) {
				classes += ' input-error';
			}
			return classes;
		},
	}
};
</script>