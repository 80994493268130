<template>
	<Drawer :title="inUpdateMode? $t('Edit evaluation') : $t('Add evaluation')" initialWidth="2xl" @requestUnmount="hideEditor">
		<template #default="{ hide }">

			<form @submit.prevent="save" class="p-6 flex flex-col space-y-5">

				<div class="flex items-center gap-3">
					<UserCircleIcon class="w-6 h-6 shrink-0" /> Amélie De Ridder
				</div>
				<div class="flex items-start gap-3">
					<MapPinIcon class="w-6 h-6 shrink-0" />
					<div>
						8.1.1.2 - Ik kan de telrij minstens tot 4 opzeggen onder ritmische begeleiding
					</div>
				</div>

				<div class="mb-8 mt-4">

					<div>
						<div class="h-8 text-center text-sm text-base-content-light">
							<label v-if="newScore">{{ newScore.description }}</label>
						</div>
						<div class="eval-scoredots">
							<div v-for="score in evaluationScores()" :key="score.id" class="relative" :style="{ borderColor: score.color }">
								<button type="button" :style="{ backgroundColor: score.color }" :class="[newScore?.id === score.id? '':'opacity-20']" @click="setScore(score)"></button>
							</div>
						</div>
					</div>
				</div>

				<div class="flex gap-4">
					<div class="w-16 h-16 shrink-0 rounded-full bg-base-100 place-content-center text-center">
						Photo
					</div>
					<textarea class="textarea textarea-bordered h-32 w-full" ref="eval-note" :disabled="newScore? false : true" placeholder="Notitie"></textarea>
				</div>

				<div class="flex flex-col gap-8">
					<!-- if there are any previous evalutions -->
					<div v-for="evaluation in previousEvaluations" class="flex gap-4">
						<div class="w-16 h-16 shrink-0 rounded-full bg-base-100 place-content-center text-center" :title="evaluation.author">
							Photo
						</div>
						<div>
							<div class="text-xs">
								{{ evaluation.date }}
							</div>
							<div class="mt-1 text-xs italic text-base-content-light bg-base-100 rounded-lg py-3 px-3">
								<span class="inline-block w-4 h-4 mr-2 rounded-full" :style="{ backgroundColor: evaluation.score.color }"></span>
								{{ evaluation.note }}
							</div>
						</div>
					</div>
				</div>

				<div class="drawer-action-bar" :class="[inUpdateMode? 'justify-between' : 'justify-end']">
					<!-- <Dropdown v-if="inUpdateMode" position="top-start">
						<template #trigger>
							<button type="button" class="btn" :class="[saving? 'btn-disabled':'btn-danger btn-outline']">
								<span v-show="deleting" class="loading loading-spinner"></span>
								<TrashIcon class="w-5 h-5" />
							</button>
						</template>
						<template #content>
							<DropdownDialogConfirm :confirm-label="$t('Yes')" :cancel-label="$t('No')" confirm-style="danger" class="w-80" @confirm="confirmRemove()">
								<span class="text-danger">{{ $t('Are you sure?') }}</span>
							</DropdownDialogConfirm>
						</template>
					</Dropdown> -->

					<div class="flex justify-end gap-1">
						<button type="button" class="btn btn-ghost" @click="hide" :class="[saving || deleting? 'hidden':'']">
							{{ $t('Cancel') }}
						</button>
						<button type="submit" class="btn" :class="[!canSave? 'btn-disabled':'btn-primary']">
							<span v-show="saving" class="loading loading-spinner"></span>
							{{ $t('Save') }}
						</button>
					</div>
				</div>

			</form>

		</template>
	</Drawer>
</template>

<style>
.eval-scoredots {
	@apply flex items-start justify-center gap-6;
}
.eval-scoredots button {
	@apply w-12 h-12 mb-2 rounded-full hover:scale-110 transition-transform;
}
</style>

<script lang="ts">
import Drawer from "@/components/ui/Drawer.v2.vue";
import { ChevronUpIcon } from "@heroicons/vue/24/outline";
import { UserCircleIcon, MapPinIcon } from "@heroicons/vue/24/solid";

// dummy data - should come from some store
const goalEvaluationScores = [
	{
		"id": 2345,
		"achieved": true,
		"description": "Boven verwachting",
		"color": "#3b82f6"
	},
	{
		"id": 2348,
		"achieved": true,
		"description": "Bereikt",
		"color": "#22c55e"
	},
	{
		"id": 2349,
		"achieved": false,
		"description": "Verder inoefenen",
		"color": "#fbbf24"
	},
	{
		"id": 2350,
		"achieved": false,
		"description": "Instructie nodig",
		"color": "#ef4444"
	}
];


export default {
	emits: ['requestClose'],

	components: {
		Drawer,
		ChevronUpIcon, UserCircleIcon, MapPinIcon,
	},

	data() {
		return {
			inUpdateMode: false,
			newScore: null,
			saving: false,
			deleting: false,
			previousEvaluations: [
				{
					"date": "8 mei",
					"score": this.evaluationScores()[1],
					"author": "Isabel Schoenackers",
					"note": "Amélie lijkt de leerstof de dag zelf goed te snappen, maar dag nadien heeft ze moeite om de stappen van gisteren verder te zetten."
				},
				{
					"date": "16 januari",
					"score": this.evaluationScores()[3],
					"author": "Isabel Schoenackers",
					"note": "Op basis van de verkennende oefening rond getallenkenns merk ik dat bij Amelie best nog niet begonnen wordt met."
				},
			],
		}
	},

	computed: {
		canSave() {
			return this.newScore && !this.saving && !this.deleting;
		}
	},

	methods: {
		hideEditor () {
			this.$emit('requestClose');
		},

		setScore(score) {
			this.newScore = score;
			// set focus to note textarea, but with a short delay
			setTimeout(() => {
				this.$refs['eval-note'].focus();
			}, 100);
		},

		evaluationScores() {
			return goalEvaluationScores;
		},

		save() {
			alert('Save evaluation');
		},
	}
}
</script>