<template>

	<transition appear name="fade" @after-enter="showContent = true" @after-leave="afterDialogCloseAnimation">
	<div v-show="showBackdrop" id="backdrop" class="fixed inset-0 bg-black z-50 bg-opacity-90">

		<transition appear name="zoom" @after-leave="showBackdrop = false">
		<div v-show="showContent" class="fixed bottom-0 left-0 right-0 inset-0 grid place-items-center z-40">

			<div class="absolute p-4 flex flex-col h-full gap-6 inset-0">
				<!-- "back" button -->
				<div class="pl-4 absolute">
					<button type="button" @click="handleGoBack" class="touch-btn back-btn">
						<ChevronLeftIcon class="w-4/5 h-4/5" />
					</button>
				</div>

				<div class="h-full flex-grow flex flex-col items-center justify-center pt-8">
					<div :class="this.selectedLessonItem ? 'elastic-spin' : null">
						<button>
							<ActivityBoardAvatar
								:pupil="pupil"
								:withSymbol="activityBoardStore.avatarWithSymbol"
								:withName="activityBoardStore.avatarWithName"
								:class="[activityBoardStore.avatarWithName? 'h-16 sm:h-18 lg:h-20 px-4' : 'h-20 md:h-24 lg:h-36']"
							/>
						</button>
					</div>

					<div id="activity-selection-grid" class="w-full h-full flex flex-wrap items-center justify-center">

						<template v-for="boardItem in activityBoardStore.boardItems" :key="boardItem.lessonItem.id">
							<div class="flex justify-center mx-2 md:mx-6 lg:mx-8 xl:mx-10">
								<div :id="'bi-btn-' + boardItem.lessonItem.id">
									<button type="button"
										@click="handleChoice(boardItem)"
										class="rounded-lg items-center justify-center bg-gray-700 border-gray-700 disabled:cursor-not-allowed shadow-lg"
										:class="[isSelectableBoardItem(boardItem)? 'touch-btn':'bg-gray-800 border-gray-800 shadow-none']"
										:disabled="!(isSelectableBoardItem(boardItem))"
									>
										<ActivitySymbol
											:activity="boardItem.lessonItem"
											class="bg-gray-50 aspect-square w-14 md:w-18 lg:w-24 xl:w-36 m-1 md:m-2 rounded-lg"
											:class="[isSelectableBoardItem(boardItem)? '':'opacity-10']"
										/>
									</button>
								</div>
							</div>
						</template>

					</div>
				</div>
			</div>

		</div>
		</transition>

	</div>
	</transition>
</template>

<script lang="ts">
import { useActivityBoardStore } from "@/stores/ActivityBoard.store";
import { mapStores } from "pinia";

import { Pupil } from '@/models/Pupil.model';
import ActivityBoardAvatar from "./ActivityBoardAvatar.vue";
import { ChevronLeftIcon } from '@heroicons/vue/24/outline';
import ActivitySymbol from "./ui/symbols/ActivitySymbol.vue";
import { LessonItem } from "@/models/LessonItem.model";
import { nextTick } from "vue";
import { PupilActivity } from "@/models/PupilActivity.model";

export default {
	emits: ['close'],

	props: {
		pupil: {
			type: Pupil,
			required: true
		},
	},

	components: {
		ActivityBoardAvatar,
		ChevronLeftIcon,
		ActivitySymbol,
	},

	data() {
		return {
			showContent: false,
			showBackdrop: true,
			selectedLessonItem: null,
			checkinResponse: null as PupilActivity,
		}
	},

	computed: {
		...mapStores(useActivityBoardStore),
	},

	mounted() {
		// set the layout of the selection grid identical to the activity grid of the parent component
		const boardActivitiesPanel = document.querySelector('#activity-grid');
		const styles = window.getComputedStyle(boardActivitiesPanel);
		let cols = styles.getPropertyValue('grid-template-columns').split(" ").length;

		const boardActivitiesSelectionPanel = document.querySelector('#activity-selection-grid');
		boardActivitiesSelectionPanel.style.gridTemplateColumns = `repeat(${cols}, 1fr)`;
	},

	methods: {
		isSelectableBoardItem(boardItem) {

			// still some seats available?
			if (boardItem.checkins.length >= boardItem.lessonItem.seats) {
				return false;
			}
			// is pupil already checked in here?
			if(boardItem.checkins.find(o => o.pupil.id === this.pupil.id)) {
				return false;
			}
			// is pupil allowed to check in here?

			//console.log(this.pupil.id, boardItem.lessonItem.allowedPupilIds);
			if(!boardItem.lessonItem.allowedPupilIds.includes(this.pupil.id)) {
				return false;
			}
			return true;
		},

		handleGoBack() {
			this.showContent = false;
		},

		makeButtonShake(elementId) {
			const btnEl = document.getElementById(elementId);
			btnEl.classList.toggle('shake');
			setTimeout(() => {
				btnEl.classList.toggle('shake');
			}, 1500);
		},

		async handleChoice(boardItem) {
			// verify again if pupil is allowed to check in here
			if(!this.isSelectableBoardItem(boardItem)) {
				this.makeButtonShake('bi-btn-' + boardItem.lessonItem.id);
				return;
			}

			await this.handleCheckIn(this.pupil, boardItem.lessonItem);
		},

		afterDialogCloseAnimation() {
			this.$emit('close', this.checkinResponse);
		},

		/**
		 * Try to checkin pupil to a lessonItem.
		 * If this does not work (for example, if there is no more room in the selected lesson item),
		 * shake the baby and keep the checkin popup open. The store will reload the board in the background
		 * and disable the previously selected activity.
		 * @param pupil
		 * @param lessonItem
		 */
		async handleCheckIn(pupil : Pupil, lessonItem: LessonItem) {

			if (this.selectedLessonItem) {
				return;
			}
			this.selectedLessonItem = lessonItem;

			const btnEl = document.getElementById('bi-btn-' + lessonItem.id);
			try {
				btnEl.classList.toggle('elastic-spin', true);

				this.checkinResponse = await this.activityBoardStore.checkIn(pupil, lessonItem)
				this.showContent = false;

				//btnEl.classList.toggle('elastic-spin', false);

			} catch (e) {

				this.selectedLessonItem = null;

				btnEl.classList.toggle('elastic-spin', false);
				if (e.response.status === 409) {
					// Conflict. Shake the baby.
					this.makeButtonShake('bi-btn-' + lessonItem.id);
					return;
				}

				this.$notify({
					title: "Error!",
					text: e.toString(),
					type: "error",
				});

				throw e;
			}
		},
	},
}
</script>
