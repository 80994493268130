import { ErrorMessage } from "@/stores/errors/ErrorMessage";
import { useClassroomsStore } from "@/stores/Classrooms.store";
import { orgApi } from "@/utils/Api.util";
import { AbstractAgendaItemModel } from "@/models/AbstractAgendaItem.model";
import { DateTime } from "luxon";
import { ApiErrors } from "@/stores/errors/ApiErrors";
import AlteredEntityHelper from "@/stores/AlteredEntity.helper";
import { AgendaFilterDef } from "@/stores/filters/AgendaFilterDef";

export interface AgendaStoreState<T extends AbstractAgendaItemModel> {
	errorMessage: ErrorMessage | null,
	events: T[],
	loadingRanges: number,

	loadedRanges: {
		start: Date,
		end: Date
	}[],

	lastLoadedRange: {
		start: Date,
		end: Date
	} | null
}

export function AgendaStore<T extends AbstractAgendaItemModel, S extends AgendaStoreState<T>>() {
	return {

		state: (): AgendaStoreState<T> => ({
			errorMessage: null,
			events: [],
			loadingRanges: 0,
			loadedRanges: [],
			lastLoadedRange: null
		}),

		getters: {

			loading(state: S) {
				return state.loadingRanges > 0;
			},

			getBetween(state: S) {
				return (start: Date, end: Date) => {
					return state.events.filter(event => {
						return event.end.getTime() > start.getTime() &&
							event.start.getTime() <= end.getTime();
					});
				};
			},

			getActiveAt(state: S) {
				return (date: Date) => {
					return state.events.filter(event => {
						return event.end.getTime() > date.getTime() &&
							event.start.getTime() <= date.getTime();
					});
				}
			},

			hasBetween(state: S) {
				return (start: Date, end: Date) : boolean => {
					for (let i = 0; i < state.events.length; i ++) {
						if (state.events[i].end.getTime() > start.getTime() &&
							state.events[i].start.getTime() <= end.getTime()) {
							return true;
						}
					}
					return false;
				};
			},

			getFromId(state: S) {
				return (eventId: string): T | undefined => {
					return state.events.find(event => event.id === eventId);
				}
			},

			getIndexFromId(state: S) {
				return (eventId: string) => {
					return state.events.findIndex(event => event.id === eventId);
				}
			},

			apiPath(state: S) {
				throw new Error('Not implemented');
			},

			defaultMask(state: S) {
				return [
					'*'
				];
			}

		},

		actions: {

			isRangedLoaded(start: Date, end: Date) {

				for (let i = 0; i < this.loadedRanges.length; i++) {
					if (
						this.loadedRanges[i].start >= start &&
						this.loadedRanges[i].end <= end
					) {
						return true;
					}
				}
				return false;

			},

			async loadBetween(
				start: Date,
				end: Date,
				filters: AgendaFilterDef | null = null,
				forceReload: boolean = false
			) {

				const classroomId = useClassroomsStore().getCurrentClassroom().id;
				if (this.isRangedLoaded(start, end) && !forceReload) {
					return this.getBetween(start, end);
				}

				let dateRange = {
					start: start,
					end: end
				};

				this.lastLoadedRange = dateRange;

				try {
					this.loadedRanges.push(dateRange);

					this.loadingRanges++;
					const response = await orgApi.get(this.apiPath, {
						params: {
							classroomIds: classroomId,
							start: start.toISOString(),
							end: end.toISOString(),
							mask: this.defaultMask.join(','),
							... (this.filters ? filters.toApiParams() : {}),
						}
					});

					this.mergeEvents(response.data);
					this.loadingRanges--;

				} catch (e) {

					// Unload the range
					this.loadedRanges.splice(this.loadedRanges.indexOf(dateRange), 1);

					throw e;
				}

				return this.getBetween(start, end);

			},

			mergeEvents(responseData: any) {

				const eventsData: any[] = responseData.data;
				eventsData.forEach((eventData) => {

					// do we already have this lesson?
					const event = this.getFromId(eventData.id);
					if (!event) {
						this.events.push(this.mapEventFromServer(eventData));
					} else {
						event.setFromServerData(eventData);
					}

				});

				AlteredEntityHelper.processAlteredEntities(responseData.altered);

			},

			mapEventFromServer(data: any): any {
				throw new Error('Not implemented');
			},

			async clearWeek(date: DateTime) {

				const start = date.startOf('week').toJSDate();
				const end = date.endOf('week').toJSDate();

				await this.clearDateRange(start, end);

			},

			async clearDateRange(start: Date, end: Date) {

				try {
					// Remove from the API
					const response = await orgApi.delete(this.apiPath, {
						params: {
							classroomIds: useClassroomsStore().getCurrentClassroom().id,
							start: start.toISOString(),
							end: end.toISOString()
						}
					});

					// Remove from the store
					response.data.data.forEach(
						(lesson: any) => {
							this.removeFromId(lesson.id);
						}
					);

				} catch (e: any) {
					this.errorMessage = ApiErrors.fromAxiosException(e);
					throw this.errorMessage;
				}

			},

			removeFromId(id: string) {
				const index = this.getIndexFromId(id);
				if (index >= 0) {
					this.events.splice(index, 1);
				}
			},

			clearErrorMessage() {
				this.errorMessage = null;
			},

			reset() {
				this.events = [];
				this.loadingRanges = 0;
				this.loadedRanges = [];
			},

			reload() {
				this.reset();
				if (this.lastLoadedRange) {
					return this.loadBetween(this.lastLoadedRange.start, this.lastLoadedRange.end);
				}
			}

		}

	}

}
