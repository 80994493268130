import { defineStore } from 'pinia';
import { LessonPlan } from '@/models/LessonPlan.model';
import { Lesson } from "@/models/Lesson.model";
import { LessonItem } from "@/models/LessonItem.model";
import { Tag as TagModel } from "@/models/Tag.model";

import { useUsersStore } from "@/stores/Users.store";
import { useTagsStore } from "@/stores/Tags.store";
import { useThemesStore } from "@/stores/Themes.store";
import { useDefaultOrderStore } from "@/stores/DefaultOrder.store";
import SmaSyLocalStorage from "@/utils/SmaSyLocalStorage.util";
import { LessonPlanFilterDef } from "@/stores/filters/LessonPlanFilterDef";
import { AbstractBaseStore, AbstractStoreState } from "@/stores/AbstractBase.store";

interface LessonPlansStoreState extends AbstractStoreState<LessonPlan> {
	latestNewPlan: LessonPlan | null,
	loadingMore: boolean,
	filter: LessonPlanFilterDef,
}

const BaseStore = AbstractBaseStore<LessonPlan, LessonPlansStoreState>();

export const useLessonPlansStore = defineStore('lessonPlans', {

	state: (): LessonPlansStoreState => {

		const defaultFilter = new LessonPlanFilterDef();

		// Look for localstorage
		const initialFilter = new LessonPlanFilterDef();
		const locallyStoredFilter = SmaSyLocalStorage.getUserItem(useUsersStore().getMe().getId(), 'lessonplan-filter');
		if (locallyStoredFilter) {
			initialFilter.fromJson(JSON.parse(locallyStoredFilter));
		} else {
			initialFilter.clear();
		}

		return BaseStore.state<LessonPlansStoreState>({

			latestNewPlan: null,
			
			order: useDefaultOrderStore().lessonPlansOrder,
			initialFilter,
			defaultFilter,
			loadingMore: false,

		});
	},

	getters: {

		...BaseStore.getters,

		apiPath() {
			return 'users/' + useUsersStore().getMe().getId() + '/lesson-plans';
		},

		/**
		 * Get the default mask that should be included in every request.
		 * @param state 
		 * @returns 
		 */
		defaultMask(state: AbstractStoreState<T>): string[] {
			return [
				'*',
				'tags.*',
				'attachments.*',
				'supplies.*',
				'owner.id',
				'goals.*',
				'goals.tags.*'
			];
		},

		/**
		 * @returns number
		 */
		defaultRecordsPerPage(): number {
			return 25;
		},

	},

	actions: {

		...BaseStore.actions,

		async new(
			lessonItem ?: LessonItem,
			lesson ?: Lesson
		) {

			const plan = new LessonPlan();
			this.latestNewPlan = plan;

			// Set activity label to the name of the activity
			if (lessonItem?.activity) {

				const activityTag = new TagModel();
				activityTag.name = lessonItem.activity.name;

				plan.activityTags = [ activityTag ];

			}

			// Set theme label if available.
			// Don't wait for it, but rather set if when the theme is loaded
			if (lesson?.theme?.id) {
				let theme = await useThemesStore().findById(lesson.theme.id);

				if (!theme || !theme.name) {
					return plan;
				}

				const themeTag = new TagModel();
				themeTag.name = theme.name;

				plan.themeTags = [ themeTag ];
			}

			return plan;

		},

		mapToItem(data: any): LessonPlan {
			return LessonPlan.createFromApiData(data);
		},

		async save(item: LessonPlan) {

			if (!item.isSavedInLib) {
				// must make a new one
				item.originalId = item.id;
				item.id = undefined;
			}

			await this.superSave(item);

			// Reload the tags as new ones may have been created
			useTagsStore().load(true);
		},

		applyFilter(filter: LessonPlanFilterDef) {

			const me = useUsersStore().getMe();
			if(filter.isEmpty()) {
				SmaSyLocalStorage.removeUserItem(me.getId(), 'lessonplan-filter');
			} else {
				SmaSyLocalStorage.setUserItem(me.getId(), 'lessonplan-filter', filter.toJson(), 60 * 60 * 8);
			}

			this.superApplyFilter(filter);
			
		},

	},
});
