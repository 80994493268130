import { FilterDef } from "@/stores/filters/FilterDef";

export class OrganisationFilterDef extends FilterDef {

	/**
	 * Constructor
	 */
	constructor() {

		super([
			'query',
			'subscription_plan',
			'country',
			'expired'
		]);

	}


	get query(): string | null {
		return this.getParamValues('query')[0]?.id || null;
	}

	set query(value: string) {
		this.setParamValue('query', value);
	}

	get subscriptionPlan(): string | null {
		return this.getParamValues('subscription_plan')[0]?.id || null;
	}

	set subscriptionPlan(value: string) {
		if (!value) {
			this.clearParam('subscription_plan');
			return;
		}
		this.setParamValue('subscription_plan', value);
	}

	get country(): string | null {
		return this.getParamValues('country')[0]?.id || null;
	}

	set country(value: string) {
		if (!value) {
			this.clearParam('country');
			return;
		}
		this.setParamValue('country', value);
	}

	get expired(): boolean | null {
		const value = this.getParamValues('expired')[0]?.id;
		if (value === undefined) {
			return null;
		}
		return !!value;
	}

	set expired(value: boolean) {
		if (value === null) {
			this.clearParam('expired');
			return;
		}

		this.setParamValue('expired', value);
	}

}