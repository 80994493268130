<template>

<Drawer :title="$t('Select a pupil')" initialWidth="2xl" :closeOnClickOutside="true" @requestUnmount="hide">
	<template #default="{ hide }">

		<div class="p-4">
			<ul class="grid grid-cols-1 sm:grid-cols-2 gap-2">
				<li v-for="(pupil, key) in pupilsStore.pupils" :key="key">
					<button class="btn btn-ghost btn-md border-base-300 w-full flex flex-nowrap justify-start gap-4" @click="handleSelection(pupil)">
						<PupilSymbol :pupil="pupil" class="w-10 shrink-0" />
						<span class="text-left grow">{{ pupil.firstName }} {{ pupil.lastName }}</span>
						<ChevronRightIcon class="w-5 h-5"></ChevronRightIcon>
					</button>
				</li>
			</ul>

			<p v-if="pupilsStore.pupils.length === 0" class="text-base-content-light italic text-center py-2">
				{{ $t('No pupils yet') }}
			</p>
		</div>

		<div class="drawer-action-bar justify-end">
			<!-- <button class="btn btn-ghost" @click="hide">{{ $t('Cancel') }}</button> -->
		</div>

	</template>
</Drawer>

</template>

<script lang="ts">
import { mapStores } from "pinia";
import { usePupilsStore } from "@/stores/Pupils.store";

import PupilSymbol from "@/components/ui/symbols/PupilSymbol.vue";
import { ChevronRightIcon } from "@heroicons/vue/24/outline";
import { useDefaultOrderStore } from "@/stores/DefaultOrder.store";
import Drawer from "@/components/ui/Drawer.v2.vue";

export default {

	emits: ['select','requestClose'],

	components: {
		PupilSymbol,
		ChevronRightIcon,
		Drawer,
	},

	data() {
		return {
			loaded: false,
		};
	},

	computed: {
		...mapStores(usePupilsStore, useDefaultOrderStore)
	},

	mounted() {
		this.loadPupils();
	},

	methods: {
		hide () {
			this.$emit('requestClose');
		},

		handleSelection(pupil) {
			this.$emit('select', pupil);
		},

		async loadPupils() {
			await this.pupilsStore.load();
			this.pupilsStore.orderBy(this.defaultOrderStore.pupilsOrder);
			this.loaded = true;
		},
	}
}

</script>
