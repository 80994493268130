<template>
	<template v-if="withName">
		<div
			v-bind="$attrs"
			class="flex items-center rounded-lg px-2"
			:class="['bg-' + avatarColor + '-50', 'text-' + avatarColor + '-600', 'border-' + avatarColor + '-300']"
		>
			<PupilSymbol v-if="withSymbol" :pupil="pupil" :withBorder="false" :disabled="disabled" class="aspect-square h-4/5 mr-2" />
			<div class="font-bold uppercase overflow-hidden whitespace-nowrap text-ellipsis" style="font-size:calc(80% + .3vh); padding-top: .2vh;">{{ pupil.firstName }}</div>
		</div>
	</template>
	<template v-else>
		<PupilSymbol :pupil="pupil" :disabled="false" v-bind="$attrs" />
	</template>
</template>

<script lang="ts">
import { Pupil } from "@/models/Pupil.model";
import PupilSymbol from './ui/symbols/PupilSymbol.vue';

export default {
	inheritAttrs: false,

	props: {
		pupil: {
			type: Pupil,
			required: true
		},
		disabled: {
			type: Boolean,
			required: false
		},
		withSymbol: {
			type: Boolean,
			default: true
		},
		withName: {
			type: Boolean,
			default: false
		}
	},

	components: {
		PupilSymbol,
	},

	computed: {
		avatarColor() {
			return this.pupil.color? this.pupil.color : 'gray';
		}
	}
}
</script>