export class File {
	public id ?: string;
	public fileSize ?: number;
	public mimeType ?: string;
	public url ?: string;
	public name ?: string;
	public type ?: string;
	public variation ?: string;
	public service ?: string;
	public created_at ?: string;

	public variations : Map<string,File> = new Map<string,File>();

	public static mapFromServer(data: any) {
		const file = this.createFromService(data.service);
		return file.setFromServerData(data);
	}

	public static createFromService(service: string) {
		switch (service) {
			case 'gdrive':
				return new FileGoogleDrive();
			case 'onedrive':
				return new FileOneDrive();
			default:
				return new File();
		}
	}

	public setFromServerData(data: any) {
		this.id = data.id;
		this.fileSize = data.file_size;
		this.mimeType = data.mime_type;
		this.url = data.url;
		this.name = data.name;
		this.type = data.type;
		this.variation = data.variation;
		this.created_at = data.created_at;

		if (data.variations) {
			this.variations = new Map<string,File>();
			data.variations.forEach((variation: any) => {
				this.variations.set(variation.variation, File.mapFromServer(variation));
			});
		}

		this.service = data.service;

		return this;
	}

	public getVariationOrDefault(variation: string): File {
		if (this.variations && this.variations.has(variation)) {
			// @ts-ignore
			const variation: File = this.variations.get(variation);
			return variation;
		} else {
			return this;
		}
	}

	public getPreviewUrl(size = 'sm') {
		if (!this.mimeType) {
			return null;
		}

		if(this.mimeType.indexOf('image') >= 0) {
			return this.getVariationOrDefault(size).url;
		} else {
			return null;
		}
	}

	getFileTypeIcon(): {
		url: string | null,
		storageIconUrl: string | null
	} {

		const icon = {
			url: null as string | null,
			storageIconUrl: null as string | null,
		}

		let iconUrl = null;

		const mimeType: string = this.mimeType || '';

		if(mimeType.indexOf('image') >= 0) {
			iconUrl = "/images/icons/file-img.svg";
		} else if(mimeType.indexOf('audio') >= 0) {
			iconUrl = "/images/icons/file-audio.svg";
		} else if(mimeType.indexOf('video') >= 0) {
			iconUrl = "/images/icons/file-video.svg";
		} else if(mimeType.indexOf('ms-word') >= 0) {
			iconUrl = "/images/icons/file-document.svg";
		} else {
			switch(mimeType) {
				case "application/pdf":
					iconUrl = "/images/icons/file-pdf.svg";
					break;
				case "application/zip":
				case "application/x-rar-compressed":
				case "application/x-tar":
				case "application/x-7z-compressed":
				case "application/x-gzip":
				case "application/x-bzip2":
					iconUrl = "/images/icons/file-archive.svg";
					break;
				case "application/msword":
				case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
				case "application/vnd.google-apps.document":
				case "application/vnd.apple.pages":
					iconUrl = "/images/icons/file-document.svg";
					break;
				case "application/vnd.ms-excel":
				case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
				case "application/vnd.google-apps.spreadsheet":
				case "application/vnd.apple.numbers":
					iconUrl = "/images/icons/file-spreadsheet.svg";
					break;
				case "application/vnd.ms-powerpoint":
				case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
				case "application/vnd.google-apps.presentation":
				case "application/vnd.apple.keynote":
					iconUrl = "/images/icons/file-presentation.svg";
					break;
				default:
					iconUrl = null;
					break;
			}
		}

		if(iconUrl) {
			icon.url = iconUrl;
			return icon;
		} else {
			return {
				url: null,
				storageIconUrl: null
			};
		}
	}
}

export class FileGoogleDrive extends File {

	public getPreviewUrl(size = 'sm') {
		const mimetype = this.mimeType || '';
		if(mimetype.indexOf('image') >= 0) {
			return this.getVariationOrDefault(size).url;
		}

		let gdriveIconSize = 16;
		return "https://drive-thirdparty.googleusercontent.com/" + gdriveIconSize + "/type/" + this.mimeType;
	}

	public getFileTypeIcon() {

		const icon = super.getFileTypeIcon();

		icon.storageIconUrl = "/images/icons/google-drive.svg";

		return icon;

	}
}

export class FileOneDrive extends File {

	public getFileTypeIcon() {

		const icon = super.getFileTypeIcon();

		icon.storageIconUrl = "/images/icons/one-drive.svg";

		return icon;

	}

}
