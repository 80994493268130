<template>

	<PupilSymbolWithFileIcon v-bind="$attrs"
		v-if="isFileIcon"
		:color="pupilClassroomAttributes?.color"
		:icon="pupilClassroomAttributes?.icon"
		style="display: block"
		:disabled="disabled"
		:withBorder="withBorder"
	/>

	<PupilSymbolWithSpriteIcon v-bind="$attrs"
		v-else-if="isSpriteIcon"
		:color="pupilClassroomAttributes?.color"
		:icon="pupilClassroomAttributes.icon"
		:disabled="disabled"
		:withBorder="withBorder"
	/>

	<PupilSymbolWithSpriteIcon v-bind="$attrs"
		v-else
		:icon="null"
		:color="pupilClassroomAttributes?.color"
		:disabled="disabled"
		:withBorder="withBorder"
	/>

</template>

<script lang="ts">

import PupilSymbolWithFileIcon from '@/components/ui/symbols/PupilSymbolWithFileIcon.vue';
import PupilSymbolWithSpriteIcon from '@/components/ui/symbols/PupilSymbolWithSpriteIcon.vue';
import { Classroom } from '@/models/Classroom.model';
import { Pupil } from "@/models/Pupil.model";
import { FileIcon, SpriteIcon } from "@/models/Icon.model";


export default {
	inheritAttrs: false,
	props: {
		pupil: {
			type: Pupil,
			required: true
		},
		classroom: {
			type: Classroom,
			default: null
		},
		disabled: {
			type: Boolean,
			default: false
		},
		withBorder: {
			type: Boolean,
			default: true
		}
	},

	components: {
		PupilSymbolWithFileIcon,
		PupilSymbolWithSpriteIcon
	},

	computed: {
		pupilClassroomAttributes() {
			if (!this.pupil) {
				return null;
			}
			return this.pupil.getClassroomRelation(this.classroom) ?? null;
		},
		isFileIcon(): boolean {
			return this.pupilClassroomAttributes && this.pupilClassroomAttributes.icon instanceof FileIcon;
		},
		isSpriteIcon(): boolean {
			return this.pupilClassroomAttributes && this.pupilClassroomAttributes.icon instanceof SpriteIcon;
		},
	}
}
</script>
