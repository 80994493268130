<template>

<Drawer :title="inUpdateMode? $t('Edit activity') : $t('Add activity')" initialWidth="2xl" @requestUnmount="hide">

	<template #default="{ hide }">

		<form @submit.prevent="save" class="isolate">

			<div class="pb-16 px-6">

				<div class="bg-base pt-4">
					<p v-if="activitiesStore.errorMessage?.genericError" class="text-red-600 text-xs">
						{{ activitiesStore.errorMessage?.genericError.toString() }}
					</p>

					<div class="grid grid-cols-6 gap-x-6 gap-y-2">
						<div class="col-span-6 sm:col-span-4">
							<sms-input type="text" autofocus v-model="editableModel.name" :label="$t('Name')"
												:validationError="activitiesStore.errorMessage?.fields.name"></sms-input>
						</div>

						<div class="col-span-6">
							<label class="block input-label capitalize">{{ $tc('Seat', 2) }}</label>
							<NumberInput v-model="editableModel.seats" :stepValue="1" class="w-14"/>
						</div>
					</div>
				</div>

				<div class="input-label border-b border-base-200 mt-4" :class="[ activitiesStore.errorMessage?.fields.icon ? 'text-red-600' : '' ]">
					{{ $t('Icon') }}
				</div>

				<div class="flex pt-4">
					<div class="mr-8 shrink-0">
						<ActivitySymbol
							:activity="editableModel"
							class="w-32 bg-base-100 rounded"
							:class="[ activitiesStore.errorMessage?.fields.icon ? 'border border-red-600' : '' ]"
						 />
					</div>
					<div class="grow">
						<div>
							<button type="button" class="btn" @click="showIconSelector = true">
								{{ $t('Change icon' ) }}...
							</button>
						</div>
					</div>
				</div>

			</div>

			<div class="drawer-action-bar" :class="[inUpdateMode? 'justify-between' : 'justify-end']">

				<Dropdown v-if="inUpdateMode" position="top-start">
					<template #trigger>
						<button type="button" class="btn" :class="[saving? 'btn-disabled':'btn-danger btn-outline']">
							<span v-show="deleting" class="loading loading-spinner"></span>
							<TrashIcon class="w-5 h-5" />
						</button>
					</template>
					<template #content="dialogActions">
						<DropdownDialogConfirm :confirm-label="$t('Yes')" :cancel-label="$t('No')" confirm-style="danger" class="w-80" @cancel="dialogActions.close" @confirm="confirmRemove(dialogActions)">
							<span class="text-danger">{{ $t('Are you sure?') }}</span>
						</DropdownDialogConfirm>
					</template>
				</Dropdown>

				<div class="flex justify-end gap-1">
					<button type="button" class="btn btn-ghost" @click="hide" :class="[saving || deleting? 'hidden':'']">
						{{ $t('Cancel') }}
					</button>
					<button type="submit" class="btn" :class="[deleting? 'btn-disabled':'btn-primary']">
						<span v-show="saving" class="loading loading-spinner"></span>
						{{ $t('Save') }}
					</button>
				</div>

				</div>

		</form>

	</template>

</Drawer>

<IconSelector
	v-if="showIconSelector"
	:isSelectable="() => true"
	:group="'activity'"
	:model="editableModel"
	:classroom="classroomsStore.currentClassroom"
	@icon:selected="setIcon($event)"
	@request-close="showIconSelector = false"
/>

</template>


<script lang="ts">
import {mapStores} from 'pinia';
import {useActivitiesStore} from "@/stores/Activities.store";
import {useClassroomsStore} from "@/stores/Classrooms.store";
import { ApiErrors } from "@/stores/errors/ApiErrors";

import { Icon } from "@/models/Icon.model";

import Drawer from '@/components/ui/Drawer.v2.vue'
import NumberInput from '@/components/ui/NumberInput.vue';
import Dropdown from '@/components/ui/Dropdown.vue';
import DropdownDialogConfirm from '@/components/ui/DropdownDialogConfirm.vue';
import IconSelector from '@/components/IconSelector.vue';
import ActivitySymbol from '@/components/ui/symbols/ActivitySymbol.vue';
import { TrashIcon } from '@heroicons/vue/24/outline';

export default {
	emits: ['requestClose'],

	props: {
		activity: {
			type: Object,
			default: null
		}
	},

	components: {
		Drawer,
		NumberInput,
		Dropdown,
		DropdownDialogConfirm,
		IconSelector,
		ActivitySymbol,
		TrashIcon,
	},

	data() {
		return {
			saving: false,
			deleting: false,
			originalModel: null,
			editableModel: null,
			showIconSelector: null,
		}
	},

	computed: {
		...mapStores(useActivitiesStore, useClassroomsStore),

		inUpdateMode() {
			return this.activity?.id;
		},
	},

	beforeMount() {
		if (this.activity && this.activity.id) {
			this.originalModel = this.activity;
			// clone the store model to allow for manipulation without instantly affecting the store
			this.editableModel = this.activity.clone();

		} else {
			this.editableModel = this.activity;
		}
	},

	methods: {
		hide () {
			this.activitiesStore.clearErrorMessage();
			this.$emit('requestClose');
		},

		setIcon(icon: Icon) {
			this.editableModel.icon = icon;
		},

		async confirmRemove(dialogActions) {

			dialogActions.close();

			if (this.deleting) {
				return;
			}
			this.deleting = true;

			try {
				await this.activitiesStore.delete(this.originalModel);
				this.deleting = false;

				this.hide();

				this.$notify({
					text: this.$t('The activity has been removed'),
					type: "success",
				});

			} catch (e: any) {
				if (!(e instanceof ApiErrors)) {
					throw e;
				}
			}
		},

		async save() {

			if (this.saving) {
				return;
			}
			this.saving = true;

			try {
				await this.activitiesStore.save(this.editableModel);

				this.saving = false;
				this.hide();

				this.$notify({
					text: this.$t('The activity has been saved'),
					type: "success",
				});

			} catch (e) {
				// error message is populated now.
				this.saving = false;
				if (!(e instanceof ApiErrors)) {
					throw e;
				}
			}
			}
	}
}
</script>