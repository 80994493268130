export class PaginationState {
	public currentPage: number = 1;
	public total: number = 0;
	public perPage: number = 10;
	public from: number = 0;
	public to: number = 0;
	public totalPages: number = 0;
	public lastPage: number = 0;

	public setFromServerData(data: any) {
		this.currentPage = data.current_page;
		this.total = data.total;
		this.perPage = data.per_page;
		this.from = data.from;
		this.to = data.to;
		this.lastPage = data.last_page;
	}

	public getNext(): PaginationState | null {
		if (!this.hasNext()) {
			return null;
		}

		const nextPage = new PaginationState();
		nextPage.currentPage = this.currentPage + 1;
		return nextPage;
	}

	public getPrevious(): PaginationState | null {
		if (!this.hasPrevious()) {
			return null;
		}

		const previousPage = new PaginationState();
		previousPage.currentPage = this.currentPage - 1;
		return previousPage;
	}

	public static mapFromServer(data: any) {
		const paginationState = new PaginationState();
		paginationState.setFromServerData(data);

		return paginationState;
	}

	public hasPrevious() {
		return this.currentPage > 1;
	}

	public hasNext() {
		return this.currentPage < this.lastPage;
	}
}
