<template>

<Fancybox :options="{Toolbar: {display: {right: ['close']}}}"></Fancybox>

<div v-if="showPupilSelector">
	<PupilSelector @select="handlePupilSelection" @requestClose="hideEditor" />
</div>

<Drawer v-else initialWidth="2xl" @requestUnmount="hideEditor">


	<template #header>
		<div class="w-full flex justify-between">
			<div class="font-semibold text-md" v-text="inUpdateMode? $t('Edit observation') : $t('Add observation for') + '...'">
			</div>
			<!-- Teacher <-> Pupil interface toggle -->
			<div class="bg-base-200 border border-base-300 rounded-full p-0.5 flex items-center gap-1 mr-6">
				<button type="button" class="rounded-full w-7 h-7 grid place-content-center" :class="[showTeacherInterface? 'bg-base' : '']" @click="showPupilInterface = false">
					<div class="tooltip tooltip-bottom" :data-tip="$t('Teacher')">
						<UserIcon class="w-5 h-5 place-self-center"></UserIcon>
					</div>
				</button>
				<button type="button" class="rounded-full w-7 h-7 grid place-content-center" :class="[showPupilInterface? 'bg-base' : '']" @click="showPupilInterface = true">
					<div class="tooltip tooltip-bottom" :data-tip="$t('Pupil')">
						<UserIcon class="w-3.5 h-3.5 place-self-center"></UserIcon>
					</div>
				</button>
			</div>
		</div>
	</template>


	<template #default="{ hide }">
		<form @submit.prevent="save" class="p-6 flex flex-col space-y-5">

			<!-- TODO: we should be using 'this.$notify' for these generic errors instead -->
			<!-- <p v-if="observationsStore.errorMessage?.genericError" class="text-red-600 text-xs">
				{{ observationsStore.errorMessage?.genericError.toString() }}
			</p> -->

			<div class="font-semibold pb-3 text-xl flex items-center gap-3 justify-center">
				<PupilSymbol :pupil="editableModel.pupil" class="w-14" />
				{{ editableModel.pupil.firstName }}
				{{ editableModel.pupil.lastName }}
			</div>

			<template v-if="canSelectActivity">

				<template v-if="showTeacherInterface">

					<div>
						<DateSelector
							v-model="dateForObservation"
							:label="$t('Date')"
							input-css-classes="input-bordered"
							:year-range="[new Date().getFullYear() - 1, new Date().getFullYear()]"
							:max-date="new Date()"
							:clearable="false"
							autocomplete="off"
							spellcheck="false"
						/>
					</div>

					<div>
						<div class="text-sm">{{ $t('Related to lesson or activity') }}</div>
						<MultiSelect
							:values="relatedEventOptions"
							:selectedIds="[editableModel.pupilActivity?.id]"
							:withFilter="false"
							:singleModus="true"
							@itemSelected="selectActivity"
							@itemUnselected="selectActivity(null)"
							buttonFieldClasses="border border-base-300"
						>
							<!-- currently selected value representation -->
							<div v-if="relatedEventOptionsLoading">
								<span class="loading loading-spinner loading-md"></span>
							</div>
							<div v-else-if="isPupilActivity(editableModel.pupilActivity)" class="text-base-content flex items-center gap-4">
								<ActivitySymbol :activity="editableModel.pupilActivity.lessonItem" class="w-10" />
								<span>{{ editableModel.pupilActivity.activity.name }}</span>
							</div>
							<div v-else-if="isLesson(editableModel.pupilActivity)" class="text-base-content flex items-center gap-4">
								<!-- icon classroom -->
								<svg v-if="isLesson(editableModel.pupilActivity)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" class="w-7">
									<path d="M116 416h-40C34.13 416 0 451.9 0 496C0 504.8 7.25 512 16 512S32 504.8 32 496C32 469.5 51.75 448 76 448h40C140.3 448 160 469.5 160 496C160 504.8 167.3 512 176 512S192 504.8 192 496C192 451.9 157.9 416 116 416zM564 416h-40C482.1 416 448 451.9 448 496c0 8.75 7.25 16 16 16s16-7.25 16-16c0-26.5 19.75-48 44-48h40C588.3 448 608 469.5 608 496c0 8.75 7.25 16 16 16s16-7.25 16-16C640 451.9 605.9 416 564 416zM340 416h-40C258.1 416 224 451.9 224 496C224 504.8 231.3 512 240 512S256 504.8 256 496C256 469.5 275.8 448 300 448h40C364.3 448 384 469.5 384 496c0 8.75 7.25 16 16 16s16-7.25 16-16C416 451.9 381.9 416 340 416zM256.2 320c0 35.35 28.44 63.98 63.79 63.98S383.8 355.3 383.8 320S355.3 256 320 256S256.2 284.7 256.2 320zM352 320c0 17.64-14.36 32-32 32s-32-14.36-32-32s14.36-32 32-32S352 302.4 352 320zM544 256c-35.35 0-63.79 28.64-63.79 63.98S508.7 383.1 544 383.1S607.8 355.3 607.8 320S579.3 256 544 256zM544 352c-17.64 0-32-14.36-32-32s14.36-32 32-32s32 14.36 32 32S561.6 352 544 352zM96 383.1c35.35 0 63.79-28.64 63.79-63.98S131.3 256 96 256S32.21 284.7 32.21 320S60.65 383.1 96 383.1zM96 288c17.64 0 32 14.36 32 32s-14.36 32-32 32s-32-14.36-32-32S78.36 288 96 288zM48 240C56.84 240 64 232.8 64 224V48C64 39.17 71.19 32 80 32h480C568.8 32 576 39.17 576 48V224c0 8.844 7.156 16 16 16S608 232.8 608 224V48c0-26.47-21.53-47.1-48-47.1h-480C53.53 .0001 32 21.53 32 48V224C32 232.8 39.16 240 48 240z"/>
								</svg>
								<span>{{ editableModel.pupilActivity.title }}</span>
							</div>
							<span v-else v-text="$t('None')"></span>

							<!-- option representation -->
							<template #item="{ selected, value }">
								<div class="w-full flex py-2 pl-3 hover:text-accent-content hover:bg-accent">
									<div class="flex-shrink-0 flex items-center justify-center w-16">
										<ActivitySymbol v-if="isPupilActivity(value)" :activity="value.lessonItem" class="w-10" />
										<!-- icon classroom -->
										<svg v-if="isLesson(value)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" class="w-7">
											<path d="M116 416h-40C34.13 416 0 451.9 0 496C0 504.8 7.25 512 16 512S32 504.8 32 496C32 469.5 51.75 448 76 448h40C140.3 448 160 469.5 160 496C160 504.8 167.3 512 176 512S192 504.8 192 496C192 451.9 157.9 416 116 416zM564 416h-40C482.1 416 448 451.9 448 496c0 8.75 7.25 16 16 16s16-7.25 16-16c0-26.5 19.75-48 44-48h40C588.3 448 608 469.5 608 496c0 8.75 7.25 16 16 16s16-7.25 16-16C640 451.9 605.9 416 564 416zM340 416h-40C258.1 416 224 451.9 224 496C224 504.8 231.3 512 240 512S256 504.8 256 496C256 469.5 275.8 448 300 448h40C364.3 448 384 469.5 384 496c0 8.75 7.25 16 16 16s16-7.25 16-16C416 451.9 381.9 416 340 416zM256.2 320c0 35.35 28.44 63.98 63.79 63.98S383.8 355.3 383.8 320S355.3 256 320 256S256.2 284.7 256.2 320zM352 320c0 17.64-14.36 32-32 32s-32-14.36-32-32s14.36-32 32-32S352 302.4 352 320zM544 256c-35.35 0-63.79 28.64-63.79 63.98S508.7 383.1 544 383.1S607.8 355.3 607.8 320S579.3 256 544 256zM544 352c-17.64 0-32-14.36-32-32s14.36-32 32-32s32 14.36 32 32S561.6 352 544 352zM96 383.1c35.35 0 63.79-28.64 63.79-63.98S131.3 256 96 256S32.21 284.7 32.21 320S60.65 383.1 96 383.1zM96 288c17.64 0 32 14.36 32 32s-14.36 32-32 32s-32-14.36-32-32S78.36 288 96 288zM48 240C56.84 240 64 232.8 64 224V48C64 39.17 71.19 32 80 32h480C568.8 32 576 39.17 576 48V224c0 8.844 7.156 16 16 16S608 232.8 608 224V48c0-26.47-21.53-47.1-48-47.1h-480C53.53 .0001 32 21.53 32 48V224C32 232.8 39.16 240 48 240z"/>
										</svg>
									</div>
									<div class="flex flex-1 items-center justify-between">
										<div class="flex-1 flex-col overflow-hidden pl-4 pr-12 py-2">
											<template v-if="isPupilActivity(value)">
												<p class="opacity-40 text-xs">{{ localTime(value.checkin) }}</p>
												<span v-text="value.activity.name"></span>
											</template>
											<template v-else-if="isLesson(value)">
												<p class="opacity-40 text-xs">{{ localTime(value.start) }}</p>
												<span v-text="value.title"></span>
											</template>
											<template v-else>
												<span v-text="value.title"></span>
											</template>
										</div>
										<div class="flex-shrink-0">
											<span v-show="selected" class="text-accent-content absolute inset-y-0 right-0 flex items-center pr-4">
												<CheckIcon class="h-7 w-7"></CheckIcon>
											</span>
										</div>
									</div>
								</div>
							</template>
						</MultiSelect>
					</div>

				</template>

				<!-- Pupil interface -->
				<div v-else-if="showPupilInterface" class="w-full overflow-hidden">
					<div v-if="pupilActivities.length === 0">
						<div class="text-base-content-light italic text-center py-2">{{ $t("No registered activities today yet") }}</div>
					</div>
					<div v-else class="w-full flex flex-nowrap py-5 overflow-x-scroll gap-1 px-3 justify-center">
						<div v-for="pupilActivity in pupilActivities.slice().reverse()" class="relative bg-base-100 rounded-md" :class="[linkActivity? '' : 'opacity-20']">
							<!-- TODO add feedback icon to activity symbol if available -->
							<div v-if="isSelectedActivity(pupilActivity)" class="absolute z-10 bg-accent-focus rounded-full w-6 h-6 -top-2 -left-2 flex items-center justify-center">
								<CheckIcon class="w-4 h-4 text-white"></CheckIcon>
							</div>
							<ActivitySymbol
								v-if="pupilActivity.lessonItem"
								:activity="pupilActivity.lessonItem"
								:selected="false"
								class="w-20 rounded-lg ring-accent"
								:class="[ isSelectedActivity(pupilActivity)? 'ring-2' : ''  ]"
								@click="selectActivity(pupilActivity)"
							/>
						</div>
					</div>
				</div>
			</template>

			<!-- in case of non-editable lesson / activity (when observation editor was called via a lesson or activity) -->
			<div v-if="!canSelectActivity && editableModel.pupilActivity" class="text-center">
				<ActivitySymbol
					v-if="editableModel.pupilActivity.lessonItem"
					:activity="editableModel.pupilActivity.lessonItem"
					:selected="false"
					class="w-24"
				/>
			</div>

			<!-- image uploads -->
			<div>
				<div v-if="showTeacherInterface">
					<sms-textarea v-model="editableModel.note" autofocus :validationError="observationsStore.errorMessage?.fields.note" :label="$t('Note')" />
				</div>

				<Dropzone @files-dropped="filesDropped($event)">

					<Attachments
						v-model="this.editableModel.files"
						v-model:uploading="attachmentsBusy"
						:preview="true"
						ref="attachmentsContainer"
						accept="image/*"
					/>

					<div class="mt-2 mb-2 pr-4 text-right">
						<span class="btn btn-primary btn-circle btn-lg"  @click="$refs.attachmentsContainer.openFileDialog()">
							<CameraIcon class="w-8 h-8"></CameraIcon>
						</span>
					</div>

				</Dropzone>
			</div>


			<div v-if="editableModel.pupilActivity && showTeacherInterface" class="py-4">
				<div class="text-sm">{{ $tc('Goal', 2) }}</div>
				<hr>
				<div v-if="loadingRelatedGoals" class="flex justify-center py-4">
					<span class="loading loading-spinner"></span>
				</div>
				<div v-else-if="showGoals" class="grid grid-cols-6 gap-4 mt-2">

					<div class="col-span-5"></div>
					<div class="text-xs uppercase text-center">
						{{ $tc('Evaluation') }}
					</div>

					<div class="col-span-5 text-sm">
						<span class="text-xs inline-block min-w-10 text-base-content-light">8.1.12</span>
						<!-- <GoalTitle :goal="" /> -->
						Ik kan de telrij minstens tot 4 opzeggen onder ritmische begeleiding
					</div>
					<div class="text-center">
						<button type="button" class="flex justify-center items-center h-full w-full" @click="evaluatedGoal = 231">
							<div class="rounded-full w-8 h-8 self-center" :style="{ backgroundColor: '#ef4444' }"></div>
						</button>
					</div>

					<div class="col-span-5 text-sm">
						<span class="text-xs inline-block min-w-10 text-base-content-light">8.1.16</span>
						<!-- <GoalTitle :goal="" /> -->
						Ik kan de telrij minstens tot 6 opzeggen met behulp van telliedjes en telrijmpjes
					</div>
					<div class="text-center">
						<button type="button" class="flex justify-center items-center h-full w-full" @click="evaluatedGoal = 234">
							<div class="rounded-full w-8 h-8 self-center" :style="{ backgroundColor: '#e5e7eb' }"></div>
						</button>
					</div>
				</div>
			</div>


			<!-- drawer action bar -->
			<div class="drawer-action-bar" :class="[inUpdateMode? 'justify-between' : 'justify-end']">
				<Dropdown v-if="inUpdateMode" position="top-start">
					<template #trigger>
						<button type="button" class="btn" :class="[saving? 'btn-disabled':'btn-danger btn-outline']">
							<span v-show="deleting" class="loading loading-spinner"></span>
							<TrashIcon class="w-5 h-5" />
						</button>
					</template>
					<template #content>
						<DropdownDialogConfirm :confirm-label="$t('Yes')" :cancel-label="$t('No')" confirm-style="danger" class="w-80" @confirm="confirmRemove()">
							<span class="text-danger">{{ $t('Are you sure?') }}</span>
						</DropdownDialogConfirm>
					</template>
				</Dropdown>

				<div class="flex justify-end gap-1">
					<button type="button" class="btn btn-ghost" @click="hide" :class="[saving || deleting? 'hidden':'']">
						{{ $t('Cancel') }}
					</button>
					<button type="submit" class="btn" :class="[!canSave? 'btn-disabled':'btn-primary']">
						<span v-show="saving" class="loading loading-spinner"></span>
						{{ $t('Save') }}
					</button>
				</div>
			</div>

		</form>
	</template>
</Drawer>

<EvaluationEditor v-if="evaluatedGoal" @requestClose="closeEvaluationEditor" />

</template>


<script lang="ts">
import { mapStores } from 'pinia';
import { useObservationsStore } from '@/stores/Observations.store';
import { usePupilsStore } from '@/stores/Pupils.store';
import { usePupilActivitiesStore } from "@/stores/PupilActivities.store";
import { Observation } from '@/models/Observation.model';
import { Pupil } from '@/models/Pupil.model';
import { Lesson } from '@/models/Lesson.model';

import { ApiErrors } from "@/stores/errors/ApiErrors";
import { Files } from "@/utils/Files.util";

import Drawer from "@/components/ui/Drawer.v2.vue";
import Dropdown from "@/components/ui/Dropdown.vue";
import DropdownDialogConfirm from "@/components/ui/DropdownDialogConfirm.vue";
import ActivitySymbol from './ui/symbols/ActivitySymbol.vue';
import PupilSymbol from './ui/symbols/PupilSymbol.vue';
import Switch from "@/components/ui/Switch.vue";
import PupilSelector from '@/components/PupilSelector.vue';
import { TrashIcon, CameraIcon, PhotoIcon, CheckIcon, UserIcon } from '@heroicons/vue/24/outline';
import {PupilActivity} from "@/models/PupilActivity.model";
import FilePreview from "./FilePreview.vue";
import Attachments from "@/components/Attachments.vue";
import Dropzone from "@/components/Dropzone.vue";
import EvaluationEditor from "@/components/EvaluationEditor.vue";
import MultiSelect from '@/components/ui/MultiSelect.vue';
import { DateTime } from "luxon";
import DateSelector from "@/components/ui/DateSelector.vue";
import Fancybox from "./ui/Fancybox.vue";
import GoalTitle from '@/components/GoalTitle.vue';

export default {

	emits: ['requestClose'],

	props: {
		observation: {
			type: Observation,
			required: false,
		},
		pupil: {
            type: Pupil,
			required: false,
        },
		pupilActivity: {
			type: PupilActivity,
			required: false
		}
	},

	components: {
		Attachments,
		FilePreview,
		Drawer,
		Dropdown,
		DropdownDialogConfirm,
		ActivitySymbol,
		PupilSymbol,
		Switch,
		PupilSelector,
		TrashIcon, CameraIcon, PhotoIcon, CheckIcon, UserIcon,
		Dropzone,
		EvaluationEditor,
		MultiSelect,
		DateSelector,
		Fancybox,
		GoalTitle,
	},

	data() {
		return {
			saving: false,
			deleting: false,
			originalModel: null,
			editableModel: null,
			linkActivity: false,
			selectedPupil: null,
			cachedSelectedActivity: null,
			attachmentsBusy: false,
			loadingRelatedGoals: false,
			evaluatedGoal: null,
			showPupilInterface: false,
			dateForObservation: new Date(),
			relatedEventOptionsLoading: false,
			showGoals: false,
		}
	},

  	computed: {
    	...mapStores(useObservationsStore, usePupilsStore, usePupilActivitiesStore),

		inUpdateMode() {
			return (this.observation && this.observation.id)? true : false;
		},

		showPupilSelector() {
			return !this.inUpdateMode && (!this.editableModel || !this.editableModel.pupil);
		},

		pupilActivities() {
			if(!this.editableModel?.pupil) {
				return [];
			}
            return this.pupilActivitiesStore.getActivityForPupilOnDate(new Date(), this.editableModel.pupil);
        },

		showTeacherInterface() {
			return !this.showPupilInterface;
		},

		relatedEventOptions() {
			if(!this.editableModel?.pupil) {
				return [];
			}

			const options = this.pupilActivitiesStore.getActivityForPupilOnDate(new Date(), this.editableModel.pupil);

			// TODO: replace with actual lessons for the selected date
			const dummyLesson = new Lesson();
			dummyLesson.title = 'Zingen: "Ik zag twee beren broodjes smeren"';
			dummyLesson.id = '7554';
			dummyLesson.start = new Date(2024, 1, 24, 13, 0, 0, 0);
			dummyLesson.end = new Date(2024, 1, 24, 14, 0, 0, 0);

			options.push(dummyLesson);

			// sort the array of mixed objects
			options.sort((a, b) => {
				if (a instanceof Lesson && b instanceof Lesson) {
					return a.start.getTime() - b.start.getTime();
				} else if (a instanceof Lesson && b instanceof PupilActivity) {
					return a.start.getTime() - b.checkin.getTime();
				} else if (a instanceof PupilActivity && b instanceof Lesson) {
					return a.checkin.getTime() - b.start.getTime();
				} else if (a instanceof PupilActivity && b instanceof PupilActivity) {
					return a.checkin.getTime() - b.checkin.getTime();
				}
			});

			// add 'None' option
			const noLinkOption = {
				title: this.$t('None'),
				id: 'none',
				start: null,
				end: null,
			};
			options.unshift(noLinkOption);

			return options;
		},

		canSelectActivity() {
			// Is the activity defined by the parent component?
			if (this.pupilActivity) {
				return false;
			}

			return !this.inUpdateMode;
		},

		canSave() {
			return !this.saving && !this.deleting && !this.attachmentsBusy;
		}
	},

	async mounted() {
		if(this.inUpdateMode) {

			this.originalModel = this.observation;

			// clone the store model to allow for manipulation without instantly affecting affected the store
			this.editableModel = this.observation.clone();
			this.selectedPupil = this.observation.pupil;

		} else if(this.pupil && this.pupilActivity) {

			this.editableModel = this.observationsStore.new(this.pupil);
			this.selectedPupil = this.pupil;
			this.editableModel.pupilActivity = this.pupilActivity;
			this.linkActivity = true;

		} else if(this.pupil) {

			await this.handlePupilSelection(this.pupil);

		}
	},

	watch: {
		linkActivity(newValue, oldValue) {
			if (newValue === false && oldValue === true) {
				this.cachedSelectedActivity = this.editableModel.pupilActivity;
				this.editableModel.pupilActivity = null;
			} else if (newValue === true && oldValue === false) {
				if(!this.editableModel.pupilActivity && this.cachedSelectedActivity) {
					this.editableModel.pupilActivity = this.cachedSelectedActivity;
				}
			}
		},

		dateForObservation(newValue, oldValue) {
			if (newValue.toDateString() !== oldValue.toDateString()) {
				this.relatedEventOptionsLoading = true;
				// TODO: replace below code with the actual loading of the lessons/activities for the selected date
				setTimeout(() => {
					this.relatedEventOptionsLoading = false;
				}, 1000);
			}
		}
	},

  	methods: {

		isPupilActivity(object) {
			return object instanceof PupilActivity;
		},

		isLesson(object) {
			return object instanceof Lesson;
		},

		localTime(date: Date) {
			// return date.toLocaleTimeString('nl-BE', { hour: '2-digit', minute: '2-digit' });
			return date.toLocaleString(DateTime.TIME_SIMPLE);
		},

		async handlePupilSelection(pupil) {
			this.editableModel = this.observationsStore.new(pupil);
			this.selectedPupil = pupil;
			await this.loadPupilActivities();
		},

		async loadPupilActivities() {
			await this.pupilActivitiesStore.loadActivityOnDate(new Date());
        	this.selectMostRecentActivity();
		},

		isSelectedActivity(pupilActivity: PupilActivity) {
			return this.editableModel.pupilActivity && this.editableModel.pupilActivity.id === pupilActivity.id;
		},

		selectMostRecentActivity() {
            // const pupilActivities = this.pupilActivitiesStore.getActivityForPupilOnDate(new Date(), this.pupil);
            if (this.pupilActivities.length === 0) {
                this.linkActivity = false;
                return;
            }

            this.editableModel.pupilActivity = this.pupilActivities[this.pupilActivities.length - 1];
            this.linkActivity = true;
        },

        selectActivity(activity) {
            this.linkActivity = true;
            this.editableModel.pupilActivity = activity;
			if(this.editableModel.pupilActivity.id === '7554') {
				this.loadingRelatedGoals = true;
				setTimeout(() => {
					this.showGoals = true;
					this.loadingRelatedGoals = false;
				}, 1000);
			} else {
				this.showGoals = false;
			}
        },

        async selectFile(file) {
            if (!file) {
                return;
            }

            this.editableModel.fileName = file.name;
            this.editableModel.fileContent = await Files.toBase64(file);
        },

		hideEditor (observation: Observation | null) {
			this.observationsStore.clearErrorMessage();
			this.$emit('requestClose', observation);
		},

		async confirmRemove() {

			if (this.deleting) {
				return;
			}
			this.deleting = true;

			try {
				await this.observationsStore.delete(this.originalModel);
				this.deleting = false;

				this.hideEditor(null);

				this.$notify({
					text: this.$t('The observation has been removed'),
					type: "success",
				});

			} catch (e: any) {
				if (!(e instanceof ApiErrors)) {
					throw e;
				}
			}
		},

		async save() {

			if (!this.canSave) {
				return;
			}
			this.saving = true;

			try {
				await this.observationsStore.save(this.editableModel);

				this.saving = false;

				if (this.observation) {
					this.observation.setFromModel(this.editableModel);
					this.hideEditor(this.observation);
				} else {
					this.hideEditor(this.editableModel);
				}

				this.$notify({
					text: this.$t('The observation has been saved'),
					type: "success",
				});

			} catch (e) {
				// error message is populated now.
				this.saving = false;
			}
    	},

		filesDropped(files) {
			this.$refs.attachmentsContainer.filesDropped(files);
		},

		closeEvaluationEditor() {
			this.evaluatedGoal = null;
		},
  	},

}
</script>
