import { FilterParamValue } from "@/stores/filters/FilterParamValue";

class FilterParam {
	name: string;
	values: FilterParamValue[] = [];

	constructor(name: string) {
		this.name = name;
	}
}

interface FilterParamJson {
	filterParams: {
		name: string;
		values?: {
			id: string | number | boolean;
			label: string;
			meta: any;
		}[];
	}[]
}

/**
 *
 */
export class FilterDef {

	filterParams: FilterParam[] = [];
	filterMap: Map<string, FilterParam> = new Map();

	constructor(
		private filterParameterNames: string[]
	) {
		this.filterParameterNames.forEach((param) => {
			let filterParam = new FilterParam(param);
			this.filterParams.push(filterParam);
			this.filterMap.set(param, filterParam);
		});
	}

	/**
	 * Create a new FilterDef object from a dictionary of values.
	 * @param this 
	 * @param values 
	 * @returns 
	 */
	static createFromValuesObject<T extends FilterDef>(
		this: new (...args: any[]) => T,	
			/* 
				Nobody understands what this does or how it works, 
				but it is what Copilot said to do. Seems to work fine.
				
				The reason we are doing this is to make FilterDef.fromValues
				return the subclass of FilterDef (for example, when ObservationFilterDef.fromValues 
				is called, we want it to return an ObservationFilterDef object)
			*/
		values: {
			[key: string]: string;
		}
	): T {

		let filterDef = new this();
		for (let key in values) {
			if (Array.isArray(values[key])) {
				filterDef.setParamValues(key, values[key]);
			} else {
				filterDef.setParamValue(key, values[key]);
			}
		}

		return filterDef;

	}

	clear() {
		this.filterParams.forEach((param) => {
			param.values = [];
		});
	}

	clearParam(paramName: string) {
		let param = this.filterParams.find((p) => p.name === paramName);
		if (param) {	
			param.values = [];
		}
	}

	valueCount(): number {
		let count = 0;
		this.filterParams.forEach((param) => {
			count += param.values.length;
		});
		return count;
	}

	isEmpty(): boolean {
		return this.valueCount() === 0;
	}

	getParamValues(paramName: string): FilterParamValue[] {
		let param = this.filterParams.find((p) => p.name === paramName);
		if (param) {
			return param.values;
		} else {
			return [];
		}
	}

	/**
	 * Set (or replace) the value of a filter parameter.
	 * @param paramName 
	 * @param value 
	 */
	setParamValue(paramName: string, value: FilterParamValue | string | number | boolean) {

		let param = this.filterParams.find((p) => p.name === paramName);
		if (param) {
			// Convert ze values to their proper type
			param.values = [ this.castToFilterParamValue(value) ];
		}

	}

	/**
	 * Set (or replace) the (multiple) values of a filter parameter.
	 * @param paramName 
	 * @param values 
	 */
	setParamValues(paramName: string, values: FilterParamValue[] | string[] | boolean[] | number[]) {
		let param = this.filterParams.find((p) => p.name === paramName);
		if (param) {
			// Convert ze values to their proper type
			param.values = values.map(this.castToFilterParamValue);
		}
	}

	hasFilterValue(paramName: string) {
		let param = this.filterParams.find((p) => p.name === paramName);
		return param && param.values.length > 0;
	}

	addParamValue(paramName: string, paramValue: FilterParamValue) {
		let filterParam = this.filterParams.find((param) => param.name === paramName);
		if (filterParam) {
			// check if already exists
			let exists = filterParam.values.find((value) => value.id === paramValue.id);
			if (!exists) {
				filterParam.values.push(paramValue);
			} else {
				console.warn(`FilterDef.addParamValue: value id '${paramValue.id}' already exists`);
			}
		} else {
			console.warn(`FilterDef.addParamValue: param '${paramName}' not valid`);
		}
	}

	removeParamValue(paramName: string, paramValue: FilterParamValue) {
		let filterParam = this.filterParams.find((param) => param.name === paramName);
		if (filterParam) {
			let index = filterParam.values.findIndex((value) => value.id === paramValue.id);
			if (index > -1) {
				filterParam.values.splice(index, 1);
			}
		} else {
			console.warn(`FilterDef.removeParamValue: param '${paramName}' not valid`);
		}
	}

	merge(filterDef: FilterDef) {
		filterDef.filterParams.forEach((param) => {
			let filterParam = this.filterParams.find((p) => p.name === param.name);
			if (filterParam) {
				param.values.forEach((value) => {
					this.addParamValue(param.name, value);
				});
			}
		});
	}

	/**
	 * Transform filter definition to query parameters for API
	 * @returns {Object} Query parameters for API
	 */
	toApiParams(): any {
		let queryParameters = {};
		this.filterParams.forEach((param) => {
			if (param.values.length > 0) {
				this.transformApiParam(param.name, param.values, queryParameters);
			}
		});
		return queryParameters;
	}

	/**
	 * Transform filter parameter to query parameter for API
	 * @param {string} paramName - Name of the filter parameter
	 * @param {FilterParamValue[]} filters - Filter values
	 * @param {Object} queryParams - Query parameters for API (object will be modified)
	 */
	transformApiParam(paramName: string, filters: FilterParamValue[], queryParams: { [key: string]: string }): void {

		const temporaryParameters: { [ key: string] : (string|number)[] } = {};

		filters.forEach((filter: FilterParamValue) => {

			const params = this.transformApiParamValue(paramName, filter);

			for (let key in params) {
				if (temporaryParameters[key]) {
					temporaryParameters[key].push(params[key]);
				} else {
					temporaryParameters[key] = [params[key]];
				}
			}

		});

		for (let key in temporaryParameters) {
			queryParams[key] = temporaryParameters[key].join(',');
		}
	}

	/**
	 * Transform a single filter value to its API representation
	 * (possibly creating an object with multiple keys)
	 * @param paramName 
	 * @param filterParamValue 
	 * @returns 
	 */
	transformApiParamValue(paramName: string, filterParamValue: FilterParamValue): { [key: string] : string | number } {
		const params: { [key: string]: string | number } = {};

		switch (typeof filterParamValue.id) {
			case 'boolean':
				params[paramName] = filterParamValue.id ? 1 : 0;
				break;

			default:
				params[paramName] = filterParamValue.id.toString();
		}
		
		return params;
	}

	/**
	 * @returns 
	 */
	toJson(): string {
		return JSON.stringify(this);
	}

	/**
	 * Hydrate from JSON
	 * @param json 
	 * @returns this
	 */
	fromJson(json: FilterParamJson) {
		if (!json.filterParams) {
			return this;
		}

		this.clear();
		json.filterParams.forEach((param) => {
			param.values?.forEach((value) => {
				this.addParamValue(param.name, new FilterParamValue(value.id, value.label, value.meta));
			});
		});

		return this;
	}

	/**
	 * DEEPclone FilterDef.
	 * @returns
	 */
	clone(): this {
		/*
		* WARNING:
		* The Object.assign() method does NOT provide a deep clone; the parameters keep their references and are not cloned.
		* This causes a whole bunch of problems when you try to modify the clone.
		*/

		const { constructor } = Object.getPrototypeOf(this);
		const clone = new constructor(this.filterParameterNames);
		clone.fromJson(JSON.parse(this.toJson()));

		return clone;
	}

	equals(filterDef: FilterDef): boolean {

		if (this.valueCount() !== filterDef.valueCount()) {
			return false;
		}

		for (let k in this.filterMap) {
			let filterParam: FilterParam | undefined = this.filterMap.get(k);
			let otherFilterParam: FilterParam | undefined  = filterDef.filterMap.get(k);

			if (!filterParam || !otherFilterParam) {
				return false;
			}

			if (filterParam.values.length !== otherFilterParam.values.length) {
				return false;
			}

			for (let i = 0; i < filterParam.values.length; i++) {
				if (filterParam.values[i].id !== otherFilterParam.values[i].id) {
					return false;
				}
			}
		}

		return true;

	}

	/**
	 * Given a scalar, create a (simple) FilterParamValue object with the scalar as both id and label.
	 * @param value
	 * @returns 
	 */
	private castToFilterParamValue(value: FilterParamValue | string | number | boolean): FilterParamValue {
		if (value instanceof FilterParamValue) {
			return value;
		}

		return new FilterParamValue(value, value.toString());
	}
}
